import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "../../helpers/callFetch";
import Cookies from "js-cookie";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dateFormat, { masks } from "dateformat";
import Timeline from "../reclamation/Timeline";
import CheckList from "./CheckList";
import Edit from "./Edit";
import ReclamationCreate from "../reclamation/Create";
import Comment from "components/Comments";
import { Divider } from "@mui/material";

function Show(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [seen, setSeen] = useState([]);
  const [saving, setSaving] = useState(false);
  const [ticketEditModal, setTicketEditModal] = useState(false);
  const [checkedChecklists, setCheckedChecklists] = useState([]);
  let { rID } = useParams(); 
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const changeSeenStatus = () => {
    callFetch("update-ticket-seen/" + (props?.viewTicket?.id ? props?.viewTicket?.id : params.id), "GET", []).then((res) => {
      setSeen(res.seen);
    });
  };

  const hideReclamationModal = () => {
    var manageCheckListModal = document.getElementById("createCheckListModalOpen");
    manageCheckListModal.click();
  };

  useEffect(() => {

    if (!(props?.viewTicket?.id ? props?.viewTicket?.id : params.id)) return;

    callFetch("ticket/" + (props?.viewTicket?.id ? props?.viewTicket?.id : params.id), "GET", []).then((res) => {
      if(res?.data){
        setData(res.data);
      }else{
        setData([]);
      }

      if(res?.data?.seen){
        setSeen(res?.data?.seen);
      }else{
        setSeen([]);
      }
      
      if (res?.data?.attachments) {
        setAttachment(JSON.parse(res?.data?.attachments));
      }else{
        setAttachment([]);
      }
    }
    );
  }, [(props?.viewTicket?.id ? props?.viewTicket?.id : params.id), refresh]);

  const statusUpdate = () => {

    var formData = {}
    formData.status = (data?.status ? 0 : 1);
    formData._method = 'PUT';

    setSaving(true);
    callFetch("ticket/" + (props?.viewTicket?.id ? props?.viewTicket?.id : params.id), "POST", formData, setError).then((res) => {
      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    });

  }

  const checkListStatusUpdate = (id) => {

    var formData = {}
    formData.id = id;
    formData._method = 'PUT';

    setSaving(true);
    callFetch("checklist-status-update", "POST", formData, setError).then((res) => {
      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    }); 
  }


  const checkListStatusUpdateUnchecked = (id) => {

    var formData = {}
    formData.id = id;
    formData._method = 'PUT';

    setSaving(true);
    callFetch("checklist-status-update", "POST", formData, setError).then((res) => {
      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    }); 
  }

  return (
    <>
      <Grid container >
        <Grid item xs={12} lg={props?.fullwidth ? 12 : 9}>
          <Card>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={3} lg={3}>
                {" "}
              </Grid>
              <Grid item xs={12} lg={12}>
                <SoftBox px={2}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox>
                      <SoftBox mb={1}>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {data?.title}
                        </SoftTypography>
                      </SoftBox>
                      <SoftTypography
                        component="p"
                        variant="button"
                        fontWeight="medium"
                        fontSize="0.875rem"
                        color="black"
                      >
                        Priorität ist{" "}
                        {data?.priority == 'Urgend' ?
                          <span style={{ color: "#E53E3E" }} className="font-weight-600">{t(data?.priority)}</span>
                          : data?.priority == 'Normal' ?
                            <span style={{ color: "#CB0C9F" }} className="font-weight-600">{t(data?.priority)}</span>
                            : data?.priority == 'Not Important' ?
                              <span style={{ color: "#72AC27" }} className="font-weight-600">{t(data?.priority)}</span>
                              : ''}
                        {" "}
                        - Ticket erstellt von{" "}
                        <span style={{ color: "#0048B1" }} className="font-weight-600">
                          {data?.user?.name}
                        </span>
                        {/* <span sx={{ fontWeight: "bold" }}>241342</span> from
                            <span sx={{ fontWeight: "bold" }}>23.02.2021</span> */}
                      </SoftTypography>
                    </SoftBox>
                    {JSON.parse(Cookies.get('permissions')).indexOf("TicU") !== -1 ? (
                      <div>
                        {seen == 0 ? <a href="#" class="btn btn-outline-secondary" onClick={() => changeSeenStatus()} style={{ marginRight: '5px' }}>
                          <i className="fa fa-eye"></i>
                        </a> : <a href="#" class="btn btn-outline-secondary" onClick={() => changeSeenStatus()} style={{ marginRight: '5px' }}>
                          <i className="fa fa-eye-slash"></i>
                        </a>}
                        {props?.editButton !== false ? (
                          <a
                            href="#"
                            onClick={() => { setTicketEditModal(true) }}
                            data-bs-toggle="modal"
                            data-bs-target="#TicketModalEdit"
                            class="btn btn-outline-secondary px-5"
                          >
                            Bearbeiten
                          </a>
                        ) : ''}

                      </div>
                    ) : <></>}
                  </SoftBox>
                  <div
                    style={{
                      backgroundColor: "#eee",
                      width: "100%",
                      height: "1px",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  ></div>
                </SoftBox>

                <Grid container justifyContent="center">
                  <Grid item xs={12} lg={8}>
                    {JSON.parse(Cookies.get('permissions')).indexOf("TicU") !== -1 ? (
                      <div className="mt-0">
                        {data?.status == 1 ? (
                          <button
                            type="button"
                            className="btn btn-success mb-0"
                            onClick={() => {
                              statusUpdate();
                            }}
                            style={{
                              marginLeft: "15px",
                              backgroundColor: "#48BB78",
                              boxShadow: "none",
                            }}
                          >
                            <i className="fa fa-check"></i> &nbsp;&nbsp; Erledigt
                          </button>

                        ) : (<>
                          <button
                            type="button"
                            className="btn btn-secondary mb-0"
                            onClick={() => {
                              statusUpdate();
                            }}
                            style={{ 
                              marginLeft: "15px",
                              boxShadow: "none",
                            }}
                          >
                            <i className="fa fa-check"></i> &nbsp;&nbsp; {t('Open')}
                          </button>
                        </>)}

                        {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                          <a className="btn btn-primary ms-3 mb-0" data-bs-toggle="modal" data-bs-target="#createReclamationModalOpenticket">
                            {t('Reklamation Generieren')}
                          </a>
                        ) : <></>}
                      </div>
                    ) : <></>}

                    <SoftBox style={{paddingRight: '15px'}}>  
                    <SoftBox className="scroll-cs" style={{maxHeight: '600px', paddingRight: '15px'}}>  
                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      <SoftTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Beschreibung
                      </SoftTypography>{" "}

                      <br />
                      <SoftTypography
                        variant="p"
                        className="text-justify"
                        color="text"
                        style={{ textAlign: "justify", fontSize: '0.875rem' }}
                      >
                        {data && data.description}
                      </SoftTypography>
                    </div>
                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      {JSON.parse(Cookies.get('permissions')).indexOf("TicU") !== -1 ? (
                        <a
                          href="#"
                          onClick={hideReclamationModal}
                          id="checkListModalOpen"
                          className="font-size-14-rem"

                        >
                          <i
                            class="fa-solid fa-square-plus"
                            style={{ 
                              color: "#0048B1", 
                              fontWeight: "900", 
                              textAlign: "center",
                              paddingTop: "2px",
                            }}
                          ></i>{" "}
                          <span style={{ color: "#0048B1", fontWeight: "600" }}>
                          Aufgabe hinzufügen
                          </span>{" "}
                        </a>
                      ) : <></>}


                      <div className="row mt-2">
                        <div className="col-sm-12">
                          {data?.checklists && data?.checklists.map((checklist) => <>
                            {checklist.status == 1 ? <div class="form-check">
                              <input class="form-check-input" checked onChange={() => {
                const isChecked = checkedChecklists.includes(checklist.id);
                if (isChecked) {
                    // Remove from checkedChecklists if already checked
                    setCheckedChecklists(prev => prev.filter(id => id !== checklist.id));
                    checkListStatusUpdateUnchecked(checklist.id); // Add your update function here if needed
                } else {
                    // Add to checkedChecklists if not checked
                    setCheckedChecklists(prev => [...prev, checklist.id]);
                    checkListStatusUpdate(checklist.id); // Add your update function here if needed
                }
            }} type="checkbox" id={"customCheck-" + checklist.id} />
                              <SoftTypography
                                variant="p"
                                className="text-justify"
                                color="text"
                                style={{ textAlign: "justify" }}
                              >
                                {checklist.title}
                              </SoftTypography>
                            </div> : <div class="form-check">
                              <input class="form-check-input" onChange={() => {
                const isChecked = checkedChecklists.includes(checklist.id);
                if (isChecked) {
                    // Remove from checkedChecklists if already checked
                    setCheckedChecklists(prev => prev.filter(id => id !== checklist.id));
                    checkListStatusUpdateUnchecked(checklist.id); // Add your update function here if needed
                } else {
                    // Add to checkedChecklists if not checked
                    setCheckedChecklists(prev => [...prev, checklist.id]);
                    checkListStatusUpdate(checklist.id); // Add your update function here if needed
                }
            }} type="checkbox" id={"customCheck-" + checklist.id} />
                              <SoftTypography
                                variant="p"
                                className="text-justify"
                                color="text"
                                style={{ textAlign: "justify" }}
                              >
                                {checklist.title}
                              </SoftTypography>
                            </div> }
                          </>)}
                        </div>
                      </div>
                    </div>


                    <SoftBox p={3} className="order-processing mt-3">
                      <a
                        href="#"
                        className="font-size-14-rem"

                      >
                        <span style={{ color: "#0048B1", fontWeight: "600" }}>
                          {t('Attachements')}
                        </span>{" "}
                      </a>
                      <Grid container spacing={3}>
                        {attachments.map(function (data, i) {
                          return (
                            <Grid item key={i} className="text-center">
                              <div>
                                <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment}>
                                  {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                    <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment} />
                                  ) : (
                                    <div className="placeholder">{data?.extension}</div>
                                  )}
                                </a>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </SoftBox>
                    </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ paddingRight: "15px" }}>
                    <SoftBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mt={2}
                      mb={3}
                    >
                      <SoftBox
                        width="100%"
                        display="flex"
                        className="scroll-cs"
                        style={{maxHeight: '600px'}}
                        flexDirection="column"
                        lineHeight={1}
                      >
                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="button"
                            className="font-weight-600 font-size-14-rem"
                            textTransform="capitalize"
                          >
                            Ticket Nr.: T-{data && data.id} &nbsp; <span style={{ fontWeight: '300', fontStyle: 'italic' }}>{data?.assignorderprocessing_id ? 'DC-' + data?.assignorderprocessing_id : ''}</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              fontSize="0.875rem"
                              textTransform="capitalize"
                            >
                              Erteilt von &nbsp;
                              <span style={{ color: "#0048B1" }}>
                                {data?.user?.name}
                              </span>
                            </SoftTypography>
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" fontWeight="medium" fontSize="0.875rem" color="text">
                            Projekt {' '}
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              fontSize="0.875rem"
                              textTransform="capitalize"
                            >
                              {data?.project_id ? (
                                <NavLink to={'/projects/' + data?.project_id} className="text-primary">{data?.project_id}</NavLink>
                              ) : '--'}
                            </SoftTypography>{" "}
                            vom{" "}
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              fontSize="0.875rem"
                              textTransform="capitalize"
                            >
                              23.02.2021
                            </SoftTypography>
                            &nbsp;&nbsp;&nbsp;
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption"  fontWeight="medium" fontSize="0.875rem" color="text">
                            Kunde:&nbsp;&nbsp;&nbsp;
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              fontSize="0.875rem"
                            >
                              {(data?.project?.vorname ? data?.project?.vorname : '--') + ' ' + (data?.project?.name ? data?.project?.name : '--')}
                            </SoftTypography>
                          </SoftTypography>
                        </SoftBox>
                        <SoftTypography variant="caption"  fontWeight="medium" fontSize="0.875rem" color="text" mt={2}>
                          Start Datum:&nbsp;&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="medium" fontSize="0.875rem">
                            {dateFormat(data && data.start_date, "dd.mm.yyyy")}
                          </SoftTypography>
                        </SoftTypography>
                        <SoftTypography variant="caption" fontWeight="medium" fontSize="0.875rem" color="text">
                          End Datum:&nbsp;&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="medium" fontSize="0.875rem">
                            {dateFormat(data && data.end_date, "dd.mm.yyyy")}
                          </SoftTypography>
                        </SoftTypography>

                        <SoftBox mb={1} mt={3}>
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            Status Information
                          </SoftTypography>
                        </SoftBox> 

                        <Timeline refresh={refresh} projectid={data?.project_id} />
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={12} style={{ paddingRight: "15px" }}>
                      <Divider/>
                      <SoftBox className="mt-3 mb-3 chat-wrapper" p={2}>
                        <Comment id={(props?.viewTicket?.id ? props?.viewTicket?.id : params.id)} from={'ticket'}/>
                      </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={3} lg={2}>
          {" "}
        </Grid>
      </Grid>


      {/* checklist modal */}
      <button type="button" class="btn bg-gradient-primary d-none" data-bs-toggle="modal" id="createCheckListModalOpen" data-bs-target="#checklistModal"></button>
      <button type="button" class="btn bg-gradient-primary d-none" data-bs-toggle="modal" id="editReclamationModalOpen" data-bs-target="#TicketModalEdit"></button>
      <CheckList refresh={setRefresh} checkLists={data?.checklists} id={(props?.viewTicket?.id ? props?.viewTicket?.id : params.id)} />
      <Edit refresh={setRefresh} id={(props?.viewTicket?.id ? props?.viewTicket?.id : params.id)} ticketEditModal={ticketEditModal} />
      <ReclamationCreate setrefresh={setRefresh} popupPrefix={'ticket'} projectid={0} ticket={data} />
    </>
  );
}

export default Show;
