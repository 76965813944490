import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch"; 
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; 

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox"; 
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData"; 
import Cookies from 'js-cookie';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/counter.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
const OrderFiles = (props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [dataa, setDataa] = useState([]);
    const [dachansicht, setDachansicht] = useState([]);
    const [attachments, setAttachment] = useState([]);
    const [ref, setRef] = useState(0);
    
    const removeAttachment = (id) => {
        // const beforeRemove = attachments;
        // const afterRemove = beforeRemove.filter(value => {
        //     return value.id != id;
        // });

        // setAttachment(afterRemove);

        // var formData = new FormData();
        // formData.attachments = JSON.stringify(attachments); 
        // formData.id = id; 
        // formData.project_id = props.project_id; 
        // callFetch("delete-order-files", "POST", formData ).then((res) => {
        //     setRef(ref+1);
        // });

    }

    const [images, setImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);

    const [position, setPosition] = useState('bottom');
    const [width, setWidth] = useState(120);
    const [height, setHeight] = useState(80);
    const [border, setBorder] = useState(3);
    const [borderRadius, setBorderRadius] = useState(4);
    const [padding, setPadding] = useState(4);
    const [gap, setGap] = useState(16);
    const [preload, setPreload] = useState(2);
    const [showToggle, setShowToggle] = useState(false);

    const [animationDuration, setAnimationDuration] = useState(500);
    const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(10);
    const [zoomInMultiplier, setZoomInMultiplier] = useState(2);
    const [doubleTapDelay, setDoubleTapDelay] = useState(300);
    const [doubleClickDelay, setDoubleClickDelay] = useState(300);
    const [doubleClickMaxStops, setDoubleClickMaxStops] = useState(2);
    const [keyboardMoveDistance, setKeyboardMoveDistance] = useState(50);
    const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] = useState(100);
    const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] = useState(100);
    const [scrollToZoom, setScrollToZoom] = useState(true);
 

    const openImgsViewer  = (index, event) => {
        setCurrentImg(index);
        setIsOpen(true);
    }

 


     // Function to get the array of image objects with src properties
     const generateImageArray = (data) => {
        const baseURL = process.env.REACT_APP_BACKEND_URL + 'storage/attachments/'; // Replace this with your actual base URL
        const imgs = [];
  
        data.forEach(item => {
            imgs.push({src: baseURL + item.attachment});
        }); 
  
        setImages(imgs);
    };


    useEffect(() => { 
        callFetch("get-order-uploaded-files/" + props.project_id, "GET", []).then((res) => { 
            setDataa(res.order);
            console.log(res.order);
            generateImageArray(res.order);
        });
    }, [props.project_id, props.refresh, ref]); 
  return (
    <>
        <div className="row mb-5">
            <SoftBox p={3} className="order-processing">
                <Grid container spacing={3}>
                {dataa?
                    dataa?.map((item, index) => (  
                        <Grid item key={index}>
                            <span key={index} onClick={(e) => openImgsViewer(index, e)} style={{cursor: 'pointer'}}>
                                <img src={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+item.attachment} />
                            </span>  
                        </Grid>  
                    )) : <></>} 

                    <Lightbox
                        index={currentImg}
                        open={isOpen}
                        close={() => setIsOpen(false)}
                        slides={images.map((slide) => ({
                            ...slide,
                            download: `${slide.src}?download`,
                          }))}
                        plugins={[Download, Zoom, Counter, Thumbnails]}
                        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
                        thumbnails={{
                            position,
                            width,
                            height,
                            border,
                            borderRadius,
                            padding,
                            gap,
                            showToggle,
                          }}
                        zoom={{
                            maxZoomPixelRatio,
                            zoomInMultiplier,
                            doubleTapDelay,
                            doubleClickDelay,
                            doubleClickMaxStops,
                            keyboardMoveDistance,
                            wheelZoomDistanceFactor,
                            pinchZoomDistanceFactor,
                            scrollToZoom,
                        }}
                    />
                </Grid>
            </SoftBox>
        </div>
        <ToastContainer />
    </>
  )
}

export default OrderFiles