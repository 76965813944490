import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import IndexTable from './IndexTable';

function EmployeeIndex() {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div>
                {JSON.parse(Cookies.get('permissions')).indexOf("SC") !== -1 ? (
                    <NavLink to="/products/CreateProduct" className="btn btn-icon btn-primary">
                        {t('Zusatzprodukte')}
                    </NavLink>
                ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Product')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeIndex;
