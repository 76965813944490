import { React, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import Select from 'react-select';

const GoodsCreate = () => {
    const params = useParams();
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);
    const [articleName, setArticleName] = useState(null);
    const [lager, setLager] = useState([]);
    const [saving, setSaving] = useState(false);
    const [items, setItems] = useState([{ article_nr:0, article_name:"", qty:1, prefix:"" }]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [warehousId, setWarehousId] = useState(0);
    const [storageAreas, setStorageAreas] = useState([]);
    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [modules, setModules] = useState([]);
    const [speichers, setSpeicher] = useState([]);
    const [wechselrichtes, setWechselrichtes] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
  
    useEffect(() => {
        const totalQty = items?.reduce((acc, item) =>{
            return acc + item.qty;
        },0)
        setQuantity(totalQty)
    },[refresh])


  
    const handleArticleChange = (e, index) => {
        const articleId = e; 
        if(articleId){
            callFetch("items-by-prefix/"+articleId, "GET", []).then((res)=>{
                if(res.prefix == 'Art'){
                    setArticleName(res?.data?.article_name);
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.article_name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                }else{ 
                    setArticleName(res?.data?.name);
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                } 

            })
        }

    };
  
    const [fileInputKey, setFileInputKey] = useState(0);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const fileNames = files.map((file) => file.name);
        setSelectedFiles([...selectedFiles, ...fileNames]);
        setFileInputKey(fileInputKey + 1); // Trigger a re-render of the file input
        setDeliveryNotes([...deliveryNotes, ...files]);
      };
    
      const handleRemoveFile = (fileName, index) => {
        const updatedFiles = selectedFiles.filter((name) => name !== fileName);
        setSelectedFiles(updatedFiles);
        setFileInputKey(fileInputKey + 1); // Trigger a re-render of the file input
        const updatedDeliveryNotes = deliveryNotes;
              deliveryNotes.splice(index, 1);
        setDeliveryNotes(updatedDeliveryNotes);
        
      };


      useEffect(()=>{
        if(deliveryNotes){
            const updatedFileList = new DataTransfer();
            deliveryNotes.forEach((file) => {
                updatedFileList.items.add(file);
            });
            setValue('delivery_notes',updatedFileList.files);
        }
      },[handleRemoveFile])

    function TruncateText({ text, maxLength }) {
        if (text.length <= maxLength) {
          return <span>{text}</span>;
        }
      
        const truncatedText = text.slice(0, maxLength) + '...';
      
        return <span title={text}>{truncatedText}</span>;
      }

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }
  
    useEffect(() => {
        if(warehousId > 0){
            setStorageAreas([]);
            callFetch("lager/"+warehousId+"/edit", "GET", []).then((res) => {
                if(res?.data?.warehouse_zone){
                  const zone = JSON.parse(res?.data?.warehouse_zone);
                  setStorageAreas(zone)
                }
            });
        }
    }, [warehousId]);
  
    useEffect(() => {
      callFetch("goods/create", "GET", []).then((res) => {
          setValue('receive_number',res?.goods_number)
          setLager(res?.data?.lager)

          const products = [];

          // Assuming res.data.modules is an array
          var updatedProducts = res?.data?.modules.map((item) => ({
            id: item.module_identity,
            name: item.name,
          }));
          
          // Concatenate the existing products with the new items
          products.push(...updatedProducts); 

          // Assuming res.data.modules is an array
          updatedProducts = res?.data?.speichers.map((item) => ({
            id: item.speicher_identity,
            name: item.name,
          }));
          
          // Concatenate the existing products with the new items
          products.push(...updatedProducts); 
          
          // Assuming res.data.modules is an array
          updatedProducts = res?.data?.wechselrichtes.map((item) => ({
            id: item.identity,
            name: item.name,
          }));
          
          // Concatenate the existing products with the new items
          products.push(...updatedProducts); 
          
          
          // Assuming res.data.modules is an array
          updatedProducts = res?.data?.articles.map((item) => ({
            id: item.article_nr,
            name: item.article_name,
          }));
          
          // Concatenate the existing products with the new items
          products.push(...updatedProducts);
           
          setArticles(products);
          

        //   setModules(res?.data?.modules)
        //   setSpeicher(res?.data?.speichers)
        //   setWechselrichtes(res?.data?.wechselrichtes)
        //   setArticles(res?.data?.articles)
      });
    }, [0]);
  
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Successfully updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );

      const [errorSB, setErrorSB] = useState(false); 
      const closeErrorSB = () => setErrorSB(false);
  
      const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Lieferschein (PDF) ist erforderlich')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );
  
  
    const onSubmit = (formData) => { 
        if(formData.delivery_notes.length > 0){
            setSaving(true);
            formData.articles = JSON.stringify(items)
            formData.quantity = quantity;
            callFetch("goods", "POST", formData, setError).then((res) => {
                setSaving(false);
                if (!res.ok) return;
                if(res.message === 'success' && params?.id){
                    openSuccessSB();
                }
                setSubmitSuccess(true);
                
            });
        }else{
            setErrorSB(true);
        }
        
    };
  
return submitSuccess ? <Navigate to={'/ware-houses/goods-received'} /> :
<>
    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <div className="row">
          <div className="col-lg-9">
              <div className="card mb-4">
                  <div className="card-header pb-0">
                      <h6>{t('Wareneingang Buchen')}</h6>
                  </div>
                  <div className="card-body">
                     
                          <div className="row g-3">

                              <div className="col-md-4">
                                  <label>{t('Wareneingangnr.')} *</label>
                                  <input type="text" className="form-control" {...register('receive_number',{required:true})} required readOnly/>
                                  <div className="invalid-feedback">{errors.receive_number && errors.receive_number.message}</div>
                              </div>
                              <div className="col-md-4">
                                  <label>{t('Auftragsnummer vom Lieferanten')} *</label>
                                  <input
                                      type="text"
                                      className="form-control mb-4"
                                      placeholder={t('e.B Jhon Doe')}
                                      {...register("supplier_order_nr", {
                                          required: true,
                                      })}
                                      required
                                  />
                                  <div className="invalid-feedback">{errors.supplier_order_nr && errors.supplier_order_nr.message}</div>
                              </div>
                              <div className="col-md-4">
                                  <label>{t('Lieferant')} *</label>
                                  <select
                                      className="form-control mb-4"
                                      placeholder={t('e.B Jhon Doe')}
                                      {...register("supplier_name", {
                                          required: true,
                                      })}
                                      required
                                  >
                                    <option value="">{t('----')}</option>
                                    <option value={1}>Md Numan Parvej</option>
                                    <option value={2}>Md Nazrul Islam</option>
                                  </select>
                                  <div className="invalid-feedback">{errors.supplier_name && errors.supplier_name.message}</div>
                              </div>


                              <div className="row g-2">
                                  <div className="col-md-6">
                                      <label>{t('Lagerauswahl')} *</label>
                                      <select className="form-control" {...register('warehous',{required:true})} required onChange={(e)=>setWarehousId(e.target.value)}>
                                        <option value="">{t("----")}</option>
                                        {
                                            lager && lager?.map((lager, index) => (
                                                <option key={index} value={lager?.id}>{t(lager?.lager_nr+" - "+lager?.lager_name)}</option>
                                            ))
                                        }
                                        
                                      </select>
                                      <div className="invalid-feedback">{errors.warehous && errors.warehous.message}</div>
                                  </div>
                                  <div className="col-md-6">
                                      <label>{t('Lagerbereiche')} *</label>
                                      <select className="form-control" {...register('storage_are',{required:true})} required disabled={storageAreas.length > 0 ? false:true }>
                                        <option value="">{t('----')}</option>
                                        {
                                            storageAreas && storageAreas?.map((storage, index)=>(
                                                <option key={index} value={storage?.zone_name}>{t(storage?.zone_name+" - "+storage?.regal_nr)}</option>
                                            ))
                                        }
                                      </select>
                                      <div className="invalid-feedback">{errors.storage_are && errors.storage_are.message}</div>
                                  </div>
                                  
                              </div>

                            <div className="row g-2 mt-4">
                                {
                                    items && items?.map((item, i) =>(
                                        <>
                                        <div className="col-md-4">
                                            <label>{t('Artikelnummer')} *</label>
                                            {/* <select className="form-control" onChange={(e)=>{
                                                handleArticleChange(e, i)
                                                items[i].article_nr = parseFloat(e.target.value)
                                                setItems(items);
                                            }}>
                                                <option value="">{t('----')}</option>
                                                {
                                                    articles && articles?.map((article,index)=>(
                                                    <option key={index} value={article?.article_nr}>{t(article?.article_nr+" - "+article?.article_name)}</option>
                                                    ))
                                                }
                                                {
                                                    modules && modules?.map((module,index)=>(
                                                    <option key={index} value={module?.module_identity}>{t(module?.module_identity+" - "+module?.name)}</option>
                                                    ))
                                                }
                                                {
                                                    speichers && speichers?.map((speicher,index)=>(
                                                    <option key={index} value={speicher?.speicher_identity}>{t(speicher?.speicher_identity+" - "+speicher?.name)}</option>
                                                    ))
                                                }
                                                {
                                                    wechselrichtes && wechselrichtes?.map((wechselrichte,index)=>(
                                                    <option key={index} value={wechselrichte?.identity}>{t(wechselrichte?.identity+" - "+wechselrichte?.name)}</option>
                                                    ))
                                                } 
                                            </select> */}

                                            <Select
                                                    placeholder={t('Select')}
                                                    options={articles ? articles : []}
                                                    getOptionLabel={(option) => ((option?.name ? option?.id +' - '+ option?.name : ''))}
                                                    getOptionValue={(option) => option?.id} 
                                                    onChange={(e) => {
                                                        handleArticleChange(e.id, i);
                                                        items[i].article_nr = parseFloat(e.id)
                                                        setItems(items);
                                                    }}

                                                    required
                                                    styles={{
                                                        fontSize: '0.875rem',
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            fontSize: '0.875rem',
                                                        }),
                                                    }}
                                                />
                                        </div>
                                        <div className="col-md-4">
                                            <label>{t('Artikelbeschreibung')} *</label>
                                            <input className="form-control" defaultValue={items[i].article_name} onChange={(e)=>{
                                              items[i].article_name = e.target.value
                                              setItems(items);
                                            }} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>{t('Anzahl')}</label>
                                            <div className="d-flex align-items-center">
                                                <input type="number" value={parseInt(items[i].qty)} className="form-control" placeholder="eg. 0.00" onChange={(e) => {
                                                    items[i].qty = parseFloat(e.target.value);
                                                    setItems(items);
                                                    setRefresh(refresh + 1);
                                                    }} />
                                                &nbsp;
                                                <i className="fa-solid fa-circle-xmark text-danger cursor-pointer" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                            </div>
                                        </div>
                                        </>
                                    ))
                                }
                                <div className="col-md-12 mt-3">
                                    <p className="btn btn-dark" style={{ cursor: 'pointer' }} onClick={() => { setItems([...items, { article_nr:0, article_name:"", qty:1 }]); setRefresh(refresh + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Item')}</p>
                                </div>
                            </div>
                            
                            <div className="row g-2">
                                  <div className="col-md-12">
                                      <label>{t('Bestellnummer')}</label>
                                      <input type="text" className="form-control"
                                          placeholder={t('e.G 000')}
                                          {...register("po_number")}/>
                                      <div className="invalid-feedback">{errors.po_number && errors.po_number.message}</div>
                                  </div>
                              </div>
                              
                              <div className="row g-2">
                                  <div className="col-md-12">
                                      <label>{t('Description')}</label>
                                      <textarea
                                          className="form-control"
                                          rows="4"
                                          placeholder={t('')}
                                          {...register("description")}></textarea>
                                      <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                  </div>
                              </div>
                              
                              <div className="col-12 mb-4">
                                  {!saving && (
                                      <button type="submit" className="btn btn-primary">
                                          {t('Save')}
                                      </button>
                                  )}
                                  {saving && (
                                      <button type="submit" className="btn btn-disabled" disabled>
                                          {t('Saving ...')}
                                      </button>
                                  )}
                              </div>
                          </div>
                      
                  </div>
              </div>
          </div>
            <div className="col-3">
            <div className="card">
                <div className="card-header">
                    <label>{t('Lieferschein')} (PDF) *</label>
                    <input
                        type="file"
                        // id={`upload-${fileInputKey}`}
                        className="form-control"
                        placeholder={t('')}
                        // {...register('delivery_notes')}
                        onChange={handleFileChange}
                        // required
                        multiple
                        />
                    </div>
                    <div className="card-body pt-0">
                        <div className="w-100">
                        {selectedFiles.map((fileName, index) => (
                            <div className="d-flex align-items-center bg-light my-2 p-1 border-radius-md mt-0" key={fileName}>
                            <p className="p-0 m-0 w-90 truncate text-sm"><TruncateText text={fileName} maxLength={41} /></p>
                            &nbsp;
                            <i className="fa-solid fa-circle-xmark text-danger cursor-pointer ms-2" onClick={() => handleRemoveFile(fileName,index)}></i>
                            </div>
                        ))}
                        </div>
                </div>
            </div>
        </div>

      </div>
      {renderSuccessSB}
      {renderErrorSB}
      </form>
</>
}
export default GoodsCreate