import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from './IndexTable';
import ReklamationIndexTable from './ReklamationIndexTable';

import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Select from 'react-select';
import callFetch from "helpers/callFetch";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

import {
    useSoftUIController,
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
} from "context";

function Index() {
    const { t } = useTranslation();
    const [chooseplan, setChoosePlan] = useState('Projects');
    const [search, setSearch] = useState('');
    const [filderData, setFilderData] = useState([]);
    const [filterKeys, setFilterKeys] = useState({});
    const [disabled, setDisabled] = useState(false);

    const [refresh, setRefresh] = useState(0);
    const [more, setMore] = useState(true);

    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } = controller;
    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);


    const handleAddMoreButton = () => {
        setMore(true);
    }

    const handleClearButton = () => {
        setMore(true);
        setFilterKeys({});
    }

    useEffect(() => {
        callFetch("get-project-filter-data", "GET", []).then((res) => {
            setFilderData(res?.data);
        });
    }, []);

    useEffect(() => {

        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }

        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener("resize", handleDisabled);

        // Call the handleDisabled function to set the state with the initial value.
        setOpenConfigurator(dispatch, false);
        handleDisabled();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleDisabled);

    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h6>
                                        <span className='float-start'>{t('Purchase')}</span>

                                        <div class="form-check float-start mx-3">
                                            <input
                                                onClick={(e) => {
                                                    setChoosePlan('Projects');
                                                }}
                                                class="form-check-input"
                                                type="radio"
                                                name="chooseplan"
                                                id="Projects"
                                                defaultChecked
                                            />
                                            <label class="form-check-label" for="Projects">{t('Projects')}</label>
                                        </div>

                                        <div class="form-check float-start mx-3">
                                            <input
                                                onClick={(e) => {
                                                    setChoosePlan('Reklamationnen');
                                                }}
                                                class="form-check-input"
                                                type="radio"
                                                name="chooseplan"
                                                id="acPlan"
                                            />
                                            <label class="form-check-label" for="acPlan">{t('Reklamationnen')}</label>
                                        </div>
                                    </h6>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        className='form-control float-end'
                                        placeholder={t('Search...')}
                                        style={{ maxWidth: '300px' }}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                    />

                                    <button onClick={handleConfiguratorOpen} className='btn btn-outline-dark mx-2 float-end' style={{ paddingTop: '10px', paddingBottom: '10px', margin: '0px' }}><i className="fa-solid fa-filter"></i> Filter</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            {chooseplan == 'Projects' ? <IndexTable filterKeys={filterKeys} refresh={refresh} chooseplan={chooseplan} search={search} /> : <ReklamationIndexTable filterKeys={filterKeys} refresh={refresh} chooseplan={chooseplan} search={search} />}

                        </div>
                    </div>
                </div>
            </div>


            <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="baseline"
                    pt={3}
                    pb={0.8}
                    px={3}
                >
                    <SoftBox>
                        <SoftTypography variant="h5">Filters</SoftTypography>
                    </SoftBox>

                    <Icon
                        sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                            fontSize: `${size.md} !important`,
                            fontWeight: `${fontWeightBold} !important`,
                            stroke: dark.main,
                            strokeWidth: "2px",
                            cursor: "pointer",
                            mt: 2,
                        })}
                        onClick={handleCloseConfigurator}
                    >
                        close
                    </Icon>
                </SoftBox>

                <Divider />

                <SoftBox pt={1.25} pb={3} px={3}>
                    <label>Project Nr</label>
                    <div className='position-relative'>

                        <Select
                            placeholder={t('Select')}
                            options={filderData?.projects ? filderData?.projects : []}
                            //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var projects = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    projects.push(option?.value)
                                }

                                dataKeys.projects = JSON.stringify(projects);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter mb-2'
                            isMulti
                        />
                    </div>

                    <label>{t("Kundenname")}</label>
                    <div className='position-relative'>
                        <Select
                            placeholder={t('Select')}
                            options={filderData?.customers ? filderData?.customers : []}
                            //defaultValue={filterKeys?.status ? JSON.parse(filterKeys?.status) : ''}
                            onChange={(e) => {
                                var dataKeys = filterKeys;
                                var customers = [];
                                for (let [option_key, option] of Object.entries(e)) {
                                    customers.push(option?.value)
                                }

                                dataKeys.customers = JSON.stringify(customers);
                                setFilterKeys(dataKeys);
                                setRefresh(refresh + 1);
                            }}
                            className='react-select-filter mb-2'
                            isMulti
                        />
                    </div>



                    {/* //more */}
                    {more ? (
                        <div>
                            <label>{t("Bestelldatum vorhanden")}</label>
                            <select
                                className='form-control mb-2'
                                onChange={(e) => {
                                    var dataKeys = filterKeys;
                                    dataKeys.bestelldatum_vorhanden = e.target.value;
                                    setFilterKeys(dataKeys);
                                    setRefresh(refresh + 1);
                                }}
                            >
                                <option>Select</option>
                                <option value="yes">{t('Yes')}</option>
                                <option value="no">{t('No')}</option>
                            </select>



                            <label>{t("Versanddatum vorhanden")}</label>
                            <select
                                className='form-control mb-2'
                                onChange={(e) => {
                                    var dataKeys = filterKeys;
                                    dataKeys.versanddatum_vorhanden = e.target.value;
                                    setFilterKeys(dataKeys);
                                    setRefresh(refresh + 1);
                                }}
                            >
                                <option>Select</option>
                                <option value="yes">{t('Yes')}</option>
                                <option value="no">{t('No')}</option>
                            </select>

                            <label>{t("Lieferdatum vorhanden")}</label>
                            <select
                                className='form-control mb-2'
                                onChange={(e) => {
                                    var dataKeys = filterKeys;
                                    dataKeys.lieferdatum_vorhanden = e.target.value;
                                    setFilterKeys(dataKeys);
                                    setRefresh(refresh + 1);
                                }}
                            >
                                <option>Select</option>
                                <option value="yes">{t('Yes')}</option>
                                <option value="no">{t('No')}</option>
                            </select>

                            <label>{t("Po-Nuber available")}</label>
                            <select
                                className='form-control mb-2'
                                onChange={(e) => {
                                    var dataKeys = filterKeys;
                                    dataKeys.po_number_available = e.target.value;
                                    setFilterKeys(dataKeys);
                                    setRefresh(refresh + 1);
                                }}
                            >
                                <option>Select</option>
                                <option value="yes">{t('Yes')}</option>
                                <option value="no">{t('No')}</option>
                            </select>
                        </div>
                    ) : <button type='button' className='btn btn-link p-0 mt-2' style={{ color: '#005498' }} onClick={() => handleAddMoreButton()}>More</button>}

                    <br />
                    <button type='button' className='btn btn-outline-dark mt-2' onClick={() => handleClearButton()}>Clear</button>
                </SoftBox>
            </ConfiguratorRoot>
        </>
    );
}

export default Index;
