import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from 'pages/project/Timeline';
import ImportModal from 'pages/importer/ImportModal';
import Select from 'react-select';
import Swal from "sweetalert2";

function IndexTable(props) {
    const { t } = useTranslation();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [filterKeys, setFilterKeys] = useState({});
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const [proID, setProID] = useState(0);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const cancelProject = (cancel, id) => {

        callFetch("project/cencel", "POST", {
            cancel: cancel,
            id: id,
        }, []).then((res) => {


            setRefresh(refresh + 1)
        });


        setRefresh(refresh + 1)
    }

    const [action, setAction] = useState('delete');
    const [wiedervorlage, setWiedervorlage] = useState('');
    const [selectedRows, setSelectedRows] = useState(false);

    const handleChangeAction = (action) => {
        setAction(action);
    }
    const handleSelectedRowsApply = () => {
        showAlert();
    }

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const showAlert = () => {
        const newSwal = Swal.mixin({
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        });

        newSwal
            .fire({
                title: t('Are you sure?'),
                text: t(action == 'delete' ? "You won't be able to revert this!" : ''),
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Yes, do it!'),
                cancelButtonText: t('Cancel')
            })
            .then((result) => {
                if (result.isConfirmed) {
                    var ids = [];
                    selectedRows.map((row) => {
                        ids.push(row.id);
                    })

                    var formData = new FormData;
                    formData.ids = ids;
                    
                    if(action == 'delete'){
                        callFetch("delete-cancel-projects", "POST", formData).then((res) => {
                            setRefresh(refresh + 1);
                        });

                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }else if(action == 'Wiedervorlage'){
                        if(!wiedervorlage) return;

                        formData.wiedervorlage = wiedervorlage;

                        callFetch("projects-wiedervorlage", "POST", formData).then((res) => {
                            setRefresh(refresh + 1);
                        });

                        Swal.fire("Wiedervorlage!", "Successfull.", "success");
                    }                 
                }
            });
    };

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>
                <Timeline datas={data} title="Project Status" />
            </Grid>
        </Menu>
    );

    const tableHeadings = [
        {
            name: t('Project Number'),
            width: '170px',
            selector: row => <NavLink to={'/projects/' + row.id} className="text-primary">{row.id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Customer'),
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
        },
        {
            name: t('VKB Name'),
            selector: row => <NavLink to={'/projects/' + row.id}>{row?.salse_person?.name}</NavLink>,
        },
        {
            name: t('Erstellt am'),
            width: '130px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Ereignisdatum'),
            width: '130px',
            selector: row => dateFormat(row?.ereignisdatum, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-dark text-xs">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row.status && row.status != 'null' ? (
                                    <>
                                        {row.status == '1-Verkauft' ? <i className="bg-success"></i>
                                            : row.status == '2-Kontte nicht verkauft werden' ? <i className="bg-primary"></i>
                                                : row.status == '3-Angebot' ? <i className="bg-secondary"></i>
                                                    : row.status == '4-Überleger' ? <i className="bg-info"></i>
                                                        : row.status == '5-Kunde war nicht da' ? <i className="bg-warning"></i>
                                                            : row.status == '6-Kein Interesse / Keine Beratung' ? <i className="bg-dark"></i>
                                                                : row.status == '7-Technisch nicht möglich' ? <i className="bg-dark"></i>
                                                                    : row.status == '0-Zeitlich nicht geschaft' ? <i className="bg-dark"></i>
                                                                        : <i className="bg-dark"></i>
                                        }
                                        <span className="text-dark text-xs">{t(row.status)}</span>
                                    </>
                                ) : <span className="text-dark text-xs">{'--'}</span>}
                            </>
                        )
                        }

                    </span>


                    {/*
                    <ProjectStatus projectid={row?.id} status={row?.status} />
                        <span style={{height: '0.375rem', width: '0.375rem', backgroundColor: row?.quations?.status == '1-Verkauft' ?
                                    '#21d4fd' : row?.quations?.status == '2-Kontte nicht verkauft werden' ? 
                                    '#ff0080' : row?.quations?.status == '3-Angebot' ? 
                                    '#fbcf33' : row?.quations?.status == '4-Überleger' ? 
                                    '#e6980b' : row?.quations?.status == '5-Kunde war nicht da' ? 
                                    '#9320bb' : row?.quations?.status == '6-Kein Interesse / Keine Beratung' ? 
                                    '#9320bb' : row?.quations?.status == '7-Technisch nicht möglich' ? 
                                    '#9320bb' : row?.quations?.status == '0-Zeitlich nicht geschaft' ? 
                                    '#9320bb' : 'dark', borderRadius: '50%', display: 'inline-block'}}></span>
                        <SoftTypography style={{position: 'relative', top: '1px', left: '3px', fontSize: '0.75rem', display: 'inline'}}>{row?.quations?.status}</SoftTypography>
                    */}
                </>
            ),
        },
        {
            name: t('Price'),
            selector: row => ((
                <NumericFormat
                    value={Number(row?.quations?.gesami_netto)}
                    displayType="text"
                    thousandSeparator={"."}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={' € '}
                />
            )),
        },
        {
            name: t('Resale Status'),
            selector: row => row?.resalestatus?.title ? row?.resalestatus?.title : '',
        },
        {
            name: t('Ort'),
            selector: row => row?.ort,
        },
        {
            name: t('Project Status'),
            width: '180px',
            cell: row => <>
                {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename != 'Call Center' ? (
                    <div onClick={handleOpenMenu} className="d-flex align-items-center cursor-pointer" style={{ color: "#0048B1", textDecoration: "underline" }}>
                        <span onClick={() => projectData(row.id)} className="d-flex align-items-center cursor-pointer">

                            <Icon
                                color={row?.corrections_revision?.length > 0 ? 'error' : ''}
                                className="mx-1"
                            >info</Icon>
                            {t(
                                row?.cancel ? t('Project Cancelled')
                                    : row?.assignorderprocessings?.length >= 2 ? 'Elektromontage'
                                        : row?.assignorderprocessings?.length == 1 ? 'Dachmontage'
                                            : row?.projectstatus
                            )}
                        </span>
                    </div>
                ) : ''}
            </>,

        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ? (
                                    <>
                                        <li><NavLink to={'/projects/' + row.id} className="dropdown-item">{t('Edit')}</NavLink></li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'projects', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        if(!props?.ready) return;

        setLoading(true);
        var parameters = "search=" + searchKey + "&page=" + pageNumber;
        for (let [key, value] of Object.entries(filterKeys)) {
            parameters += "&" + key + "=" + value;
        }

        if (!filterKeys?.status) {
            var filter_keys = (Cookies.get('user_cache') ? (JSON.parse(Cookies.get('user_cache'))?.reseller_project_status_filter ? JSON.parse(JSON.parse(Cookies.get('user_cache'))?.reseller_project_status_filter) : []) : [])
            var dataKeys = filterKeys;
            var status = ['8-Storno'];
            for (let [option_key, option] of Object.entries(filter_keys)) {
                status.push(option?.value)
            }
            parameters += "&status=" + JSON.stringify(status);
        }


        //parameters += "&status_not_in=" + JSON.stringify(['1-Verkauft']);

        if (props?.menu) {
            if (props?.menu == 'Wiedervorlage') {
                parameters += "&wiedervorlage=yes";
            }
        }

        callFetch("projects?" + parameters, "GET", []).then((res) => {
            setTableData(res.data.projects);
            setLoading(false);
            Cookies.set('user_cache', res.cache);
        });
    }, [searchKey, filterKeys, pageNumber, refresh, props?.ready]);

    /*
    useEffect(()=>{
        if(searchKey.length > 0){
            callFetch("prject/search/"+searchKey, "GET", []).then((res) =>{
                setTableData(res.data);
            })
        }else{
            setRefresh(refresh + 1)
        }
    },[searchKey])
    */

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            selectableRows
            onSelectedRowsChange={handleChange}
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            paginationComponent={BootyPagination}
            subHeader
            subHeaderComponent={
                <>
                    {selectedRows?.length >= 1 &&

                        <div style={{ position: 'absolute', left: '15px', width: '450px', marginTop: '15px' }}>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <select className='form-control' onChange={(e) => handleChangeAction(e.target.value)}>
                                        <option value="delete">Löschen</option>
                                        <option value="Wiedervorlage">Wiedervorlage</option>
                                    </select>
                                </div>
                                {action == 'Wiedervorlage' ? (
                                    <div className='col-sm-4'>
                                        <input type='date' className='form-control'  onChange={(e) => setWiedervorlage(e.target.value)}/>
                                    </div>
                                ) : ''}

                                <div className='col-sm-4'>
                                    <buttn className="btn btn-secondary" style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={() => handleSelectedRowsApply()}>Anwenden</buttn>
                                </div>
                            </div>
                        </div>

                    }

                    <Select
                        placeholder={t('Select')}
                        options={[
                            { value: '2-Kontte nicht verkauft werden', label: '2-Kontte nicht verkauft werden' },
                            { value: '3-Angebot', label: '3-Angebot' },
                            { value: '4-Überleger', label: '4-Überleger' },
                            { value: '5-Kunde war nicht da', label: '5-Kunde war nicht da' },
                            { value: '6-Kein Interesse / Keine Beratung', label: '6-Kein Interesse / Keine Beratung' },
                            { value: '7-Technisch nicht möglich', label: '7-Technisch nicht möglich' },
                            { value: '8-Storno', label: '8-Storno' },
                            { value: '9-Kundenseitig abgesagt', label: '9-Kundenseitig abgesagt' },
                            { value: '10-Entscheider waren nicht da', label: '10-Entscheider waren nicht da' },
                            { value: '0-Zeitlich nicht geschaft', label: '0-Zeitlich nicht geschaft' },
                        ]}
                        defaultValue={(Cookies.get('user_cache') ? (JSON.parse(Cookies.get('user_cache'))?.reseller_project_status_filter ? JSON.parse(JSON.parse(Cookies.get('user_cache'))?.reseller_project_status_filter) : []) : [])}
                        onChange={(e) => {

                            let found = e.find(e => e.value === '8-Storno');                            
                            if(!found){
                                e.push({label: '8-Storno', value: '8-Storno'})
                            }

                            var dataKeys = filterKeys;
                            var status = [];
                            for (let [option_key, option] of Object.entries(e)) {
                                status.push(option?.value)
                            }
                            //status.push('8-Storno')

                            //dataKeys.status = status.toString();
                            dataKeys.status = JSON.stringify(status);                            
                            dataKeys.cache_reseller_project_status_filter = JSON.stringify(e);
                            setFilterKeys(dataKeys);
                            setRefresh(refresh + 1);
                        }}
                        className='react-select-filter'
                        isMulti
                    />

                    <input
                        type="text"
                        placeholder={t("Search...")}
                        className=' form-control w-sm-50 w-md-25 w-lg-15 ms-3'
                        defaultValue={searchKey}
                        /*
                        onChange={(e)=> {
                            setSearchKey(e.target.value);
                        }}
                        */
                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setSearchKey(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                </>
            }
        />

        <ImportModal type={'project'} refreshParent={() => setRefresh(refresh + 1)} />
        <ImportModal type={'projectWithSales'} refreshParent={() => setRefresh(refresh + 1)} />
    </>
}

export default IndexTable;
