import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import Cookies from 'js-cookie';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function Index(props) {
    const { t } = useTranslation();
    const [currentAttachmentTab, setCurrentAttachmentTab] = useState('');
    const [tabs, setTabs] = useState({});
    const [tabsdata, setTabsData] = useState({});
    const [refresh, setRefresh] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const handeleAttachment = (name, newData) => {
        //clearErrors();
        clearErrors(name);

        if (tabsdata[name]) {
            var updateTabsdata = tabsdata;
            updateTabsdata[name].push(newData);
            //console.log('Append Tab Data');
        } else {
            var updateTabsdata = tabsdata;
            updateTabsdata[name] = [];
            updateTabsdata[name].push(newData);
        }
        setTabsData(updateTabsdata);
        setRefresh(refresh + 1);
    }

    const removeAttachment = (id) => {
        var beforeRemoveTabsdata = tabsdata;
        var updateTabsdata = tabsdata[currentAttachmentTab];
        const afterRemove = updateTabsdata.filter(value => {
            return value.id != id;
        });
        beforeRemoveTabsdata[currentAttachmentTab] = afterRemove;
        setTabsData(beforeRemoveTabsdata);
        setRefresh(refresh + 1);
    }

    const fileUploadTab = (name) => {
        //console.log(name);
        setCurrentAttachmentTab(name);
    }

    useEffect(() => {
        if (!props?.defaultTab) return;

        setCurrentAttachmentTab(props?.defaultTab);
        if (props?.defaultFiles) {
            setTabsData(props?.defaultFiles);
        }

    }, [props?.defaultTab, props?.defaultFiles]);

    useEffect(() => {
        if (refresh <= 0) return;

        props.callBackData(tabsdata);

    }, [refresh]);

    const dropZoneInitialize = () => {
        return (
            <SoftDropzone
                options={{
                    dictDefaultMessage: t('Bilder Hochladen'),
                    // addRemoveLinks: true,  
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.doc,.docx",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(currentAttachmentTab, response.data);
                        }
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <ul className="order_upload_tabs" id="order_upload_tabs">
                        {props?.tabs && props.tabs ? props.tabs.map((tab) => (
                            <li className={currentAttachmentTab == tab?.name ? 'active' : ''} onClick={() => { fileUploadTab(tab?.name) }} >{t(tab?.titel)}</li>
                        )) : <></>}
                    </ul>

                    {/*errors.dachansicht && errors.dachansicht.message ? (
                        <div className="invalid-feedback d-block">{t('The dachansicht is required.')}</div>
                    ) : errors.dachsparren && errors.dachsparren.message ? (
                        <div className="invalid-feedback d-block">{t('The dachsparren is required.')}</div>
                    ) : ''*/}
                    {props?.dropZonValidationError ? (
                        <div className="invalid-feedback d-block">{t(props?.dropZonValidationError)}</div>
                    ) : ''}
                </div>
                <div className="col-md-8">
                    <SoftBox>
                        <SoftBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-end"
                            height="100%"
                        >
                            {!props?.readOnly ? (
                                <>{dropZoneInitialize()}</>
                            ) : ''}

                        </SoftBox>
                    </SoftBox>


                    <SoftBox p={3} className="order-processing">
                        <Grid container spacing={3}>
                            {tabsdata[currentAttachmentTab] ? tabsdata[currentAttachmentTab].map(function (data, i) {
                                return (
                                    <Grid item key={i} className="text-center">
                                        <div>
                                            <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data.attachment}>
                                                <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data.attachment} />
                                            </a>
                                        </div>
                                        {!props?.readOnly ? (
                                            <a onClick={() => { removeAttachment(data.id) }}>{t('Remove')}</a>
                                        ) : ''}
                                    </Grid>
                                );
                            }) : <><Grid></Grid></>}
                        </Grid>
                    </SoftBox>
                </div>
            </div>
        </>
    )
}

export default Index;