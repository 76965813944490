import { useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import callFetch from 'helpers/callFetch';
import EditPlan from "../EditPlan";

const OverviewListTable = () => {
    const { t } = useTranslation();
    let params = useParams();
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [planning, setPlanning] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")

    const tableHeadings = [
        {
            name: t('Id'),
            selector: row => <NavLink to={'/projects/' + row?.id} className="text-primary">
                <>
                    {
                        row?.dachmontag && row.type == 'DC' ? 'DC-' + row?.dachmontag?.id
                            : row?.elektromontage && row.type == 'AC' ? 'AC-' + row?.elektromontage?.id
                                : row.id
                    }
                </>
            </NavLink>,
            sortable: true,
        },

        {
            name: t('Project Nr.'),
            selector: row => <NavLink to={'/projects/' + row?.id} className="text-primary">{row.id}</NavLink>,
            sortable: true,
        },

        {
            name: t('Technician Name'),
            sortable: true,
            cell: row => (
                <>
                    {
                        row.type == 'DC' ? row?.dachmontag?.employee?.name
                            : row.type == 'AC' ? row?.elektromontage?.employee?.name
                                : ''
                    }
                </>
            ),
        },
        {
            name: t('Abteilung'),
            sortable: true,
            selector: row => (
                <>
                    {
                        row.type == 'DC' ? row?.dachmontag?.employee?.roles[0]?.name
                            : row.type == 'AC' ? row?.elektromontage?.employee?.roles[0]?.name
                                : ''
                    }
                </>
            ),
        },
        {
            name: t('Planung'),
            sortable: true,
            selector: row => (
                <>
                    {
                        row.type == 'DC' ? (
                            <>
                                {row?.dcabnameprotokoll && row?.dcabnameprotokoll?.comisioning_status == 2 ? t('Reklamation')
                                    : row?.dachmontag ? t('Planed')
                                        : t('DC Open')
                                }
                            </>
                        )
                            : row.type == 'AC' ? (
                                <>
                                    {row?.acabnameprotokoll && row?.acabnameprotokoll?.comisioning_status == 2 ? t('Reklamation')
                                        : row?.elektromontage ? t('Planed')
                                            : t('AC Open')
                                    }
                                </>)
                                : ''
                    }
                </>
            ),
        },
        {
            name: t('Stadium'),
            sortable: true,
            selector: row => row?.stadium,
        },
        {
            name: t('Action'),
            sortable: true,
            selector: row => (
                <>
                    {row?.action != 'Reklamation' ?
                        <a
                            className="btn btn-primary btn-sm m-0"
                            onClick={() => {

                                var action = (
                                    row.type == 'DC' ? (row?.dachmontag ? 'edit_plan' : 'create_plan')
                                        : row.type == 'AC' ? (row?.elektromontage ? 'edit_plan' : 'create_plan')
                                            : ''
                                );

                                var title = (
                                    row.type == 'DC' ? (row?.dachmontag ? row?.dachmontag?.dachmontag_elektromontage : 'DC')
                                        : row.type == 'AC' ? (row?.elektromontage ? row?.elektromontage?.dachmontag_elektromontage : 'AC')
                                            : ''
                                );

                                var id = (
                                    row.type == 'DC' ? (row?.dachmontag ? row?.dachmontag?.id : '')
                                        : row.type == 'AC' ? (row?.elektromontage ? row?.elektromontage?.id : '')
                                            : ''
                                );


                                var data = {
                                    id: id,
                                    project_nr: row?.id,
                                    title: title,
                                    action: action,
                                };

                                if (action == "create_plan") {
                                    document.getElementById('triggercardNewPlanBtn' + row?.id).click();
                                } else if (action == "edit_plan") {
                                    setPlanning(data);
                                    document.getElementById('triggerEditPlanBtn').click();
                                }
                            }}
                        >{t('Planning')}</a>
                        : ''}
                </>
            ),
        }
    ];

    useEffect(() => {
        setLoading(true);

        if (params?.plantype && params?.status) {
            var requestUrl = "filed_service/overViewList?plantype=" + params.plantype + "&&status=" + params.status + "&&page=" + pageNumber;
        } else {
            var requestUrl = "filed_service/overViewList?page=" + pageNumber;
        }

        callFetch(requestUrl, "GET", []).then((res) => {
            setTableData(res.data);
            setLoading(false);
        });

    }, [pageNumber, refresh, searchKey, params]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata?.from} {t('to')} {tabledata?.to} {t('of')} {tabledata?.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
                subHeader
                subHeaderComponent={<input className=' form-control w-sm-50 w-md-25 w-lg-15' type="text" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
            />


            <EditPlan data={planning} setRefresh={setRefresh} />
        </>
    );

}

export default OverviewListTable
