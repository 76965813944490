import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next'; 
import { Navigate, useParams, NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
import Timeline from "../create/Timeline";
import { callFetch } from "../../../helpers/callFetch";
import Overview from "../Overview";
import SoftTypography from 'components/SoftTypography';
import IndexTable from "./IndexTable";
import { useForm } from "react-hook-form";
import SoftSnackbar from "components/SoftSnackbar";
import SoftBox from "components/SoftBox";
function Index(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [saving, setSaving] = useState(false); 
    const [articles, setArticles] = useState([]);
    const [articlesNew, setArticlesNew] = useState([]);
    const [items, setItems] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false); 
    const closeErrorSB = () => setErrorSB(false);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Quantity was updated.')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const {
        reset, resetField, register, handleSubmit, setError, setValue, getValues,
        formState: { errors },
      } = useForm();
    
      function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }
    
      const handleArticleChange = (e, index) => {
        const articleId = e.target.value;

        if(articleId){
            callFetch("items-by-prefix/"+articleId, "GET", []).then((res)=>{
                if(res.prefix == 'Art'){
                     
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.article_name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                }else{  
                    if(res?.data){
                        const updatedItems = [...items];
                        updatedItems[index].article_name = res?.data?.name;
                        updatedItems[index].article_nr = res?.id;
                        updatedItems[index].prefix = res?.prefix;
                        setItems(updatedItems);

                    }
                } 

            })
        }

    };
    
    useEffect(() => {
            callFetch("quation-info-for-material/" + (props?.projectid ? props?.projectid : params.id) + "?project_id=" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => {
                setData(res?.data);
                setArticlesNew(res?.articles);
                setValue('hacken', res?.data?.quations?.anzahl_module * 2.5);
                setValue('schiene', res?.data?.quations?.anzahl_module * 2.5); 
                setValue('module', res?.data?.quations?.anzahl_module); 
                setValue('speicher_identity', res?.data?.quations?.speichers?.speicher_identity); 
                setValue('identity', res?.data?.quations?.wechselrichter?.identity); 
                setValue('module_identity', res?.data?.quations?.module?.module_identity); 
            });

            callFetch("goods/create", "GET", []).then((res) => {
                setValue('receive_number',res?.goods_number)  
                setArticles(res?.data?.articles)
            });
    }, [refresh]);

    const addProjectMaterial = () => {
        var formData            = new FormData();
        formData.items          = JSON.stringify(items);
        formData.project_id     = props?.projectid ? props?.projectid : params.id;
        callFetch("store-project-material", "POST", formData, setError).then((res) => { 
            console.log(res.message);
            setRefresh(refresh + 1);
            setItems([]);
        });
    }

    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true);
        formData.project_id     = props?.projectid ? props?.projectid : params.id;
        callFetch("deduct-products-qty", "POST", formData, setError).then((res) => { 
            setSaving(false); 
            if(res.message == 'success'){
                setSuccessSB(true);
            }else{
                setErrorSB(true);
            }
            
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-9 inner-main">
                  
                        <div className='row'>
                            <div className="col-12"> 
                                <Overview project_id={(props?.projectid ? props?.projectid : params.id)} title={t('Materialliste')} ></Overview>
                            </div>
                            <div className="col-sm-12 mt-3">
                                 <SoftBox>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="card">
                                            <div className="card-body">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                            <th style={{paddingLeft:'8px', width: '120px'}}><SoftTypography variant="h6">{('Product Nr.')}</SoftTypography></th>
                                                            <th style={{paddingLeft:'8px'}}><SoftTypography variant="h6">{('Description')}</SoftTypography></th>
                                                            <th style={{paddingLeft:'8px', width: '200px'}}><SoftTypography variant="h6">{('Quantity')}</SoftTypography></th>
                                                            <th style={{paddingLeft:'8px'}}><SoftTypography variant="h6">{('Supplier')}</SoftTypography></th>
                                                            <th style={{paddingLeft:'8px'}}><SoftTypography variant="h6">{('Abteilung')}</SoftTypography></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        
                                                        <tr>
                                                            <td style={{width: '120px'}}><SoftTypography variant="caption">{data?.quations?.speichers?.speicher_identity}</SoftTypography></td>
                                                            <td><SoftTypography variant="caption"><strong>{data?.quations?.speichers?.name}</strong></SoftTypography></td>
                                                            <td><input value="1" {...register('speicher')} className='form-control' style={{width: '40%', float: 'left'}}/> <SoftTypography style={{position: 'relative', top: '5px', left: '5px'}} variant="caption">Stk./m</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Senec AG</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Elektromontage</SoftTypography></td>
                                                        </tr>

                                                        <tr>
                                                            <td><SoftTypography variant="caption">{data?.quations?.wechselrichter?.identity}</SoftTypography></td>
                                                            <td><SoftTypography variant="caption"><strong>{data?.quations?.wechselrichter?.name}</strong></SoftTypography></td>
                                                            <td><input value="1" {...register('wachselrichter')} className='form-control' style={{width: '40%', float: 'left'}}/> <SoftTypography style={{position: 'relative', top: '5px', left: '5px'}} variant="caption">Stk./m</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Senec AG</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Dachmontage</SoftTypography></td>
                                                        </tr>

                                                        <tr>
                                                            <td><SoftTypography variant="caption">{data?.quations?.module?.module_identity}</SoftTypography></td>
                                                            <td><SoftTypography variant="caption"><strong>{data?.quations?.module?.name}</strong></SoftTypography></td>
                                                            <td><input {...register('module')} className='form-control' style={{width: '40%', float: 'left'}}/> <SoftTypography style={{position: 'relative', top: '5px', left: '5px'}} variant="caption">Stk./m</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Senec AG</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Dachmontage</SoftTypography></td>
                                                        </tr>

                                                        {data?.quations?.module_eingeben == 'no' ? <><tr>
                                                            <td><SoftTypography variant="caption">P-100004</SoftTypography></td>
                                                            <td><SoftTypography variant="caption"><strong>Hacken ABC Typ: 123</strong></SoftTypography></td>
                                                            <td><input {...register('hacken')} className='form-control' style={{width: '40%', float: 'left'}}/> <SoftTypography style={{position: 'relative', top: '5px', left: '5px'}} variant="caption">Stk./m</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Senec AG</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Dachmontage</SoftTypography></td>
                                                        </tr>
                                                        <tr>
                                                            <td><SoftTypography variant="caption">P-100005</SoftTypography></td>
                                                            <td><SoftTypography variant="caption"><strong>Schiene ABC Type: 456</strong></SoftTypography></td>
                                                            <td><input {...register('schiene')} className='form-control' style={{width: '40%', float: 'left'}}/> <SoftTypography style={{position: 'relative', top: '5px', left: '5px'}} variant="caption">Stk./m</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Senec AG</SoftTypography></td>
                                                            <td><SoftTypography variant="caption">Elektromontage</SoftTypography></td>
                                                        </tr></> : ''} 

                                                        {articlesNew?.map((item) => (
                                                            <tr>
                                                                <td><SoftTypography variant="caption">{item.product_id}</SoftTypography></td>
                                                                <td><SoftTypography variant="caption"><strong>{item.article_name}</strong></SoftTypography></td>
                                                                <td><input value={item.quantity} className='form-control' style={{width: '40%', float: 'left'}}/> <SoftTypography style={{position: 'relative', top: '5px', left: '5px'}} variant="caption">Stk./m</SoftTypography> </td>
                                                                <td><SoftTypography variant="caption">{item.supplier_name}</SoftTypography></td>
                                                                <td><SoftTypography variant="caption">Elektromontage</SoftTypography></td>
                                                            </tr>
                                                        ))}

                                                        {
                                                            items && items?.map((item, i) =>(
                                                                <tr>
                                                                <td>
                                                                <div className="col-md-12">
                                                                    <select className="form-control" onChange={(e)=>{
                                                                        handleArticleChange(e, i)
                                                                        items[i].article_nr = parseFloat(e.target.value)
                                                                        setItems(items);
                                                                    }}>
                                                                        <option value="">{t('----')}</option>
                                                                        {
                                                                            articles && articles?.map((article,index)=>(
                                                                            <option key={index} value={article?.article_nr}>{t(article?.article_nr+" - "+article?.article_name)}</option>
                                                                            ))
                                                                        }
                                                                        
                                                                    </select>

                                                                    {/* <Select
                                                                            placeholder={t('Select')}
                                                                            options={articles ? articles : []}
                                                                            getOptionLabel={(option) => ((option?.article_name ? option?.article_name : ''))}
                                                                            getOptionValue={(option) => option?.article_nr}
                                                                            value={''}
                                                                            onChange={(e) => {
                                                                                setValue('verkaufer', e.user.id); 
                                                                            }}

                                                                            required
                                                                            styles={{
                                                                                fontSize: '0.875rem',
                                                                                control: (baseStyles, state) => ({
                                                                                    ...baseStyles,
                                                                                    fontSize: '0.875rem',
                                                                                }),
                                                                            }}
                                                                        /> */}
                                                                </div>
                                                                </td>
                                                                <td>
                                                                <div className="col-md-12">
                                                                    <input className="form-control" defaultValue={items[i].article_name} onChange={(e)=>{
                                                                    items[i].article_name = e.target.value
                                                                    setItems(items);
                                                                    }} />
                                                                </div>
                                                                </td>

                                                                <td colSpan={3}><div className="col-md-5" style={{position: 'relative'}}> 
                                                                    <div className="d-flex align-items-center">
                                                                        <input type="number"  style={{width: '40%', float: 'left'}} value={parseInt(items[i].qty)} className="form-control" placeholder="eg. 0.00" onChange={(e) => {
                                                                            items[i].qty = parseFloat(e.target.value);
                                                                            setItems(items);
                                                                            setRefresh(refresh + 1);
                                                                            }} />
                                                                        &nbsp;
                                                                        <i className="fa-solid fa-circle-xmark text-danger cursor-pointer" data-key={i} onClick={() => { delete items[i]; deleteProduct(); }}></i>
                                                                        {saving ? <button className="btn btn-secondary pull-right" disabled style={{ marginRight: '15px', position: 'absolute', right: '0px', top: '0px' }}>
                                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                            </button> :  <button className="btn btn-primary" type="button" style={{position: 'absolute', right: '0px', top: '0px'}} onClick={() => addProjectMaterial()}>Save</button> }
                                                                        
                                                                    </div>
                                                                </div></td>
                                                                </tr>
                                                            ))
                                                        }
                                                        
                                                       
                                                        

                                                        <tr>
                                                            <td><button className="btn btn-primary" type="button" onClick={() => { setItems([...items, { article_nr:0, article_name:"", qty:1 }]); setRefresh(refresh + 1); }}> + {t('Add')}</button> <button className="btn btn-primary">{t('Save')}</button></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </form>
                                 </SoftBox>
                            </div>
                        </div> 

                </div>
                <div className="col-sm-3 inner-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {props.projectid ? props.projectid : '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Timeline refresh={refresh} acStatusEdit={true} />
                </div>
            </div>
            {renderSuccessSB}
            {renderErrorSB}
        </>
    );
}

export default Index;
