// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React icons
import SpaceShip from "examples/Icons/SpaceShip";
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Settings from "examples/Icons/Settings";
import CreditCard from "examples/Icons/CreditCard";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Sidenav() {
  const { t } = useTranslation();

  const sidenavItems = [
    { icon: <Shop />, label: t("Geschaftsfuhrung"), href: "management" },
    { icon: <Settings />, label: t("Sales Person"), href: "sales-person" },
    { icon: <Cube />, label: t("Sales Manager"), href: "sales-manager" },
    { icon: <Office />, label: t("Innendienst"), href: "indoor-service" },
    { icon: <Cube />, label: t("Call Center"), href: "call-center" },
    { icon: <Document />, label: t("AC / DC Techniker"), href: "acdc-techniker" },
    { icon: <SpaceShip />, label: t("AC / DC Manager"), href: "acdc-manager" },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <NavLink 
          to={`/${href}`}
        >
          <SoftTypography
            component="a"
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            sx={({
              borders: { borderRadius },
              functions: { pxToRem },
              palette: { light },
              transitions,
            }) => ({
              display: "flex",
              alignItems: "center",
              borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                backgroundColor: light.main,
              },
            })}
          >
            <SoftBox mr={1.5} lineHeight={1}>
              {icon}
            </SoftBox>
            {label}
          </SoftTypography>
        </NavLink>
      </SoftBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

export default Sidenav;
