import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";

import Calendar from "examples/Calendar";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
// Data
import Cookies from "js-cookie";
// Images
import callFetch from "../../helpers/callFetch";

import AbsenceRequest from "../absenceRequest/Create";
import AbsenceRequestEdit from "../absenceRequest/Edit";

import ProjectStatus from 'pages/project/ProjectStatus';
import NewAufgabe from './layouts/NewAufgabe';
import EditPlan from "pages/reports/EditPlan";
import allLocales from '@fullcalendar/core/locales-all';

function Index(props) {
    const { t } = useTranslation();
    const [attachments, setAttachment] = useState([]);
    const [calendarEventsData, setCalendarEventsData] = useState([]);
    const [initialDate, setInitialDate] = useState(dateFormat(new Date(), "yyyy-mm-dd"));
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [serach, setSearch] = useState('');
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [absenceId, setAbsenceId] = useState(0);
    const [refreshAbsemce, setRefreshAbsemce] = useState(0);
    const [reloadCalendarData, setReloadCalendarData] = useState(0);

    const [isUpdate, setIsUpdate] = useState(false);
    const [planning, setPlanning] = useState([]);
    const [editEvent, setEditEvent] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [abhnameStatus, setAbhnameStatus] = useState('');
    const [year, setYear] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [week, setWeek] = useState('');

    const [tooltipInfo, setTooltipInfo] = useState(null);









    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const toggle = () => {
        setModal(modal);
    };

    const handleEventDrop = (event) => {
        if (event.event?._def?.extendedProps?.absence) {
            console.log("absence can't update");
            //setReloadCalendarData(reloadCalendarData+1);
        } else {
            var start_date = new Date(event.event._instance.range.start);
            var end_date = new Date(event.event._instance.range.end);

            /*
            var month = Number(date.getMonth()) + 1;
            var date_day = Number(date.getDate());
            if (month <= 9) {
                month = "0" + month;
            }
            if (date_day <= 9) {
                date_day = "0" + date_day;
            }
            */

            var formData = new FormData();
            formData.id = event.event._def.publicId;
            //formData.date = date.getFullYear() + "-" + month + "-" + date_day;
            formData.start_date = start_date.toISOString();
            formData.end_date = end_date.toISOString();
            //formData.color = event.event._def.ui.backgroundColor;
            formData.event_id = (event?.event?.extendedProps?.event_id ? event?.event?.extendedProps?.event_id : event.event._def.publicId);
            formData.plan_form = event?.event?.extendedProps?.event_type;

            callFetch("update-event-date", "POST", formData, setError).then((res) => {
                //console.log(res.message);
                setSaving(false);
                if (!res.ok) return;

                if (editEvent) {

                    var data = {
                        id: event?.event?.id,
                        project_nr: event.event._def.extendedProps.project_nr,
                        title: event.event._def.extendedProps.event_titel,
                        action: "edit_plan",
                        planform: (event.event._def.extendedProps.event_type == 'order_processing' ? "Projects" : event.event._def.extendedProps.event_type == 'ACFertigmeldung' ? 'ACFertigmeldung' : "Reclamation"),
                    };

                    setPlanning(data);
                    setRefresh2(refresh2 + 1);
                    document.getElementById('triggerEditPlanBtn').click();

                }
            });
        }
    };

    const getWeekNumber = (date) => {
        var d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        var yearStart = new Date(d.getFullYear(), 0, 1);
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        return weekNo;
    }

    const handleEventClick = ({ event, el }) => {

        //console.log('event?._def?.extendedProps');
        //console.log(event?._def?.extendedProps);

        if (event?._def?.extendedProps?.absence) {
            if (Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename != "Call Center") {
                setRefreshAbsemce(refreshAbsemce + 1);
                setAbsenceId(event?.id);
                document.getElementById("absenceRequestEditBtn").click();
            }
        } else {

            if (event?._def?.extendedProps?.event_type == 'project') {
                if (document.getElementById("home1-tab")) {
                    document.getElementById("home1-tab").click();
                }

                callFetch("project-event/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.vorname + " " + res.data.name,
                        id: event.id,
                        project_id: event.id,
                        salse_person: res?.data?.salse_person?.name ? res?.data?.salse_person?.name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        event_type: event?._def?.extendedProps?.event_type,
                    });

                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();

                });
            } else if (event?._def?.extendedProps?.event_type == 'order_processing') {

                if (document.getElementById("home2-tab")) {
                    document.getElementById("home2-tab").click();
                }

                callFetch("event-order-processing/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.name,
                        id: event.id,
                        project_id: res.data.project_id,
                        salse_person: res?.data?.salse_person_name ? res?.data?.salse_person_name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        modul: (res.data?.anzahl_module + ' Stck. | ' + (res.data?.module_eingeben == 'no' ? res.data?.module_short_title : res.data?.custom_module_short_title)),
                        speicher: (res.data?.speicher_eingeben == 'no' ? res.data?.speicher_name : res.data?.custom_speicher_name),
                        dachtyp: res.data?.dachtyp,
                        event_type: event?._def?.extendedProps?.event_type,
                    });
                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();
                });
            } else if (event?._def?.extendedProps?.event_type == 'reclamation') {

                if (document.getElementById("home2-tab")) {
                    document.getElementById("home2-tab").click();
                }

                callFetch("event-reclamation/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.name,
                        id: event.id,
                        project_id: res.data.project_id,
                        reclamation_id: res?.data?.reclamation_id ? res?.data?.reclamation_id : '',
                        reclamation_description: res?.data?.reclamation?.description ? res?.data?.reclamation?.description : '',
                        salse_person: res?.data?.salse_person_name ? res.data.salse_person_name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        products: (res?.data?.reclamation?.products ? JSON.parse(res?.data?.reclamation?.products) : []),
                        //modul: (res.data?.anzahl_module + ' Stck. | ' + (res.data?.module_eingeben == 'no' ? res.data?.module_short_title : res.data?.custom_module_short_title)),
                        //speicher: (res.data?.speicher_eingeben == 'no' ? res.data?.speicher_name : res.data?.custom_speicher_name),
                        dachtyp: '',
                        event_type: event?._def?.extendedProps?.event_type,
                    });
                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();
                });
            } else if (event?._def?.extendedProps?.event_type == 'ACFertigmeldung') {

                if (document.getElementById("home2-tab")) {
                    document.getElementById("home2-tab").click();
                }

                callFetch("event-zahlertermin/" + event.id, "GET", []).then((res) => {
                    //setEmployees(res.employees);

                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'file') {
                            if (value != null && value != "null") {
                                setAttachment(JSON.parse(value));
                                setValue("file", value);
                            } else {
                                setAttachment([]);
                            }
                        } else {
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }

                    setModalData({
                        title: res.data.name,
                        id: event.id,
                        project_id: res.data.project_id,
                        salse_person: res?.data?.salse_person_name ? res?.data?.salse_person_name : '',
                        name: res.data.name,
                        plz: res.data.plz,
                        telefonnummer: res.data.telefonnummer,
                        desc: res.data.description,
                        start: res.data.time,
                        end_time: res.data.end_time,
                        status: res.data.status,
                        modul: (res.data?.anzahl_module + ' Stck. | ' + (res.data?.module_eingeben == 'no' ? res.data?.module_short_title : res.data?.custom_module_short_title)),
                        speicher: (res.data?.speicher_eingeben == 'no' ? res.data?.speicher_name : res.data?.custom_speicher_name),
                        dachtyp: res.data?.dachtyp,
                        event_type: event?._def?.extendedProps?.event_type,
                    });
                    setIsUpdate(false);
                    document.getElementById("calanderDataOpen").click();
                });
            }
        }
    };

    useEffect(() => {

        if (!props?.readyToLoad) return;
        if (!startDate) return;
        if (!endDate) return;

        var fromData = {};
        fromData.serach = serach;
        fromData.projects = (props?.projects && props?.projects == true ? 1 : 0);
        fromData.assign_orders = (props?.assign_orders && props?.assign_orders == true ? 1 : 0);
        fromData.reclamations = (props?.reclamations && props?.reclamations == true ? 1 : 0);
        fromData.absence = (props?.absence && props?.absence == true ? 1 : 0);
        fromData.startDate = startDate;
        fromData.endDate = endDate;

        if (props?.teamsEvent == true) {
            fromData.teams = JSON.stringify(props?.teams);
        }

        if (props?.callcenter) {
            fromData.callcenter = props?.callcenter;
        }

        if (props?.teamMembers) {
            fromData.teamMembers = JSON.stringify(props?.teamMembers);
        }

        if (abhnameStatus) {
            fromData.abhnameStatus = abhnameStatus;
        }

        if (props?.editEvent == true) {
            setEditEvent(true);
        }

        var calendar = props.class == 'calendar-main' ? '?calendar=main' : '';

        //setCalendarEventsData([]);
        callFetch("projects/calanderdata" + calendar, "POST", fromData).then((res) => {
            setCalendarEventsData(res.data);
            console.log(res.data);
            setInitialDate(res.initialDate);
        });
    }, [reloadCalendarData, isUpdate, serach, refresh, startDate, endDate, abhnameStatus, props?.refresh, props?.teams, props?.teamsEvent, props?.teamMembers, props?.readyToLoad, props?.editEvent]);


    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Changes saved successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {

        console.log(formData);
        return;
        setSaving(true);
        if (modalData?.event_type == 'order_processing') {
            //console.log(getValues('event_id'));
            callFetch(
                "update-order-event/" + modalData.id,
                "POST",
                formData,
                setError
            ).then((res) => {
                //openSuccessSB();
                setSaving(false);
                setRefresh(refresh + 1);

                if (!res.ok) return;
                var eventModalClose = document.getElementById("projectInfoModalClose");
                eventModalClose.click();
            });
        } else if (modalData?.event_type == 'ACFertigmeldung') {
            //console.log(getValues('event_id'));
            callFetch(
                "update-zahlertermin/" + modalData.id,
                "POST",
                formData,
                setError
            ).then((res) => {
                //openSuccessSB();
                setSaving(false);
                setRefresh(refresh + 1);

                if (!res.ok) return;
                var eventModalClose = document.getElementById("projectInfoModalClose");
                eventModalClose.click();
            });
        } else if (modalData?.event_type == 'project') {
            callFetch(
                "update-project-event/" + modalData.id,
                "POST",
                formData,
                setError
            ).then((res) => {
                //openSuccessSB();
                setSaving(false);
                setRefresh(refresh + 1);

                if (!res.ok) return;
                var eventModalClose = document.getElementById("projectInfoModalClose");
                eventModalClose.click();
            });
        } else {
            console.log('Reclamation update')
            setSaving(false);
        }
    };

    let timer;
    const waitTime = 2000;

    const getAbhnamheStatus = (status) => {
        setAbhnameStatus(status);
    }

    const searchKey = (key) => {


        clearTimeout(timer);

        timer = setTimeout(() => {
            setSearch(key);
        }, waitTime);

        /*
        if (key.length > 0) {
            callFetch("calendardata/search/" + key, "GET", []).then((res) => {
                setCalendarEventsData(res.data);
            });
        } else {
            setIsUpdate(false);
            setSearch(serach + 1);
        }
        */
    };

    const handleEventMouseEnter = (arg) => {
        setTooltipInfo({
            data: arg.event,
            top: arg.jsEvent.clientY + 10,
            left: arg.jsEvent.clientX,
        });
    };

    const handleEventMouseLeave = () => {
        setTooltipInfo(null);
    };

    return (
        <>
            <SoftBox className={props.class == 'calendar-main' ? 'calendar-main' : 'fullcalanderCustomDesign'}>

                {useMemo(
                    () => (
                        <>
                            {Cookies.get("permissions").indexOf(
                                "CalAllE"
                            ) !== -1 ||
                                Cookies.get("permissions").indexOf(
                                    "CalOwnE"
                                ) !== -1 ? (
                                <>
                                    <Calendar
                                        locales={allLocales}
                                        allDaySlot={false}
                                        locale={'de'}
                                        weekNumbers={true}
                                        dashboard={true}
                                        abhnameFilter={true}
                                        searchKey={searchKey}
                                        getAbhnamheStatus={getAbhnamheStatus}
                                        initialView="dayGridMonth"
                                        views={{
                                            dayGridMonth: {
                                                dayMaxEventRows: 6,
                                            },
                                            timeGridWeek: {
                                                dayMaxEventRows: 4,
                                            },
                                        }}
                                        initialDate={initialDate}
                                        events={calendarEventsData}
                                        eventClick={(e) => {

                                            if (editEvent) {
                                                var data = {
                                                    id: e.event.id,
                                                    project_nr: e.event._def.extendedProps.project_nr,
                                                    title: e.event._def.extendedProps.event_titel,
                                                    action: "edit_plan",
                                                    planform: (e.event._def.extendedProps.event_type == 'order_processing' ? "Projects" : e.event._def.extendedProps.event_type == 'ACFertigmeldung' ? 'ACFertigmeldung' : "Reclamation"),
                                                };

                                                setPlanning(data);
                                                setRefresh2(refresh2 + 1);

                                                document.getElementById('triggerEditPlanBtn').click();

                                            } else {
                                                handleEventClick(e);
                                            }
                                        }}
                                        eventDrop={(e) => {
                                            handleEventDrop(e);
                                        }}

                                        datesSet={(arg) => {
                                            //console.log(arg.start) //starting visible date
                                            //console.log(arg.end) //ending visible date




                                            var start_date = new Date(arg.start);

                                            start_date = start_date.toISOString();
                                            var end_date = new Date(arg.end);
                                            end_date = end_date.toISOString();
                                            setStartDate(start_date);
                                            setEndDate(end_date);
                                        }}
                                        selectable
                                        editable={JSON.parse(Cookies.get('permissions')).indexOf("CnCcE") !== -1 ? false : true}
                                        /*
                                        buttonText={{
                                            today: t("today"),
                                            week: t("week"),
                                        }}
                                        */
                                        buttonText={{
                                            today: t('today'),
                                            day: t('day'),
                                            week: t('week'),
                                            month: t('month'),
                                            list: t('list'),
                                        }}
                                        dayHeaderContent={(arg) => {
                                            return [
                                                t("Sun"),
                                                t("Mon"),
                                                t("Tue"),
                                                t("Wed"),
                                                t("Thu"),
                                                t("Fri"),
                                                t("Sat"),
                                            ][arg.date.getDay()];
                                        }}
                                        customButtons={{
                                            absenceRequest: {
                                                text: "+ " + t("Absence"),
                                                click: function () {
                                                    document
                                                        .getElementById("absenceRequestBtn")
                                                        .click();
                                                },
                                            },
                                        }}
                                        headerToolbar={{
                                            left: "prev,next today",
                                            center: 'title',
                                            right:
                                                Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Call Center" ||
                                                    Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Sales Person" ?
                                                    "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                                                    : "absenceRequest dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                        }}
                                        /*
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}
                                        */
                                        eventTimeFormat={{
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: false,
                                        }}
                                        eventMouseEnter={handleEventMouseEnter}
                                        eventMouseLeave={handleEventMouseLeave}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                    [calendarEventsData]
                )}
            </SoftBox>

            {tooltipInfo && (
                <div
                    style={{
                        position: 'fixed',
                        top: tooltipInfo.top,
                        left: tooltipInfo.left,
                        border: '0px solid #ccc',
                        zIndex: 9999,
                        boxShadow: '0 0 10px 0 rgba(229, 229, 229, 0.9)',
                        borderRadius: '12px'
                    }}
                    className="fullcalender-event-hover-view"
                >
                    <div class="event-hover-view-header">
                        <img
                            style={{
                                width: '22px',
                                height: 'auto',
                                marginRight: '5px',
                                marginTop: '4px'
                            }}
                            src="/assets/img/framecopy.png"
                        />
                        <NavLink style={{
                            maxWidth: '275px',
                            position: 'absolute',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'

                        }}
                            to={'/projects/' + tooltipInfo?.data?._def?.extendedProps?.project_nr}><span style={{ textDecoration: 'none' }}> {tooltipInfo?.data?._def?.extendedProps?.project_nr}</span> <span dangerouslySetInnerHTML={{ __html: tooltipInfo?.data?._def?.extendedProps?.project_info }}></span></NavLink>
                    </div>
                    <div class="event-hover-view-body">
                        <p style={{ margin: '0px', padding: '0px' }}>
                            <span style={{ color: '#718096', fontSize: '12px' }}>Modul:</span>  <strong>{(tooltipInfo?.data?._def?.extendedProps?.anzahl_module && tooltipInfo?.data?._def?.extendedProps?.anzahl_module != 'null' ? tooltipInfo?.data?._def?.extendedProps?.anzahl_module : '') + ' Stück | ' + (tooltipInfo?.data?._def?.extendedProps?.module_eingeben == 'no' ? (tooltipInfo?.data?._def?.extendedProps?.module_short_title && tooltipInfo?.data?._def?.extendedProps?.module_short_title != 'null' ? tooltipInfo?.data?._def?.extendedProps?.module_short_title : '') : (tooltipInfo?.data?._def?.extendedProps?.custom_module_short_title && tooltipInfo?.data?._def?.extendedProps?.custom_module_short_title != 'null' ? tooltipInfo?.data?._def?.extendedProps?.custom_module_short_title : ''))}</strong>
                        </p>
                        <p style={{ margin: '0px', padding: '0px' }}><span style={{ color: '#718096', fontSize: '12px' }}>Speicher:</span> <strong> {(tooltipInfo?.data?._def?.extendedProps?.speicher_eingeben == 'no' ? (tooltipInfo?.data?._def?.extendedProps?.speicher_name != 'null' ? tooltipInfo?.data?._def?.extendedProps?.speicher_name : '') : (tooltipInfo?.data?._def?.extendedProps?.custom_speicher_name != 'null' ? tooltipInfo?.data?._def?.extendedProps?.custom_speicher_name : ''))} </strong></p>
                        <p style={{ margin: '0px', padding: '0px' }}><span style={{ color: '#718096', fontSize: '12px' }}>Dachtyp:</span> <strong> {(tooltipInfo?.data?._def?.extendedProps?.dachtyp && tooltipInfo?.data?._def?.extendedProps?.dachtyp != 'null' ? tooltipInfo?.data?._def?.extendedProps?.dachtyp : '')}</strong></p>
                        <p>
                            {(tooltipInfo?.data?._def?.extendedProps?.gerust_notwendig == 'JA' ? 'GR ' : '') + (tooltipInfo?.data?._def?.extendedProps?.frealeatung_isolierung == 'JA' ? 'FR ' : '') + (tooltipInfo?.data?._def?.extendedProps?.grabungsarbeiten == 'Yes' ? 'GA ' : '')}
                        </p>
                    </div>
                </div>
            )}

            <button
                type="button"
                id="calanderDataOpen"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#calanderModal"
            >
                View Calander Click Event Data
            </button>

            <div
                className="modal fade project-info-modal"
                id="calanderModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="calanderModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '600px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="calanderModalLabel"
                                style={{ fontWeight: "bold" }}
                            >
                                <img src="/assets/img/framecopy.png" /> <span>#{modalData?.project_id ? modalData?.project_id : modalData?.id}</span>
                            </h6>
                            <button
                                type="button"
                                className="btn-close text-dark"
                                id="projectInfoModalClose"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src="/assets/img/x.png" className="close-icon" />
                            </button>
                        </div>
                        {modalData?.event_type == 'project' && (
                            <div className="modal-body tb-ac-cs">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h6
                                        className="modal-title"
                                        id="calanderModalLabel"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {getValues("code")} -{" "}
                                        {modalData?.name}{", "}
                                        {'Plz: ' + modalData?.plz}{", "}

                                        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                            <>
                                                {'Tel: ' + modalData?.telefonnummer}{", "}
                                            </>
                                        ) : ''}

                                        {modalData.salse_person ? modalData.salse_person : ""} -{" "}
                                        {modalData.id ? modalData.id : ""}
                                    </h6>
                                    <div style={{ marginBottom: "35px" }}>
                                        <p className="m-0" style={{ fontSize: "12px" }}>
                                            {" "}
                                            {t("Start Date & Time")}: {getValues("time")} -{" "}
                                            {dateFormat(getValues("date"), "dd.mm.yyyy")}{" "}
                                        </p>
                                    </div>
                                    <ul className="nav nav-tabs mt-3" id="myTab" role="tablist" >
                                        <li className="nav-item" role="presentation">
                                            <button

                                                className="nav-link active"
                                                id="home1-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#home1-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="home1-tab-pane"
                                                aria-selected="true"
                                            >
                                                Ereignisdetails
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="desc-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#desc-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="desc-tab-pane"
                                                aria-selected="false"
                                            >
                                                Beschreibung
                                            </button>
                                        </li>

                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        style={{ fontSize: "0.75rem" }}
                                                        className="nav-link"
                                                        id="project1-status"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#project1-status-pane"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="project1-status-pane"
                                                        aria-selected="false"
                                                    >
                                                        {t("Project Status")}
                                                    </button>
                                                </li>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active pt-3"
                                            style={{ minHeight: "230.5px" }}
                                            id="home1-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="home1-tab"
                                            tabindex="0"
                                        >
                                            <table className="mt-3 mb-5">
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td width="100">
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Project Nr")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <NavLink
                                                            to={
                                                                "/projects/" +
                                                                modalData.id
                                                            }
                                                            data-bs-dismiss="modal"
                                                            style={{ textDecoration: "1px solid" }}
                                                        >
                                                            <SoftTypography
                                                                style={{
                                                                    textDecoration: "underline",
                                                                    color: "#005498",
                                                                }}
                                                                variant="caption"
                                                                color="text"
                                                            >
                                                                {modalData.id}
                                                            </SoftTypography>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Name")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {modalData.salse_person}{" "}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Time")}:{" "}
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {modalData.start ? modalData.start : ""}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Date")}:{" "}
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {dateFormat(getValues("date"), "dd.mm.yyyy")}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>
                                                <br />
                                                <tr>
                                                    <td colSpan="2">
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                            style={{ fontSize: "0.75rem", color: "#2D3748" }}
                                                        >
                                                            {t("Client Information")}:
                                                        </SoftTypography>{" "}
                                                        <br />
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Name")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {getValues("vorname") ? getValues("vorname") : ""}{" "}
                                                            {getValues("name") ? getValues("name") : ""}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>

                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Address")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <a target="_blank" href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}>
                                                            <SoftTypography variant="caption" color="text" style={{
                                                                textDecoration: "underline",
                                                                color: "#005498",
                                                            }}>
                                                                {getValues("street") ? getValues("street") : ""}
                                                                {getValues("nr") ? ' ' + getValues("nr") : ""}
                                                            </SoftTypography>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("PLZ und Ort")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <a target="_blank" href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}>
                                                            <SoftTypography variant="caption" color="text">
                                                                {getValues("plz") ? getValues("plz") : ""},{" "}
                                                                {getValues("ort") ? getValues("ort") : ""}
                                                            </SoftTypography>
                                                        </a>
                                                    </td>
                                                </tr>

                                                {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                                    <>
                                                        <tr style={{ lineHeight: "0px" }}>
                                                            <td>
                                                                <SoftTypography
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    color="text"
                                                                >
                                                                    {t("Phone")}:
                                                                </SoftTypography>
                                                            </td>
                                                            <td>
                                                                <a href={getValues("telefonnummer") ? "tel:" + getValues("telefonnummer") : "#"}>
                                                                    <SoftTypography variant="caption" color="text" style={{
                                                                        textDecoration: "underline",
                                                                        color: "#005498",
                                                                    }}>
                                                                        {getValues("telefonnummer")
                                                                            ? getValues("telefonnummer")
                                                                            : ""}
                                                                    </SoftTypography>
                                                                </a>

                                                                {getValues("mobilnummer") ? (
                                                                    <a href={getValues("mobilnummer") ? "tel:" + getValues("mobilnummer") : "#"}>
                                                                        <SoftTypography variant="caption" color="text" style={{
                                                                            textDecoration: "underline",
                                                                            color: "#005498",
                                                                        }}>
                                                                            , {getValues("mobilnummer")
                                                                                ? getValues("mobilnummer")
                                                                                : ""}
                                                                        </SoftTypography>
                                                                    </a>
                                                                ) : ''}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : ''}
                                            </table>
                                        </div>
                                        <div
                                            className="tab-pane fade pt-4"
                                            style={{ minHeight: "230.5px" }}
                                            id="desc-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="desc-tab"
                                            tabindex="0"
                                        >
                                            <SoftTypography
                                                variant="caption"
                                                fontWeight="bold"
                                                color="text"
                                                style={{ fontSize: "0.75rem", color: "#2D3748" }}
                                            >
                                                {t("Project Description")}:
                                            </SoftTypography>{" "}
                                            <br />
                                            {/* <SoftTypography variant="caption" color="text">{modalData.desc}</SoftTypography> */}
                                            <SoftTypography
                                                variant="caption"
                                                color="text"
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "17px",
                                                    display: "block",
                                                    color: "#2D3748",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                {modalData.desc}
                                            </SoftTypography>
                                        </div>
                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                <div
                                                    className="tab-pane fade pt-4"
                                                    style={{ minHeight: "230.5px" }}
                                                    id="project1-status-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="project1-status"
                                                    tabindex="0"
                                                >
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{t("Project Status")}</label>
                                                            {modalData?.id ? <ProjectStatus projectid={modalData.project_id} status={modalData?.status} /> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            flexShrink: "0",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}
                                        className="d-none"
                                    >
                                        <button
                                            type="button"
                                            style={{
                                                textTransform: "capitalize",
                                                padding: "0.50rem 1.5rem",
                                                marginRight: "10px",
                                            }}
                                            className="btn btn-outline-dark btn-start mt-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            {t("Cancel")}
                                        </button>

                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                {!saving && (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            textTransform: "capitalize",
                                                            padding: "0.50rem 2rem",
                                                        }}
                                                        className="btn bg-gradient-primary btn-start mt-2"
                                                    >
                                                        {t("Save")}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            textTransform: "capitalize",
                                                            padding: "0.50rem 2rem",
                                                        }}
                                                        className="btn btn-disabled mt-2"
                                                        disabled
                                                    >
                                                        {t("Saving ...")}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </form>
                            </div>
                        )}

                        {modalData?.event_type == 'order_processing' || modalData?.event_type == 'reclamation' || modalData?.event_type == 'ACFertigmeldung' ? (
                            <div className="modal-body tb-ac-cs">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h6
                                        className="modal-title"
                                        id="calanderModalLabel"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {getValues("code")} -
                                        {modalData?.name}{", "}
                                        {'Plz: ' + modalData?.plz}{", "}

                                        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
                                            <>
                                                {'Tel: ' + modalData?.telefonnummer}{", "}
                                            </>
                                        ) : ''}

                                        {modalData.salse_person ? modalData.salse_person : ""}
                                        {modalData?.reclamation_id ? ', R-' + modalData?.reclamation_id : modalData?.project_id ? ' - ' + modalData?.project_id : ""}
                                    </h6>
                                    <div style={{ marginBottom: "35px" }}>
                                        <p className="m-0" style={{ fontSize: "12px" }}>
                                            {" "}
                                            {t("Start Date & Time")}: {getValues("time")} -{" "}
                                            {dateFormat(getValues("date"), "dd.mm.yyyy")}{" "}
                                        </p>
                                    </div>
                                    <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                style={{ fontSize: "0.75rem" }}
                                                className="nav-link active"
                                                id="home2-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#home2-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="home1-tab-pane"
                                                aria-selected="true"
                                            >
                                                Ereignisdetails
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                style={{ fontSize: "0.75rem" }}
                                                className="nav-link"
                                                id="desc-tab2"
                                                data-bs-toggle="tab"
                                                data-bs-target="#desc-tab-pane2"
                                                type="button"
                                                role="tab"
                                                aria-controls="desc-tab-pane"
                                                aria-selected="false"
                                            >
                                                Beschreibung
                                            </button>
                                        </li>

                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        style={{ fontSize: "0.75rem" }}
                                                        className="nav-link"
                                                        id="project2-status"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#project2-status-pane"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="project1-status-pane"
                                                        aria-selected="false"
                                                    >
                                                        {t("Project Status")}
                                                    </button>
                                                </li>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active pt-3"
                                            style={{ minHeight: "230.5px" }}
                                            id="home2-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="home2-tab"
                                            tabindex="0"
                                        >
                                            <table className="mt-3 mb-5">
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td width="100">
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Project Nr")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <NavLink
                                                            to={
                                                                "/projects/" +
                                                                modalData.project_id
                                                            }
                                                            data-bs-dismiss="modal"
                                                            style={{ textDecoration: "1px solid" }}
                                                        >
                                                            <SoftTypography
                                                                style={{
                                                                    textDecoration: "underline",
                                                                    color: "#005498",
                                                                }}
                                                                variant="caption"
                                                                color="text"
                                                            >
                                                                {modalData.project_id}
                                                            </SoftTypography>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Name")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {modalData.salse_person}{" "}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Time")}:{" "}
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {modalData.start ? modalData.start : ""}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Date")}:{" "}
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {dateFormat(getValues("date"), "dd.mm.yyyy")}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>
                                                <br />
                                                <tr>
                                                    <td colSpan="2">
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                            style={{ fontSize: "0.75rem", color: "#2D3748" }}
                                                        >
                                                            {t("Client Information")}:
                                                        </SoftTypography>{" "}
                                                        <br />
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Name")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <SoftTypography variant="caption" color="text">
                                                            {getValues("vorname") ? getValues("vorname") : ""}{" "}
                                                            {getValues("name") ? getValues("name") : ""}
                                                        </SoftTypography>
                                                    </td>
                                                </tr>

                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Address")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <a target="_blank" href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}>
                                                            <SoftTypography variant="caption" color="text" style={{
                                                                textDecoration: "underline",
                                                                color: "#005498",
                                                            }}>
                                                                {getValues("street") ? getValues("street") : ""}
                                                                {getValues("nr") ? ' ' + getValues("nr") : ""}
                                                            </SoftTypography>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("PLZ und Ort")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <a target="_blank" href={getValues("street") ? 'https://www.google.com/maps/place/' + getValues("street") + ' ' + getValues("nr") + ', ' + getValues("plz") + ' ' + getValues("ort") + ', Germany' : ""}>
                                                            <SoftTypography variant="caption" color="text">
                                                                {getValues("plz") ? getValues("plz") : ""},{" "}
                                                                {getValues("ort") ? getValues("ort") : ""}
                                                            </SoftTypography>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr style={{ lineHeight: "0px" }}>
                                                    <td>
                                                        <SoftTypography
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            color="text"
                                                        >
                                                            {t("Phone")}:
                                                        </SoftTypography>
                                                    </td>
                                                    <td>
                                                        <a href={getValues("telefonnummer") ? "tel:" + getValues("telefonnummer") : "#"}>
                                                            <SoftTypography variant="caption" color="text" style={{
                                                                textDecoration: "underline",
                                                                color: "#005498",
                                                            }}>
                                                                {getValues("telefonnummer")
                                                                    ? getValues("telefonnummer")
                                                                    : ""}
                                                            </SoftTypography>
                                                        </a>

                                                        {getValues("mobilnummer") ? (
                                                            <a href={getValues("mobilnummer") ? "tel:" + getValues("mobilnummer") : "#"}>
                                                                <SoftTypography variant="caption" color="text" style={{
                                                                    textDecoration: "underline",
                                                                    color: "#005498",
                                                                }}>
                                                                    , {getValues("mobilnummer")
                                                                        ? getValues("mobilnummer")
                                                                        : ""}
                                                                </SoftTypography>
                                                            </a>
                                                        ) : ''}
                                                    </td>
                                                </tr>

                                                {modalData?.event_type == 'reclamation' ? (
                                                    <>
                                                        {modalData?.products && modalData?.products.map(function (product, i) {
                                                            return (
                                                                <tr style={{ lineHeight: "0px" }}>
                                                                    <td>
                                                                        <SoftTypography
                                                                            variant="caption"
                                                                            fontWeight="bold"
                                                                            color="text"
                                                                        >
                                                                            {t("Product")}:
                                                                        </SoftTypography>
                                                                    </td>
                                                                    <td>
                                                                        <SoftTypography variant="caption" color="text" style={{

                                                                        }}>
                                                                            {product?.quantity} Stück. {product?.title}
                                                                        </SoftTypography>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr style={{ lineHeight: "0px" }}>
                                                            <td>
                                                                <SoftTypography
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    color="text"
                                                                >
                                                                    {t("Modul")}:
                                                                </SoftTypography>
                                                            </td>
                                                            <td>
                                                                <SoftTypography variant="caption" color="text" style={{

                                                                }}>
                                                                    {modalData?.modul}
                                                                </SoftTypography>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ lineHeight: "0px" }}>
                                                            <td>
                                                                <SoftTypography
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    color="text"
                                                                >
                                                                    {t("Speicher")}:
                                                                </SoftTypography>
                                                            </td>
                                                            <td>
                                                                <SoftTypography variant="caption" color="text" style={{
                                                                }}>
                                                                    {modalData?.speicher}
                                                                </SoftTypography>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ lineHeight: "0px" }}>
                                                            <td>
                                                                <SoftTypography
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    color="text"
                                                                >
                                                                    {t("Dachtyp")}:
                                                                </SoftTypography>
                                                            </td>
                                                            <td>
                                                                <SoftTypography variant="caption" color="text" style={{
                                                                }}>
                                                                    {modalData?.dachtyp}
                                                                </SoftTypography>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </table>
                                        </div>
                                        <div
                                            className="tab-pane fade pt-4"
                                            style={{ minHeight: "230.5px" }}
                                            id="desc-tab-pane2"
                                            role="tabpanel"
                                            aria-labelledby="desc-tab"
                                            tabindex="0"
                                        >
                                            <SoftTypography
                                                variant="caption"
                                                fontWeight="bold"
                                                color="text"
                                                style={{ fontSize: "0.75rem", color: "#2D3748" }}
                                            >
                                                {t(modalData?.reclamation_id ? "Reklamatione Description" : "Project Description")}:
                                            </SoftTypography>{" "}
                                            <br />
                                            {/* <SoftTypography variant="caption" color="text">{modalData.desc}</SoftTypography> */}
                                            <SoftTypography
                                                variant="caption"
                                                color="text"
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "17px",
                                                    display: "block",
                                                    color: "#2D3748",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                {modalData?.reclamation_id ? modalData?.reclamation_description : modalData.desc}
                                            </SoftTypography>
                                        </div>

                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                <div
                                                    className="tab-pane fade pt-4"
                                                    style={{ minHeight: "230.5px" }}
                                                    id="project2-status-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="project1-status"
                                                    tabindex="0"
                                                >
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{t("Project Status")}</label>
                                                            {modalData?.id ? <ProjectStatus projectid={modalData.project_id} status={modalData?.status} /> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            flexShrink: "0",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}

                                        className="d-none"
                                    >
                                        <button
                                            type="button"
                                            style={{
                                                textTransform: "capitalize",
                                                padding: "0.50rem 1.5rem",
                                                marginRight: "10px",
                                            }}
                                            className="btn btn-outline-dark btn-start mt-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            {t("Cancel")}
                                        </button>
                                        {/* <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn btn-dark btn-start mt-2">{t('Save')}</button>  */}
                                        {Cookies.get("permissions").indexOf("QR") !==
                                            -1 &&
                                            Cookies.get("permissions").indexOf("QU") !==
                                            -1 ? (
                                            <>
                                                {!saving && (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            textTransform: "capitalize",
                                                            padding: "0.50rem 2rem",
                                                        }}
                                                        className="btn bg-gradient-primary btn-start mt-2"
                                                    >
                                                        {t("Save")}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            textTransform: "capitalize",
                                                            padding: "0.50rem 2rem",
                                                        }}
                                                        className="btn btn-disabled mt-2"
                                                        disabled
                                                    >
                                                        {t("Saving ...")}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </form>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>


            <NewAufgabe />

            <AbsenceRequest setReloadCalendarData={setReloadCalendarData} />
            <AbsenceRequestEdit
                id={absenceId}
                refresh={refreshAbsemce}
                setReloadCalendarData={setReloadCalendarData}
            />

            <EditPlan data={planning} refresh={refresh2} setRefresh={setRefresh} />

            {renderSuccessSB}
        </>
    );
}

export default Index;
