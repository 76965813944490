import { React, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import OperationalPlan from "./OperationalPlan";
import OrdersToPlan from "./OrdersToPlan";
import Calendar from 'pages/calendar/Index'; 

function Index2() {
    const [refresh, setRefresh] = useState(0);
    const [eventTechnicianLocation, setEventTechnicianLocation] = useState({});

    const fetchRefresh = (refresh) => {
        console.log('fetchRefresh')
        console.log(refresh)
        setRefresh(refresh);
    }

    const EventTechnicianLocationSet = (data) => {
        setEventTechnicianLocation(data);
    }

    return (
        <>
            <Grid container spacing={2} py={0}>
                <Grid item xs={12}>
                    <Calendar team_type={'ac,dc'} vkEventHide={true} editEvent={true} absence={true}/>
                </Grid>
            </Grid>

            <OrdersToPlan setRefresh={fetchRefresh} eventTechnicianLocation={eventTechnicianLocation} />
        </>
    );
}

export default Index2;
