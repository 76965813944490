import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

import TermineQouteTable from 'pages/dashboard/layouts/TermineQouteTable';
import ProjectBezahltPieChart from 'pages/dashboard/layouts/ProjectBezahltPieChart';
import ProjectTable from 'pages/dashboard/layouts/ProjectTable';
import Calendar from 'pages/dashboard/Calendar';

import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftAvatar from 'components/SoftAvatar'
import curved0 from "assets/images/curved-images/curved0.jpg";

import { Card, Grid } from '@mui/material'

function Show() {
    let params = useParams();
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'All',
        callcenter: params?.id,
    });
    const [data, setData] = useState({});
    const [selectedMembers, setSelectedMembers] = useState([]);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!params?.id) return;
        callFetch("callcenter/" + params.id, "GET", []).then((res) => {
            setData(res?.data);
        });
    }, [params?.id]);


    return (
        <>
            <SoftBox position="relative">
                <SoftBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="12.75rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0.6),
                                rgba(gradients.info.state, 0.6)
                            )}, url(${curved0})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                />
                <Card
                    sx={{
                        backdropFilter: `saturate(200%) blur(30px)`,
                        backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                        boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                        position: "relative",
                        mt: -8,
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <SoftAvatar
                                src={'/assets/img/placeholder.png'}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography variant="h5" fontWeight="medium">
                                    {data?.name ?? ''}
                                </SoftTypography>
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    {data?.identity_number ?? ''}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Card>
            </SoftBox>

            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <div className="row">
                        <div className="col-md-12">
                            <Grid container className="mt-3">
                                <Grid item xs={12} lg={12} xl={12}>
                                    <SoftBox>
                                        <SoftBox>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} lg={7}>
                                                    <TermineQouteTable filterData={filterData}/>
                                                </Grid>

                                                <Grid item xs={12} sm={12} lg={5}>
                                                    <SoftBox>
                                                        <ProjectBezahltPieChart filterData={filterData} />
                                                    </SoftBox>
                                                </Grid>

                                                <Grid item xs={12} sm={12} lg={12}>
                                                    <ProjectTable filterData={filterData} />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Calendar
                                                        teamsEvent={false}
                                                        readyToLoad={true}
                                                        projects={true}
                                                        assign_orders={true}
                                                        reclamations={true}
                                                        absence={true}
                                                        callcenter={filterData?.callcenter}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </SoftBox>
            </SoftBox>
        </>
    );
}

export default Show;
