import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import ProjectIndexTable from './IndexTable';

function ProjectIndex() {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Resell Projekte')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div style={{ padding: '0px 15px' }}>
                                <ul className="nav nav-tabs nav-underline" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Alle Projekte</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="Wiedervorlage-tab" data-bs-toggle="tab" data-bs-target="#Wiedervorlage-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>Wiedervorlage</button>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active p-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                    <ProjectIndexTable ready={true} menu="all" />
                                </div>
                                <div className="tab-pane fade p-3" id="Wiedervorlage-tab-pane" role="tabpanel" aria-labelledby="Wiedervorlage-tab" tabindex="0">
                                    <ProjectIndexTable ready={true} menu="Wiedervorlage" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectIndex;
