import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import FilesIndexTable from './FilesIndexTable';
import CustomFiles from './CustomFiles';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography"
import SoftDropzone from "components/SoftDropzone";
import Timeline from "./Timeline";
import ProjectStatus from '../ProjectStatus';
import EmailForm from "./EmailForm";
import ACZahlertermin from './ACZahlertermin';

function Fertigmeldung(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [selectedFilesRefresh, setSelectedFilesRefresh] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [acZahlertermin, setAcZahlertermin] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setValue('id', params.id);
    }, [params.id]);


    const notify = () => toast("Success");
    const user = JSON.parse(Cookies.get('user'));
    const submitBtn = useRef();
    const [attachments, setAttachment] = useState([]);
    const [newDropzone, setNewDropzone] = useState(true);
    const [editForm, setEditForm] = useState(false);



    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    }


    const onSubmit = (formData) => {
        setSaving(true);
        formData.id = params.id;
        formData.for = 'project_fertigmeldung';
        callFetch("custom-file-upload", "POST", formData, setError).then((res) => {
            setRefresh(refresh + 1);
            setAttachment([]);
            setValue('attachments', '');
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
            clearForm();
            //notify();
        });
    };

    useEffect(() => { 
        callFetch("ac-zahlertermin/" + params.id, "GET", []).then((res) => { 
            setAcZahlertermin(res.data);
        });
    }, [params.id, refresh2]); 

    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setNewDropzone(true);
        setEditForm(false);
        setAttachment([]);
    }

    const dropZoneInitialize = (name = 'dachansicht') => {

        return (
            <SoftDropzone
                key={'dfdf'}
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-9 inner-main">
                    <div className="row mb-2">
                        <div className="col-lg-12 mt-lg-0">
                            <div className="emb-4">
                                {editForm == false ? (
                                    <>
                                        <form id="createOrderProcesing" className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                            <input type="hidden" defaultValue="POST" {...register("_method")} />
                                            <input
                                                type="hidden"
                                                {...register("id", {
                                                    required: true,
                                                })}
                                            />
                                            <SoftBox>
                                                <Card sx={{ overflow: "visible" }}>
                                                    <SoftBox p={2} lineHeight={1}>
                                                        <SoftBox>
                                                            <SoftBox
                                                                display="flex"
                                                                flexDirection="column"
                                                                justifyContent="flex-end"
                                                                height="100%"
                                                            >
                                                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                        {t('Files')}
                                                                    </SoftTypography>
                                                                </SoftBox>

                                                                {dropZoneInitialize()}

                                                            </SoftBox>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox p={2} display="flex" justifyContent="flex-end">

                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#ACZahlertermin" className={acZahlertermin?.id ? "btn btn-success mx-2" : 'btn btn-outline-success mx-2'} style={{/* background: 'rgb(72, 187, 120)'*/}}>
                                                            {t(acZahlertermin?.id ? "Zählertermin Ok" : 'AC Zählertermin')}
                                                        </a>

                                                        <a href="#" className="btn btn-danger mx-2" onClick={() => { clearForm() }}>
                                                            {t('Cancel')}
                                                        </a>

                                                        {!saving && (
                                                            <button type="submit" className="btn btn-primary">
                                                                {t('Save')}
                                                            </button>
                                                        )}
                                                        {saving && (
                                                            <button type="submit" className="btn btn-disabled" disabled>
                                                                {t('Saving ...')}
                                                            </button>
                                                        )}
                                                    </SoftBox>
                                                </Card>
                                            </SoftBox>
                                        </form>
                                    </>
                                ) : <></>}
                            </div>
                            <br />
                        </div>
                    </div>
                    <ToastContainer />

                    <div className="card mb-4">
                        <div className="card-body px-0 pt-0 pb-2">
                            <FilesIndexTable for={'project_fertigmeldung'} refresh={refresh} onlyCustomFiles={1} status={true} checkForEmail={true} setSelectedFilesRefresh={setSelectedFilesRefresh} setSelectedFiles={setSelectedFiles} />
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-body px-0 pt-0 pb-2">
                            <CustomFiles for={'project_fertigmeldung'} refresh={refresh} project_id={params.id} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 inner-main">
                    <div className="card mb-3">
                        <div className="card-body p-3">
                            <div className="d-flex mb-3">
                                <div>
                                    <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                    </div>
                                </div>
                                <div className="ms-3">
                                    <div className="numbers">
                                        <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr')}:</p>
                                        <h5 className="font-weight-bolder mb-0">
                                            {params?.id ? params?.id : '-'}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header pb-0">
                            <h6>{t('Email')}</h6>
                        </div>
                        <div className="card-body">
                            <EmailForm id={params?.id} type={'fertigmeldung_attchments'} attachments={selectedFiles} selectedFilesRefresh={selectedFilesRefresh} />
                        </div>
                    </div>

                    <Timeline refresh={refresh} />
                </div>
            </div>

            <ACZahlertermin setRefresh2={setRefresh2} acZahlertermin={acZahlertermin} />
        </>
    );
}

export default Fertigmeldung;
