import { useMemo, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { GoogleMap, useLoadScript, Rectangle, OverlayView, StandaloneSearchBox, Autocomplete } from '@react-google-maps/api';
import Slider from '@mui/material/Slider';
import { setRef } from "@mui/material";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import deleteAlert from "helpers/deleteAlert";
import { valid } from "chroma-js";


const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
})

function millimetersToPixels(millimeters, latitude, zoom) {
    var meters = millimeters * 0.001;
    const earthCircumference = 40075016.686; // Earth's circumference in meters at the equator
    const metersPerPixel = earthCircumference * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom + 8);
    return meters / metersPerPixel;
}

function calculateDegree(lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const angleRad = Math.atan2(y, x);
    let angleDeg = angleRad * (180 / Math.PI);

    if (angleDeg < 0) {
        angleDeg += 360; // Ensure positive degrees (0 to 360)
    }

    return angleDeg;
}

function RoofPlannerV3(props) {
    let params = useParams();
    const { t } = useTranslation();

    const mapRef = useRef(null);
    const [saving, setSaving] = useState(false);
    const [addPanel, setAddPanel] = useState(false);
    const [addPanelPosition, setAddPanelPosition] = useState({});
    const [drawing, setDrawing] = useState(false);
    const [editDrawing, setEditDrawing] = useState(false);
    const [rotateing, setRotateing] = useState(false);
    const [editPanelGroupIndex, setEditPanelGroupIndex] = useState('');
    const [moving, setMoving] = useState(false);

    const [refresh, setRefresh] = useState(0);
    const [module, setModule] = useState(''); //mm
    const [width, setWidth] = useState(0); //mm
    const [height, setHeight] = useState(0); //mm
    const [watt, setWatt] = useState(0); //mm
    //const [widthMetersToLatitude, setWidthMetersToLatitude] = useState(((width * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters
    //const [heightMetersToLatitude, setHeightMetersToLatitude] = useState(((height * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters
    const [defaultAngle, setDefaultAngle] = useState(0); // Portrait, Landscape
    const [defaultOrientation, setDefaultOrientation] = useState('Portrait'); // Portrait, Landscape

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["places"]
    });

    const [currentTab, setCurrentTab] = useState(0);
    const [roofPanelId, setRoofPanelId] = useState(0);
    const [panelGroups, setPanelGroups] = useState([]);
    const [totalPanel, setTotalPanel] = useState(0);
    const [totalKWp, setTotalKWp] = useState(0);
    const [totalWatt, setTotalWatt] = useState(0);
    const [mapZoom, setMapZoom] = useState(21);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [position, setPosition] = useState({ lat: 0, lng: 0 });
    const [searchBox, setSearchBox] = useState(null);
    const [data, setData] = useState({});
    const [searchAddress, setSearchAddress] = useState('');
    const [autocomplete, setAutocomplete] = useState(null);
    const [tilt, setTilt] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [refreshNewSystem, setRefreshNewSystem] = useState(0);
    const [validation, setValidation] = useState({});


    const handleTiltChange = () => {
        setTilt((prevTilt) => (prevTilt + 45) % 90);
        setRefresh(refresh + 1)
    };

    const handleRotationChange = (direction) => {
        const rotationIncrement = 90; // Increment in degrees
        let newRotation;

        if (direction === 'clockwise') {
            newRotation = (rotation + rotationIncrement) % 360;
        } else {
            newRotation = (rotation - rotationIncrement + 360) % 360;
        }

        setRotation(newRotation);
        setRefresh(refresh + 1)
    };

    const addPanels = () => {

        if (!module || validation?.module === 'invalid') {
            return;
        }

        setDrawing(true);
        setAddPanel(true);
        setEditDrawing(false);
        setEditPanelGroupIndex('');
        setMoving(false);
        setRotateing(false);
        setRefresh(refresh + 1)
    }

    const orientation = (orientation) => {

        if (editPanelGroupIndex === '') {
            setDefaultOrientation(orientation);
        } else {
            var currentpanelGroups = panelGroups;
            currentpanelGroups[editPanelGroupIndex].orientation = orientation;
            setPanelGroups(currentpanelGroups);
        }

        setRefresh(refresh + 1)
    }

    function handleLoad(map) {
        mapRef.current = map;
    }

    function handleCenterChanged() {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setPosition(newPos);
        setMapCenter(newPos);
        console.log('handleCenterChanged')
    }

    function handleZoomChanged() {
        setMapZoom(this.getZoom());
    }

    const handleMapClick = (event) => {
        console.log('handleMapClick');

        if (drawing && moving === false && rotateing === false) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON

            var currentpanelGroups = panelGroups;
            currentpanelGroups.push({
                position: latLng,
                angle: defaultAngle,
                panel: { row: 1, column: 1 },
                activePanel: [{ x: 0, y: 0 }],
                orientation: defaultOrientation,
                width: width,
                height: height,
                watt: watt,
                module: module,
            });

            setEditPanelGroupIndex(currentpanelGroups.length - 1);
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1)

        } else if (editDrawing) {

            //setEditPanelGroupIndex(editPanelGroupIndex);
        } else {
            setEditPanelGroupIndex('');
        }
        setEditDrawing(false)
        setDrawing(false);
        setMoving(false);
        setRotateing(false);
        setAddPanel(false);
        setRefresh(refresh + 1)
    };


    const handleMouseMove = (event) => {
        console.log('handleMouseMove')

        if (addPanel && drawing) {
            const latLng = event.latLng && event.latLng.toJSON();
            setAddPanelPosition(latLng);
            setRefresh(refresh + 1);
        } else if (moving && drawing) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON
            var currentpanelGroups = panelGroups;
            currentpanelGroups[editPanelGroupIndex].position = latLng
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1);
        } else if (rotateing && drawing) {
            var currentpanelGroups = panelGroups;
            const latLng = event.latLng && event.latLng.toJSON(); // Get the current mouse point LatLng as JSON

            const angle = calculateDegree(
                ((currentpanelGroups[editPanelGroupIndex].position.lat) * Math.PI) / 180,
                ((currentpanelGroups[editPanelGroupIndex].position.lng) * Math.PI) / 180,
                (latLng.lat * Math.PI) / 180,
                (latLng.lng * Math.PI) / 180
            );

            currentpanelGroups[editPanelGroupIndex].angle = angle;
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1);
        } else {
            //console.log('handleMouseMove');
            //console.log('moving='+moving);
            // console.log('drawing='+drawing);
        }
    };



    const onClickPanel = (currentPanel, panelGroupKey) => {

        var update = panelGroups;
        var activePanel = update[panelGroupKey].activePanel;

        let foundPosition = -1;
        for (let i = 0; i < activePanel.length; i++) {
            if (activePanel[i].x === currentPanel.x && activePanel[i].y === currentPanel.y) {
                foundPosition = activePanel[i];
                break;
            }
        }

        if (foundPosition !== -1) {
            activePanel.splice(activePanel.indexOf(foundPosition), 1);
        } else {
            activePanel.push(currentPanel);
        }

        update[panelGroupKey].activePanel = activePanel;
        setEditDrawing(true)
        setPanelGroups(update);
        setRefresh(refresh + 1);

        return;
    }

    const activePanelSearch = (currentPanel, panelGroupKey) => {
        var update = panelGroups;
        var activePanel = update[panelGroupKey].activePanel;

        let foundPosition = -1;
        for (let i = 0; i < activePanel.length; i++) {
            if (activePanel[i].x === currentPanel.x && activePanel[i].y === currentPanel.y) {
                foundPosition = i;
                break;
            }
        }

        return foundPosition;
    }

    const onSubmit = (newRoof = false) => {

        setSaving(true);

        var fromData = {};
        fromData._method = 'PUT';
        fromData.project_id = params.id;

        if (newRoof) {
            fromData.panelGroups = JSON.stringify([]);
            fromData.position = JSON.stringify({ lat: parseFloat(data?.data?.lat), lng: parseFloat(data?.data?.lng) });
            fromData.mapCenter = JSON.stringify({ lat: parseFloat(data?.data?.lat), lng: parseFloat(data?.data?.lng) });
            fromData.zoom = 21;
            fromData.tilt = 0;
            fromData.rotation = 0;
            fromData.totalKWp = 0;
            fromData.totalPanel = 0;
            fromData.totalWatt = 0;
            fromData.module = data?.data?.quations?.module?.id;
        } else {
            fromData.panelGroups = JSON.stringify(panelGroups);
            fromData.position = JSON.stringify(position);
            fromData.mapCenter = JSON.stringify(mapCenter);
            fromData.zoom = mapZoom;
            fromData.tilt = tilt;
            fromData.rotation = rotation;
            fromData.totalKWp = totalKWp;
            fromData.totalPanel = totalPanel;
            fromData.totalWatt = totalWatt;
            fromData.module = module;
        }

        callFetch("v4/roofplanner/" + (newRoof ? 0 : roofPanelId), "POST", fromData).then((res) => {

            //console.log(res.data.data);
            setData(res.data.data);

            if (newRoof) {
                setRefreshNewSystem(refreshNewSystem + 1);
            }

            setSaving(false);
        });
    }

    useEffect(() => {
        if (!params?.id) return;

        callFetch("v4/roofplanner/" + params.id, "GET", []).then((res) => {
            setData(res);
        });

    }, [params?.id]);

    //after Added new system
    useEffect(() => {
        if (!data?.data?.id || refreshNewSystem <= 0) return;

        if (data?.data?.roofpanels?.length) {
            setRoofPanelData((data?.data?.roofpanels?.length - 1));
        }
    }, [data?.data?.id, refreshNewSystem]);



    useEffect(() => {
        if (!data?.data?.id || refreshNewSystem != 0) return;

        if (data?.data?.roofpanels?.length) {
            setRoofPanelData(0);
        } else {
            onSubmit(true);
        }

    }, [data?.data?.id, refreshNewSystem]);


    const setRoofPanelData = (index) => {

        /*
        console.log('setRoofPanelData index');
        console.log(index);
        console.log(data);
        
        if (data?.data?.quations) {
            if (data?.data?.quations?.module_eingeben == 'yes') {
                var module_description = JSON.parse(data?.data?.quations?.module_description);
            } else {
                var module_description = JSON.parse(data?.data?.quations?.module?.description);
                setModule(data?.data?.quations?.module?.id);
                setWatt(data?.data?.quations?.module?.watt);
            }

            setWidth(module_description?.Modulabmessung_width);
            setHeight(module_description?.Modulabmessung_height);
        }
        */

        //mapCenter
        if (data?.data?.roofpanels?.[index]?.mapCenter) {
            setMapCenter(JSON.parse(data?.data?.roofpanels?.[index]?.mapCenter));
        } else if (data?.data?.lat && data?.data?.lng) {
            setMapCenter({ lat: parseFloat(data?.data?.lat), lng: parseFloat(data?.data?.lng) });
        }

        //position
        if (data?.data?.roofpanels?.[index]?.position) {
            setPosition(JSON.parse(data?.data?.roofpanels?.[index]?.position));
        } else if (data?.data?.lat && data?.data?.lng) {
            setPosition({ lat: parseFloat(data?.data?.lat), lng: parseFloat(data?.data?.lng) });
        }

        if (data?.data?.roofpanels?.[index]?.panelGroups) {
            setPanelGroups(JSON.parse(data?.data?.roofpanels?.[index]?.panelGroups));
        }

        if (data?.data?.roofpanels?.[index]?.id) {
            setRoofPanelId(data?.data?.roofpanels?.[index]?.id);
        }

        if (data?.data?.roofpanels?.[index]?.zoom) {
            setMapZoom(data?.data?.roofpanels?.[index]?.zoom);
        }

        if (data?.data?.roofpanels?.[index]?.tilt) {
            setTilt(data?.data?.roofpanels?.[index]?.tilt);
        }

        if (data?.data?.roofpanels?.[index]?.rotation) {
            setRotation(data?.data?.roofpanels?.[index]?.rotation);
        }

        if (data?.data?.roofpanels?.[index]?.totalKWp) {
            setTotalKWp(data?.data?.roofpanels?.[index]?.totalKWp);
        }

        if (data?.data?.roofpanels?.[index]?.totalPanel) {
            setTotalPanel(data?.data?.roofpanels?.[index]?.totalPanel);
        }

        if (data?.data?.roofpanels?.[index]?.totalWatt) {
            setTotalWatt(data?.data?.roofpanels?.[index]?.totalWatt);
        }

        setSearchAddress(data?.data?.street + ' ' + data?.data?.nr + ' ' + data?.data?.plz + ' ' + data?.data?.ort)
        setRefresh(refresh + 1);
    }

    useEffect(() => {

        console.log('panelGroups')
        //console.log(panelGroups)

        var totalPanel = 0;
        var total_watt = 0;
        var total_kwp = 0;
        var updatePanelGroups = panelGroups;
        for (let [groupKey, panelGroup] of Object.entries(panelGroups)) {
            var subTotalPanel = 0;
            var row = panelGroup.panel.row;
            var column = panelGroup.panel.column;
            var activePanel = [];

            for (var i = 0; i < row; i++) {
                for (var j = 0; j < column; j++) {
                    var currentPoin = { x: i, y: j };

                    if (activePanelSearch(currentPoin, groupKey) !== -1) {
                        activePanel.push(currentPoin);
                        subTotalPanel += 1;
                    }
                }
            }

            totalPanel += subTotalPanel;
            updatePanelGroups[groupKey].activePanel = activePanel;
            updatePanelGroups[groupKey].moudle = subTotalPanel;

            total_watt += Number(panelGroup.watt);
            total_kwp += (Math.round(panelGroup.watt * subTotalPanel / 1000 * 100) / 100);
        }

        setPanelGroups(updatePanelGroups);
        setTotalPanel(totalPanel);
        setTotalKWp(total_kwp ? total_kwp.toFixed(2) : 0);
        setTotalWatt((total_watt / 2));
        //activePanelSearch({ x: x, y: y }, panelGroupKey) !== -1 

    }, [refresh]);


    /*
    useEffect(() => {

        if (!searchAddress) return;

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: searchAddress }, (results, status) => {
            console.log('result')
            console.log(results)
            if (status === 'OK' && results[0]) {

                console.log('search lat lng');
                console.log({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                })
            } else {
                console.error('Geocode was not successful for the following reason:', status);
            }
        });
    }, [searchAddress]);
    */

    const onLoadAutoComplte = (autocomplete) => {
        //console.log('autocomplete: ', autocomplete);
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            //console.log('onPlaceChanged')
            //console.log(autocomplete)

            var place = autocomplete.getPlace();

            //console.log(place.geometry.location.lat())
            //setValue('lat', place.geometry.location.lat());
            //setValue('lng', place.geometry.location.lng());

            setMapCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
            setPosition({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
            setRefresh(refresh + 1);
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    const memoizedMap = useMemo(() => {



        if (!mapCenter?.lat && !mapCenter?.lng) return;
        //console.log('searchAddress')
        //console.log(searchAddress)


        return (
            <GoogleMap
                id='project-location'
                mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '7px', position: 'relative' }}
                mapContainerClassName={'googleMapContainer'}
                zoom={mapZoom}
                center={mapCenter}

                options={{
                    disableDefaultUI: true,
                    zoomControl: (drawing ? false : true),
                    draggable: (drawing ? false : true),
                    mapTypeId: 'satellite',
                    //tilt: 0, // Set tilt to 0 for a top-down view
                    //heading: 0, // Set heading to 0 for a top-down view
                    tilt: tilt,
                    heading: rotation
                }}
                onLoad={handleLoad}
                onClick={handleMapClick}
                onMouseMove={handleMouseMove}
                onTouchMove={(event) => {
                    console.log('onTouchMove')
                    //handleMouseMove(event);
                }}
                //onMouseUp={handleMouseUp}
                onCenterChanged={handleCenterChanged}
                onZoomChanged={handleZoomChanged}
                onMouse

            >
                {panelGroups && panelGroups.map((panelGroup, panelGroupKey) => (
                    <OverlayView
                        key={Math.random()}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={panelGroup?.position}
                        getPixelPositionOffset={getPixelPositionOffset}
                    >
                        <div
                            className="solarPanel"
                            style={{
                                cursor: 'cursor',
                            }}
                        >
                            <div
                                style={{
                                    width: 'max-content',
                                    height: 'max-content',
                                    //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                    transform: `translate(-50%, -50%) rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                    transformOrigin: 'center center',
                                    transition: 'transform 1s ease-in-out',
                                }}
                            >
                                <table
                                    className="solarPanelModule"
                                    style={{
                                        borderCollapse: 'separate',
                                        //borderCollapse: 'collapse', // Merge cell borders
                                        borderSpacing: (panelGroup?.orientation == 'Portrait' ? '0px 1px' : '1px 0px'),
                                    }}
                                >
                                    {[...Array(panelGroup.panel.row)].map((r, x) =>
                                        <tr style={{
                                            //marginBottom: `${(millimetersToPixels(80, position?.lat, mapZoom) - 1)}px`, // Adjust this value to set the space between rows
                                            // display: 'block', // Ensures each row behaves like a block element
                                        }}>
                                            {[...Array(panelGroup.panel.column)].map((c, y) =>
                                                <>
                                                    <td
                                                        style={{
                                                            width: `${(millimetersToPixels((panelGroup?.orientation == 'Portrait' ? panelGroup?.width : panelGroup?.height), position?.lat, mapZoom) - 1)}px`,
                                                            height: `${(millimetersToPixels((panelGroup?.orientation == 'Portrait' ? panelGroup?.height : panelGroup?.width), position?.lat, mapZoom)) - 1}px`,
                                                            border: (editPanelGroupIndex === panelGroupKey ? '3px solid #CFEA00' : (activePanelSearch({ x: x, y: y }, panelGroupKey) !== -1 ? '1px solid rgb(100, 100, 100)' : 'none')),
                                                            //padding: (panelGroup?.orientation == 'Portrait' ? '2px 0px 0px 0px' : '0px 0px 0px 2px'),
                                                            //borderSpacing: '10px', // Remove spacing
                                                            //paddingBottom: '1px',
                                                        }}
                                                        onClick={(e) => {
                                                            var currentPanel = { x: x, y: y };
                                                            if (editPanelGroupIndex === panelGroupKey) {
                                                                onClickPanel(currentPanel, panelGroupKey);
                                                            } else {
                                                                setEditPanelGroupIndex(panelGroupKey);
                                                                setEditDrawing(true)
                                                                setRefresh(refresh + 1)
                                                            }
                                                        }}
                                                        className={activePanelSearch({ x: x, y: y }, panelGroupKey) !== -1 ? 'active' : ''}
                                                    >
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    )}
                                </table >
                            </div >
                        </div>
                    </OverlayView>
                ))}


                {addPanel && addPanelPosition?.lat && addPanelPosition?.lng ? (
                    <OverlayView
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={addPanelPosition}
                        getPixelPositionOffset={getPixelPositionOffset}
                    >
                        <div
                            className="solarPanel"
                            style={{
                                cursor: 'copy',
                            }}
                        >
                            <div
                                style={{
                                    width: 'max-content',
                                    height: 'max-content',
                                    //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                    transform: `translate(-50%, -50%) rotate(${(defaultAngle)}deg)`,
                                    transformOrigin: 'center center',
                                    transition: 'transform 1s ease-in-out',
                                }}
                            >
                                <table className="solarPanelModule" >
                                    {[...Array(1)].map((r, x) =>
                                        <tr>
                                            {[...Array(1)].map((c, y) =>
                                                <>
                                                    <td
                                                        style={{
                                                            width: `${(millimetersToPixels((defaultOrientation == 'Portrait' ? width : height), position?.lat, mapZoom) - 1)}px`,
                                                            height: `${(millimetersToPixels((defaultOrientation == 'Portrait' ? height : width), position?.lat, mapZoom)) - 1}px`,
                                                            border: '3px solid #CFEA00',
                                                        }}
                                                        className={'active'}
                                                    ></td>
                                                </>
                                            )}
                                        </tr>
                                    )}
                                </table >
                            </div >
                        </div>
                    </OverlayView>
                ) : ''}

                {panelGroups && panelGroups.map((panelGroup, panelGroupKey) => (
                    <>
                        {editPanelGroupIndex === panelGroupKey ? (
                            <>
                                <OverlayView
                                    key={Math.random() + '_buttons'}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={panelGroup?.position}
                                    getPixelPositionOffset={getPixelPositionOffset}
                                >
                                    <div
                                        className="solarPanel"
                                        style={{
                                            //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                            //transition: 'transform 1s ease-in-out',
                                            // cursor: 'move',
                                            //transform: `translate(-50%, -50%) rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                        }}
                                    >
                                        <div
                                            className="buttons text-center d-flex"
                                            style={{
                                                width: '19px',
                                                marginTop: '0px',
                                            }}
                                        >
                                            <i
                                                style={{
                                                    cursor: 'move',
                                                    transform: `translate(-50%, -50%) rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                                }}
                                                className={(moving ? 'bg-primary' : 'bg-light') + " mx-1 p-1 rounded-circle fa fa-up-down-left-right"}
                                                onMouseDown={(event) => {
                                                    console.log('move button onMouseDown start dragging')
                                                    setEditPanelGroupIndex(panelGroupKey);
                                                    setDrawing(true);
                                                    setMoving(true);
                                                }}

                                                onMouseUp={() => {
                                                    console.log('onMouseUp stop draging')
                                                    setDrawing(false);
                                                    setMoving(false);
                                                }}

                                                onTouchStart={() => {
                                                    console.log('onTouchStart start dragging')
                                                    setEditPanelGroupIndex(panelGroupKey);

                                                    if (moving && drawing) {
                                                        setDrawing(false);
                                                        setMoving(false);
                                                    } else {
                                                        setDrawing(true);
                                                        setMoving(true);
                                                    }
                                                }}
                                            ></i>

                                            {/*
                                            <i
                                                className="bg-light mx-1 p-1 rounded-circle fa fa-rotate"
                                                onMouseDown={(event) => {
                                                    //console.log('move button onMouseDown')
                                                    setEditPanelGroupIndex(panelGroupKey);
                                                    setDrawing(true);
                                                    setRotateing(true);
                                                }}

                                                onClick={() => {
                                                    //console.log('move button onClick')
                                                    setDrawing(false);
                                                    setRotateing(false);
                                                }}
                                            ></i>
                                            */}
                                        </div>
                                    </div>
                                </OverlayView>
                            </>
                        ) : ''}
                    </>
                ))}

                {/*
                <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                    bounds={null}
                >
                    <input
                        type="text"
                        placeholder="Search for an address"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            left: '50%',
                            marginLeft: '-120px'
                        }}
                        ref={searchInputRef}
                        defaultValue={searchAddress}
                    />
                </StandaloneSearchBox>
                */}
            </GoogleMap >
        );
    }, [refresh, mapZoom, width, height, drawing, searchAddress]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 46 || event.key === 'Delete') {
                if (editPanelGroupIndex === '') return;
                const shouldDelete = window.confirm('Are you sure you want to delete?');
                if (shouldDelete) {
                    var update = panelGroups;
                    update.splice(editPanelGroupIndex, 1);
                    setEditPanelGroupIndex('');
                    setPanelGroups(update);
                    setRefresh(refresh + 1);
                }
            }
        };

        if (isLoaded) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (isLoaded) {
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [isLoaded, editPanelGroupIndex, refresh]);

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return (
        <>
            <div
                className='row'
            >
                <div className='col-md-12'>
                    <div className="card">
                        <div
                            className="card-body p-0"
                            style={{
                                height: 'calc(100vh - 20px)',
                            }}
                        >
                            {memoizedMap}

                            <div style={{
                                position: 'absolute',
                                top: '25px',
                                left: '25px',
                                zIndex: 950, // Ensure it's above the map
                                maxWidth: '420px'
                            }}>
                                <div
                                    className='row'
                                    style={{ height: '0px' }}>
                                    <div
                                        className='col-9'
                                        style={{ maxHeight: '500px', overflowY: 'auto' }}
                                    >
                                        {data?.data?.roofpanels && data?.data?.roofpanels.map((roofpanel, roofpanelsKey) => (
                                            <div
                                                className={'card ' + (roofPanelId == roofpanel?.id && currentTab !== '' ? 'm-0' : '')}
                                                style={{
                                                    borderRadius: (roofPanelId == roofpanel?.id && currentTab !== '' ? '0rem' : '0rem'),
                                                    width: '292px'
                                                }}>
                                                <div
                                                    className={'card-header p-2 ' + (roofPanelId == roofpanel?.id ? '' : 'bg-light')}
                                                    style={{ borderRadius: (roofPanelId == roofpanel?.id && currentTab !== '' ? '0rem' : '0rem'), cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (roofPanelId != roofpanel?.id) {
                                                            console.log('change tab')
                                                            setRoofPanelData(roofpanelsKey);
                                                            setCurrentTab(0);
                                                            setEditPanelGroupIndex('')
                                                        }
                                                    }}
                                                >
                                                    <h5 className="m-0" style={{ fontSize: '14px' }}>Modul Positionierung
                                                        <span className="float-end ">
                                                            <i
                                                                style={{ cursor: 'pointer' }}
                                                                className={(roofPanelId == roofpanel?.id && currentTab === 0 ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down")}
                                                                onClick={() => {
                                                                    if (currentTab === '') {
                                                                        setCurrentTab(0);
                                                                    } else {
                                                                        setCurrentTab('');
                                                                    }
                                                                }}
                                                            ></i>
                                                            <i
                                                                style={{ cursor: 'pointer', marginLeft: '5px', color: 'rgb(229, 62, 62)' }}
                                                                className={"fa-solid fa-trash"}
                                                                onClick={(e) => deleteAlert(e, 'v4/roofplanner', roofpanel?.id, t).then((res) => {
                                                                    if (res?.data?.data) {
                                                                        setData(res.data);

                                                                        if (res?.data?.data?.roofpanels.length === 0) {
                                                                            //create new one
                                                                            setRoofPanelId(0);
                                                                            setPanelGroups([]);
                                                                            setRefresh(refresh + 1)
                                                                            // onSubmit(true);
                                                                            return;
                                                                        } else if (roofpanelsKey == 0) {
                                                                            setRoofPanelId(0);
                                                                            setRoofPanelData(0);
                                                                        } else {
                                                                            setRoofPanelId(0);
                                                                            setRoofPanelData(roofpanelsKey - 1);
                                                                        }

                                                                        setCurrentTab('');
                                                                        setEditPanelGroupIndex('')
                                                                    }
                                                                })}
                                                            ></i>
                                                        </span>
                                                    </h5>

                                                    {roofPanelId == roofpanel?.id
                                                     //&& editPanelGroupIndex !== '' 
                                                     ? (
                                                        <>
                                                            {/*
                                                            <div className="panel_info">
                                                                <label className="m-0">{panelGroups[editPanelGroupIndex]?.moudle ? panelGroups[editPanelGroupIndex]?.moudle : 0} Module</label>
                                                                <label className="m-0">&nbsp; {(Math.round(panelGroups[editPanelGroupIndex]?.watt * panelGroups[editPanelGroupIndex]?.moudle / 1000 * 100) / 100 ? Math.round(panelGroups[editPanelGroupIndex]?.watt * panelGroups[editPanelGroupIndex]?.moudle / 1000 * 100) / 100 : 0)} kWp</label>
                                                                <label className="m-0">&nbsp; {panelGroups[editPanelGroupIndex]?.watt ? panelGroups[editPanelGroupIndex]?.watt : 0} Watt</label>
                                                            </div>
                                                            */}

                                                            {panelGroups ? panelGroups.map((panelGroup, panelGroup_key) => (
                                                                <div className="panel_info">
                                                                    <label className="m-0">{panelGroups[panelGroup_key]?.moudle ? panelGroups[panelGroup_key]?.moudle : 0} Module</label>
                                                                    <label className="m-0">&nbsp; {(Math.round(panelGroups[panelGroup_key]?.watt * panelGroups[panelGroup_key]?.moudle / 1000 * 100) / 100 ? Math.round(panelGroups[panelGroup_key]?.watt * panelGroups[panelGroup_key]?.moudle / 1000 * 100) / 100 : 0)} kWp</label>
                                                                    <label className="m-0">&nbsp; {panelGroups[panelGroup_key]?.watt ? panelGroups[panelGroup_key]?.watt : 0} Watt</label>
                                                                    <label className="m-0"
                                                                        onClick={(e) => {
                                                                            console.log('remove panel')
                                                                            var panelGroupsUpdate = panelGroups;
                                                                            panelGroupsUpdate.splice(panelGroup_key, 1);
                                                                            setPanelGroups(panelGroupsUpdate);
                                                                            setRefresh(refresh+1);
                                                                        }}
                                                                    >
                                                                        <i
                                                                            class="fa-solid fa-xmark"
                                                                            style={{ cursor: 'pointer', marginLeft: '5px', color: 'rgb(229, 62, 62)' }}
                                                                        ></i>
                                                                    </label>
                                                                </div>
                                                            )) : ''}
                                                        </>
                                                    ) : roofPanelId != roofpanel?.id ? (
                                                        <div className="panel_info">
                                                            <label className="m-0">{roofpanel?.totalPanel} Module</label>
                                                            <label className="m-0">&nbsp; {Number(roofpanel?.totalKWp).toFixed(2)} kWp</label>
                                                            <label className="m-0">&nbsp; {roofpanel?.totalWatt} Watt</label>
                                                        </div>
                                                    ) : (
                                                        <div className="panel_info">
                                                            <label className="m-0">{totalPanel} Module a</label>
                                                            <label className="m-0">&nbsp; {totalKWp} kWp</label>
                                                            <label className="m-0">&nbsp; {totalWatt} Watt</label>
                                                        </div>
                                                    )}
                                                </div>
                                                {roofPanelId == roofpanel?.id && currentTab !== '' ? (
                                                    <div className={'card-body p-2 border-top '}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <Autocomplete
                                                                    options={{
                                                                        componentRestrictions: { country: "de" },
                                                                        //fields: ["address_components", "geometry", "icon", "name"],
                                                                        //types: ["establishment"]
                                                                    }}
                                                                    onLoad={onLoadAutoComplte}
                                                                    onPlaceChanged={onPlaceChanged}
                                                                >
                                                                    <div className="form-group mb-1">
                                                                        <label>{t('Address')}</label>
                                                                        <input
                                                                            type="text"
                                                                            placeholder={t('')}
                                                                            className="form-control form-control-sm"
                                                                            defaultValue={searchAddress}
                                                                        />
                                                                    </div>
                                                                </Autocomplete>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group mb-1">
                                                                    <label className="form-control-label">{'Module'}</label>
                                                                    <select
                                                                        // className={"form-control form-control-sm "+(validation?.module === 'invalid' ? 'border-danger' : '')}
                                                                        className={"form-control form-control-sm "}
                                                                        onChange={(e) => {
                                                                            var validate = validation;
                                                                            if (!e.target.value) {
                                                                                if (editPanelGroupIndex === '') {
                                                                                    setHeight(0);
                                                                                    setWidth(0);
                                                                                    setWatt(0);
                                                                                    setModule('');
                                                                                }
                                                                                validate.module = 'invalid'
                                                                                return;
                                                                            } else {
                                                                                validate.module = ''
                                                                            }

                                                                            setValidation(validate);

                                                                            var selectedModule = {};
                                                                            for (let [moduleKey, moduleVal] of Object.entries(data?.modules)) {
                                                                                if (moduleVal.id == e.target.value) {
                                                                                    selectedModule = moduleVal;
                                                                                }
                                                                            }

                                                                            var description = JSON.parse(selectedModule?.description);

                                                                            if (editPanelGroupIndex !== '') {
                                                                                var update = panelGroups;
                                                                                update[editPanelGroupIndex].module = e.target.value;
                                                                                update[editPanelGroupIndex].watt = selectedModule.watt;
                                                                                update[editPanelGroupIndex].width = description.Modulabmessung_width;
                                                                                update[editPanelGroupIndex].height = description.Modulabmessung_height;

                                                                                setPanelGroups(update);
                                                                                setRefresh(refresh + 1);
                                                                            } else {
                                                                                setHeight(description.Modulabmessung_height);
                                                                                setWidth(description.Modulabmessung_width);
                                                                                setWatt(selectedModule.watt);
                                                                                setModule(e.target.value);
                                                                            }
                                                                        }}
                                                                        value={(editPanelGroupIndex === '' ? module : panelGroups[editPanelGroupIndex]?.module)}
                                                                    >
                                                                        <option value={''}>---</option>
                                                                        {data?.modules ? data?.modules.map((module, module_key) => (
                                                                            <option value={module?.id}>{module?.name}</option>
                                                                        )) : ''}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group mb-1">
                                                                    <label className="form-control-label">{'Modulausrichtung'}</label>
                                                                    <select
                                                                        className="form-control form-control-sm"
                                                                        onChange={(e) => {
                                                                            orientation(e.target.value);
                                                                        }}
                                                                        value={(editPanelGroupIndex === '' ? defaultOrientation : panelGroups[editPanelGroupIndex]?.orientation)}
                                                                    >
                                                                        <option value={"Portrait"}>Horizontal</option>
                                                                        <option value={"Landscape"}>Vertikal</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group px-1 mb-1">
                                                                    <label className="form-control-label">{'Module Rotieren'}</label>
                                                                    <Slider
                                                                        size="small"
                                                                        value={panelGroups[editPanelGroupIndex]?.angle ? panelGroups[editPanelGroupIndex]?.angle : 0}
                                                                        aria-label="Small"
                                                                        max={90}
                                                                        min={-90}
                                                                        step={1}
                                                                        valueLabelDisplay="on"
                                                                        marks={[
                                                                            {
                                                                                value: 0,
                                                                                //label: '0',
                                                                            }
                                                                        ]}
                                                                        onChange={(e) => {
                                                                            if (editPanelGroupIndex === '') return;

                                                                            if (typeof e.target.value === 'number') {
                                                                                var update = panelGroups;
                                                                                update[editPanelGroupIndex].angle = e.target.value;

                                                                                setPanelGroups(update);
                                                                                setRefresh(refresh + 1);
                                                                            }

                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="form-group px-1 mb-1">
                                                                    <label className="form-control-label">{'Modul Horizontal'}</label> <br />
                                                                    <i
                                                                        className="bg-light mx-1 p-1 rounded-circle fa fa-plus mb-1"
                                                                        onClick={() => {
                                                                            if (editPanelGroupIndex === '') return;

                                                                            var update = panelGroups;
                                                                            var updateRow = update[editPanelGroupIndex].panel.row + 1;
                                                                            var columns = update[editPanelGroupIndex].panel.column;
                                                                            var activePanel = update[editPanelGroupIndex].activePanel;

                                                                            for (var j = 0; j < columns; j++) {
                                                                                var currentPanel = { x: (updateRow - 1), y: j };
                                                                                activePanel.push(currentPanel);
                                                                            }

                                                                            update[editPanelGroupIndex].activePanel = activePanel;
                                                                            update[editPanelGroupIndex].panel.row = updateRow;
                                                                            setPanelGroups(update);
                                                                            setRefresh(refresh + 1);
                                                                        }}
                                                                    ></i>
                                                                    <i
                                                                        className="bg-light mx-1 p-1 rounded-circle fa fa-minus"
                                                                        onClick={() => {
                                                                            if (editPanelGroupIndex === '') return;

                                                                            var update = panelGroups;
                                                                            var updateRow = update[editPanelGroupIndex].panel.row - 1;
                                                                            var columns = update[editPanelGroupIndex].panel.column;
                                                                            var activePanel = update[editPanelGroupIndex].activePanel;

                                                                            for (var j = 0; j < columns; j++) {
                                                                                //console.log('j='+j);
                                                                                var currentPanel = { x: (updateRow), y: j };
                                                                                let searchIndex = activePanelSearch(currentPanel, editPanelGroupIndex)
                                                                                if (searchIndex !== -1) {
                                                                                    activePanel.splice(activePanel.indexOf(searchIndex), 1);
                                                                                }
                                                                            }

                                                                            if (updateRow === 0) {
                                                                                update.splice(editPanelGroupIndex, 1);
                                                                                setEditPanelGroupIndex('');
                                                                            } else {
                                                                                update[editPanelGroupIndex].activePanel = activePanel;
                                                                                update[editPanelGroupIndex].panel.row = updateRow;
                                                                            }

                                                                            setPanelGroups(update);
                                                                            setRefresh(refresh + 1);
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="form-group px-1 mb-1">
                                                                    <label className="form-control-label">{'Modul Vertikal'}</label> <br />
                                                                    <i
                                                                        className="bg-light mx-1 p-1 rounded-circle fa fa-plus mb-1"
                                                                        onClick={() => {
                                                                            if (editPanelGroupIndex === '') return;

                                                                            var update = panelGroups;
                                                                            var updateColumn = update[editPanelGroupIndex].panel.column + 1;
                                                                            var rows = update[editPanelGroupIndex].panel.row;

                                                                            var activePanel = update[editPanelGroupIndex].activePanel;
                                                                            for (var j = 0; j < rows; j++) {
                                                                                var currentPanel = { x: j, y: (updateColumn - 1) };
                                                                                activePanel.push(currentPanel);
                                                                            }

                                                                            update[editPanelGroupIndex].activePanel = activePanel;
                                                                            update[editPanelGroupIndex].panel.column = updateColumn;
                                                                            setPanelGroups(update);
                                                                            setRefresh(refresh + 1);
                                                                        }}
                                                                    ></i>
                                                                    <i
                                                                        className="bg-light mx-1 p-1 rounded-circle fa fa-minus"
                                                                        onClick={() => {
                                                                            if (editPanelGroupIndex === '') return;

                                                                            var update = panelGroups;
                                                                            var updateColumn = update[editPanelGroupIndex].panel.column - 1;
                                                                            var rows = update[editPanelGroupIndex].panel.row;

                                                                            var activePanel = update[editPanelGroupIndex].activePanel;
                                                                            for (var j = 0; j < rows; j++) {
                                                                                var currentPanel = { x: j, y: (updateColumn) };
                                                                                let searchIndex = activePanelSearch(currentPanel, editPanelGroupIndex)
                                                                                if (searchIndex !== -1) {
                                                                                    activePanel.splice(activePanel.indexOf(searchIndex), 1);
                                                                                }
                                                                            }

                                                                            if (updateColumn === 0) {
                                                                                update.splice(editPanelGroupIndex, 1);
                                                                                setEditPanelGroupIndex('');
                                                                            } else {
                                                                                update[editPanelGroupIndex].activePanel = activePanel;
                                                                                update[editPanelGroupIndex].panel.column = updateColumn;
                                                                            }

                                                                            setPanelGroups(update);
                                                                            setRefresh(refresh + 1);
                                                                        }}

                                                                    ></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <label className="form-control-label">3D Vorschau <i className="fa fa-info ms-1 bg-light rounded-circle" style={{ width: '12px', height: '12px', textAlign: 'center', fontSize: '10px' }}></i></label> <br />
                                                                <button className="btn btn-light mx-1 p-2 mb-1" onClick={handleTiltChange}>{tilt == 45 ? <i class="fa-solid fa-arrows-to-dot"></i> : <i class="fa-solid fa-cube"></i>}</button>
                                                                <button className="btn btn-light mx-1 p-2 mb-1" onClick={() => handleRotationChange('clockwise')}><i class="fa-solid fa-rotate-right"></i></button>
                                                                <button className="btn btn-light mx-1 p-2 mb-1" onClick={() => handleRotationChange('counterclockwise')}><i class="fa-solid fa-rotate-left"></i></button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className='col-3'
                                        style={{ height: 'fit-content', width: 'auto' }}
                                    >
                                        {roofPanelId ? (
                                            <button
                                                type='button'
                                                className={'btn btn-sm m-0 px-2 ' + (!module || validation?.module === 'invalid' ? 'btn-secondary' : 'btn-primary')}
                                                disabled={(!module || validation?.module === 'invalid' ? true : false)}
                                                onClick={() => { addPanels() }}
                                                style={{
                                                    //backgroundColor: '#CFEA00',
                                                }}
                                            >
                                                <i className='fa fa-plus'></i> <br />
                                                Modul
                                            </button>
                                        ) : ''}

                                    </div>
                                    {data?.data?.id ? (
                                        <div className='col-9'>
                                            <button type="button" onClick={() => { onSubmit(true) }} className="btn btn-light mt-2" style={{ width: '292px' }}>Add New System</button>
                                        </div>
                                    ) : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button className="btn d-none " id="roofPlanSave" onClick={() => {
                onSubmit();
            }}>Save</button>
        </>
    )
}

export default RoofPlannerV3