import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import TypeModal from './TypeModal';
import SoftSnackbar from "components/SoftSnackbar";
import FileUpload from "./FileUpload";
function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [refresh2, setRefresh2] = useState(0);
    const [speicherTypes, setSpeicherTypes] = useState([]);
    const [file, setFile] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!params.id) return;
        callFetch("speicher/" + params.id + "/edit", "GET", []).then((res) => {
            setData(res); 
            if(res?.data?.file?.length > 0){
                setFile(true);
            }
        });
    }, [params.id]);

    useEffect(() => {
        callFetch("speicherTypes", "GET", []).then((res) => {
            setSpeicherTypes(res?.data);
        });
    }, [refresh2])

    useEffect(() => {
        if (!data?.data) return;
        for (let [key, value] of Object.entries(data.data)) {
            if (key == 'without_energiespeichersystem') {
                if (value == false || value == 'false' || value == '') {
                    setValue(key, false);
                } else {
                    setValue(key, true);
                }
            } else {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }
        }
    }, [data, speicherTypes]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("speicher/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false); 
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/speicher' /> :
        <>
           
            <div className="row">
                <div className="col-md-9">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"> 
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Edit Speicher')}</h6>
                        </div>
                        <div className="card-body">
                        
                                <input type="hidden" defaultValue="PUT" {...register("_method")} />

                                <div className="row g-3">

                                    <div className="col-md-6">
                                        <label>{t('Nummber')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("speicher_identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <label>{t('Lieferanten')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{"Select Lieferanten"}</option>
                                            {data?.lieferanten && data?.lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>


                                    <div className="col-md-12">
                                        <label>{t('Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t('Typ')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("type", {
                                                    required: true,
                                                })}
                                                style={{ float: 'left', width: '65%' }}
                                                required
                                            //onChange={handleInputChange}
                                            >
                                                <option value="">--</option>
                                                {speicherTypes && speicherTypes.map((speicherType) => (
                                                    <option key={speicherType.id} value={speicherType.id}>{speicherType.name}</option>
                                                ))}
                                            </select>
                                            &nbsp;
                                            <button className="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#speicherTypeModal" style={{ padding: '11px 25px' }}>{t('Add')}</button>
                                            <div className="invalid-feedback">{errors.type && errors.type.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label>{t('Watt')} *</label>
                                        <input
                                            type="number"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("watt", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('Description')}</label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('')}
                                            {...register("description")}></textarea>
                                        <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <label>{t('Ohne Energiespeicher')}</label>
                                            <input className="form-check-input" type="checkbox" value={""} {...register("without_energiespeichersystem")} />
                                        </div>
                                        <div className="invalid-feedback">{errors.without_energiespeichersystem && errors.without_energiespeichersystem.message}</div>
                                    </div>

                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                    </form>
                </div>
                <div className="col-3"> 
                    <FileUpload id={params.id} file={file} data={data}/>
                </div>
            </div> 
            <TypeModal refreshParent={() => setRefresh2(refresh2 + 1)} /> 
        </>;
}

export default Edit;
