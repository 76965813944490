import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import SoftEditor from "components/SoftEditor";

function Angebot() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [description, setDescription] = useState('');
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("smtp-email-configurations/" + params.id + "/edit", "GET", [], setError).then((res) => {
            console.log(res);
            for (let [key, value] of Object.entries(JSON.parse(res?.data?.angebot))) {
                setValue(key, (value == null || value == 'null' ? "" : value ));
                if(key == 'description'){
                    setDescription((value == null || value == 'null' ? "" : value ))
                }
            }
        });
    }, [setError, setValue, params]);

    const onSubmit = (formData) => {
        formData.description = description;
        var data = {};
        data.angebot = JSON.stringify(formData);
        data._method = 'PUT';

        setSaving(true);
        callFetch("smtp-email-configurations/" + params.id, "POST", data, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            //setSubmitSuccess(true);
        });
    };

    return(
        <div className="row">
            <div className="col-12 col-lg-8">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Angebot Email Setup')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />
                            <div className="row g-3">
                                <div className="col-12">
                                    <label>
                                        {t('Subject')} <b>*</b>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('Subject')}
                                        {...register("subject", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.subject && errors.subject.message}</div>
                                </div>
                                <div className="col-12 mb-4">
                                    <label>
                                        {t('Description')} <b>*</b>
                                    </label>
                                    <SoftEditor
                                        onChange={setDescription}
                                        value={description}
                                    />
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>
                            </div>

                            <div className="col-12 mb-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving')} ...
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Angebot;
