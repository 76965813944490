import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next'
import TypeModal from './TypeModal';
import SoftSnackbar from "components/SoftSnackbar";
import FileUploadCreate from "./FileUploadCreate";
function Create() {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [lieferanten, setlieferanten] = useState([]);
    const [speicherTypes, setSpeicherTypes] = useState([]);
    const [id, setID] = useState('');
     
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("speicher/create", "GET", [], setError).then((res) => {
            console.log(res);
            if (!res.ok) return;
            setValue('speicher_identity', res?.data?.speicher_identity)
            setValue('id', res?.data?.id)
            setID(res?.data?.id);
            setlieferanten(res?.data?.lieferanten)
        });
    }, [refresh]);

    useEffect(()=>{
        callFetch("speicherTypes", "GET", []).then((res) => {
            setSpeicherTypes(res?.data);
        });        
    },[refresh2])

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("speicher", "POST", formData, setError).then((res) => {
            setSaving(false); 
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/speicher' /> :
        <>
         
            <div className="row"> 
                <div className="col-md-9">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"> 
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Create Speicher')}</h6>
                        </div>
                        <div className="card-body">
                          
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label>{t('Nummber')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("speicher_identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <label>{t('Lieferanten')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{"Select Lieferanten"}</option>
                                            {lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t('Typ')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("type", {
                                                    required: true,
                                                })}
                                                style={{ float: 'left', width: '65%' }}
                                                required
                                            //onChange={handleInputChange}
                                            >
                                                <option value="">--</option>
                                                {speicherTypes && speicherTypes.map((speicherType) => (
                                                    <option key={speicherType.id} value={speicherType.id}>{speicherType.name}</option>
                                                ))}
                                            </select>
                                            &nbsp;
                                            <button className="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#speicherTypeModal" style={{ padding: '11px 25px' }}>{t('Add')}</button>
                                            <div className="invalid-feedback">{errors.type && errors.type.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label>{t('Watt')} *</label>
                                        <input
                                            type="number"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("watt", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('Description')}</label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('')}
                                            {...register("description")}></textarea>
                                        <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <label>{t('Ohne Energiespeicher')}</label>
                                            <input className="form-check-input" type="checkbox" value={"Yes"} {...register("without_energiespeichersystem")} />
                                        </div>
                                        <div className="invalid-feedback">{errors.without_energiespeichersystem && errors.without_energiespeichersystem.message}</div>
                                    </div>

                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                          
                        </div>
                    </div>
                    </form>
                </div>
                <div className="col-3">
                     <FileUploadCreate id={id}/>
                </div>
            </div>  
            <TypeModal refreshParent={() => setRefresh2(refresh2 + 1)} />  
        </>;
}

export default Create;