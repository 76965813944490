import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftSnackbar from "components/SoftSnackbar";
import FileUpload from "./FileUpload";
function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [data, setData] = useState([]);
    // const [selectedFile, setSelectedFile] = useState(null);
    const [file, setFile] = useState(false);
    // const [errorSB, setErrorSB] = useState(false);
    // const closeErrorSB = () => setErrorSB(false);
    // const renderErrorSB = (
    //     <SoftSnackbar
    //         color="error"
    //         icon="error"
    //         title={t('The file must not be greater than 5 MB.')}
    //         //content={t("Changes saved successfully")}
    //         dateTime={t("")}
    //         open={errorSB}
    //         onClose={() => closeErrorSB(false)}
    //         close={() => closeErrorSB(false)}
    //         bgDanger
    //     />
    // );
    // const handleFileChange = (e) => {
    //         const file = e.target.files[0];
    //         if (file) {
    //             setSelectedFile(file);
    //             setFile(false);
    //         }
    // }

    // const handleFileRemove = () => {
    //     setValue("file", "")
    //     setSelectedFile(null)
    // }
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(!params.id) return;

        callFetch("module/" + params.id + "/edit", "GET", []).then((res) => {
            setData(res); 
            if(res?.data?.file?.length > 0){
                setFile(true);
            }
        });
    }, [params?.id]);

    useEffect(() => {
        if(!data?.data) return;

        for (let [key, value] of Object.entries(data.data)) {
            if (key == 'description') {
                for (let [description_key, description_value] of Object.entries(JSON.parse(value))) {
                    setValue('description[' + description_key + ']', (description_value && description_value != 'null' ? description_value : ''));
                }
            } else {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }
        }
    }, [data]);

    const onSubmit = (formData) => {
        setSaving(true);

        formData.description = JSON.stringify(formData.description);

        callFetch("module/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false); 
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/products/module' /> :
    <>
    
        <div className="row"> 
                <div className="col-md-9">
                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Edit Module')}</h6>
                        </div>
                        <div className="card-body">
                        
                                <input type="hidden" defaultValue="PUT" {...register("_method")} />

                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label>{t('Nummber')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("module_identity", {
                                                required: true,
                                            })}
                                            required
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <label>{t('Lieferanten')} *</label>
                                        <select
                                            className="form-control"
                                            {...register("lieferanten_id", {
                                                required: true,
                                            })}
                                            required
                                        >
                                            <option value={''}>{"Select Lieferanten"}</option>
                                            {data?.lieferanten && data?.lieferanten.map((lief) => (
                                                <option value={lief?.id}>{lief?.lieferanten_nummer + (lief?.firma_name ? ' - ' + lief?.firma_name : '')}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.lieferanten_id && errors.lieferanten_id.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Short Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("short_itle", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Watt')} *</label>
                                        <input
                                            type="number"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("watt", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                    </div>
                                    {/*
                                    <div className="col-md-4">
                                        <label>{t('Description')}</label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            placeholder={t('')}
                                            {...register("description")}></textarea>
                                        <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                    </div>
                                    */}

                                    <div className="col-md-12"></div>

                                    <div className="col-md-4">
                                        <label>{t('Markenhersteller')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Markenhersteller]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Typ')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Typ]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Zelltyp')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Zelltyp]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Leistungstoleranz')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Leistungstoleranz]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>{t('Modulabmessung') + ' (' + t('Width') + ')'}</label>
                                                <input
                                                    type="number"
                                                    step={0.01}
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("description[Modulabmessung_width]", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>{t('Modulabmessung') + ' (' + t('Height') + ')'}</label>
                                                <input
                                                    type="number"
                                                    step={0.01}
                                                    className="form-control mb-4"
                                                    placeholder={t('')}
                                                    {...register("description[Modulabmessung_height]", {
                                                        required: true,
                                                    })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Gewicht')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Gewicht]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Modulwirkungsgrad')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Modulwirkungsgrad]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Max. Druckbelastung')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[MaxDruckbelastung]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Leistungsgarantie')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Leistungsgarantie]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Produktgarantie')}</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("description[Produktgarantie]", {
                                                required: true,
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 mb-4">
                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                        
                        </div>
                    </div> 
                    </form> 
                </div>
                
                <div className="col-3">
                     <FileUpload id={params.id} file={file} data={data}/>
                </div>
        </div> 
        </>
}

export default Edit;
