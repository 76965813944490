import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";

const LieferantenIndexTable = () => {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")

    const tableHeadings = [
        {
            name: t('Lieferanten Nummer'),
            selector: row => (
                <NavLink to={'/lieferanten-partner/' + row?.lieferanten_nummer + '/details'}>
                    {row.lieferanten_nummer}
                </NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Name'),
            selector: row => (
                <div className="row mt-1" style={{ width: '300px' }}>
                    <div className="col-2 pe-0">
                        <img className="avatar avatar-sm" src={row?.logo ? process.env.REACT_APP_STORAGE_URL + row?.logo : '/assets/img/placeholder.png'} alt="" />
                    </div>
                    <div className="col-10 ps-0">
                        <p className="mb-0 text-xxs text-wrap ms-1 mt-2">{row?.firma_name}</p>
                    </div>
                </div>

            ),
        },
        {
            name: t('email'),
            selector: row => (row?.email),
        },
        {
            name: t('Date'),
            selector: row => dateFormat(row.created_at, "dd.mm.yyyy"),
        },
        
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("IU") !== 1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("ID") !== 1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("IU") !== 1 ? (
                                    <>
                                        <li>
                                            <NavLink to={'/lieferanten-partner/' + row?.lieferanten_nummer + '/edit'} className="dropdown-item">
                                                {t('Edit')}
                                            </NavLink>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("ID") !== 1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'lieferanten', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("lieferanten?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    useEffect(() => {
        if (searchKey.length > 0) {
            callFetch('lieferanten/search/' + searchKey, "GET", []).then((res) => {
                setTableData(res.data)
            })
        } else {
            setRefresh(refresh + 1)
        }
    }, [searchKey])

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        subHeader
        subHeaderComponent={<input type="text" placeholder={t("Search...")} className='form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />}
    />;
}

export default LieferantenIndexTable