import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
function EmployeeEdit() {
    let params = useParams();
    const { t } = useTranslation();
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!params.id) return;

        callFetch("callcenter/" + params.id + "/edit", "GET", []).then((res) => {
            setMembers(res?.members);
            for (let [key, value] of Object.entries(res.data)) {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }
            setSelectedMembers(res.data.members ? JSON.parse(res.data.members) : null);
        });
    }, [params?.id]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("callcenter/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/call-center' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Edit Call Center')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />

                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Call Center ID')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        {...register("identity_number", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Call Center Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Jhon Doe')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        {t('Call Center Email')} *
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control mb-4"
                                        placeholder={t('eg. email@mail.com')}
                                        {...register("email", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        {t('Password')} *
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control mb-4"
                                        placeholder={t('eg.') + " ********"}
                                        {...register("password")}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.password && t(errors.password.message)}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        {t('Mobile')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 98765432')}
                                        {...register("mobile")} />
                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Members')}</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={members ? members : { value: '', label: '---' }}
                                            value={selectedMembers}
                                            onChange={(e) => {
                                                setSelectedMembers(e);
                                                setValue('members', JSON.stringify(e));
                                            }}
                                            isMulti
                                        />
                                        <div className="invalid-feedback">{errors.selectedMembers && errors.selectedMembers.message}</div>
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label>
                                        {t('Address')}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('eg. 1234 My street, City')}
                                        {...register("address")}></textarea>
                                    <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default EmployeeEdit;
