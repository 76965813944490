import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import ProjectIndexTable from './IndexTable';
import Configurator from 'pages/project/Configurator';
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
  } from "context";
function ProjectIndex() {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0); 
    const [controller, dispatch] = useSoftUIController();
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator); 
    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);  
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0"  onClick={handleCloseConfigurator}>
                            <h6>{t('Fertigmeldung')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <ProjectIndexTable/>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    );
}

export default ProjectIndex;
