import { React, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import Moment from "react-moment";

function Index() {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const [unreadNotification, setUnreadNotification] = useState(0);

    useEffect(() => {
        callFetch("topnav-notifications", "GET", []).then((res) => {
            // console.log(res.data);
            setNotifications(res.data);
            setUnreadNotification(res.unread_notifications);
        });

    }, []);
    return (
        <>
            <div class="overflow-hidden z-50 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg shadow-gray-300 block" id="notification-dropdown" data-popper-placement="bottom">
                <div class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50">
                    Notifications
                </div>
                <div className="scroll-cs" style={{ maxHeight: '75vh'}}>
                    {notifications?.data ? notifications?.data?.map((notification) => {
                        const title = notification.title;
                        const splitTitle = title.split('#');
                        var icon = '';
                        if (notification.type == 'project' || notification.type == 'client_sign_order_overview') {
                            icon = <i class="fa-solid fa-briefcase text-primary" style={{ fontSize: "12px" }}>  </i>;
                        } else if (notification.type == 'quation') {
                            icon = <i class="fa-solid fa-bag-shopping text-primary" style={{ fontSize: "12px" }}>  </i>;
                        } else if (notification.type == 'order') {
                            icon = <i class="fa-solid fa-bag-shopping text-primary" style={{ fontSize: "12px" }}>  </i>;
                        }
                        return <a href={'/projects/' + notification.task_id} class="d-flex py-3 px-4 border-b hover:bg-gray-100">
                            <div class="flex-shrink-0">
                                <img style={{ border: '0.125rem solid rgb(4, 75, 178)' }} class="w-11 h-11 rounded-full" src={notification?.photo?.length > 0 ? process.env.REACT_APP_STORAGE_URL + 'storage/avatar/' + notification.photo : '/assets/img/placeholder.png'} alt="image" />
                                <div class="d-flex position-relative justify-center-c items-center-c ml-6 -mt-5 wc-5 h-5 bg-fuchsia-600 rounded-full border border-white ">
                                    {icon}
                                </div>
                            </div>
                            <div class="plc-3 wc-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5" style={{ color: 'rgb(52, 71, 103)' }}>{splitTitle[0]} <span class="font-semibold text-gray-900">{splitTitle[1]}</span></div>
                                <div class="text-xs text-primary"> <Moment fromNow>{notification.created_at}</Moment></div>
                            </div>
                        </a>
                    }) : <></>}
                </div>
            </div>
        </>
    );
}

export default Index;
