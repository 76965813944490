import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import dateFormat from "dateformat";

import SoftButton from "components/SoftButton";
import SoftDatePicker from "components/SoftDatePicker";
import Icon from "@mui/material/Icon";
import { useForm } from "react-hook-form";
import SoftTypography from "components/SoftTypography";

function ReklamationIndexTable(props) {
    const { t } = useTranslation();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const purchaseSave = (project_id) => {
        var fromData = {};
        fromData.project_id = project_id;
        fromData.chooseplan = "Reklamationnen";
        if (getValues("liefertermin_module[" + project_id + "]")) {
            fromData.liefertermin_module = getValues("liefertermin_module[" + project_id + "]");
        } else {
            fromData.liefertermin_module = '';
        }

        if (getValues("siefertermin_speicher[" + project_id + "]")) {
            fromData.siefertermin_speicher = getValues("siefertermin_speicher[" + project_id + "]");
        } else {
            fromData.siefertermin_speicher = '';
        }

        if (getValues("ac_liefertermin_grobhandler[" + project_id + "]")) {
            fromData.ac_liefertermin_grobhandler = getValues("ac_liefertermin_grobhandler[" + project_id + "]");
        } else {
            fromData.ac_liefertermin_grobhandler = '';
        }

        if (getValues("liefertermin_wechselrichter[" + project_id + "]")) {
            fromData.liefertermin_wechselrichter = getValues("liefertermin_wechselrichter[" + project_id + "]");
        } else {
            fromData.liefertermin_wechselrichter = '';
        }

        if (getValues("bemerkung_modullieferant[" + project_id + "]")) {
            fromData.bemerkung_modullieferant = getValues("bemerkung_modullieferant[" + project_id + "]");
        }
        if (getValues("bemerkund_speicherlieferant[" + project_id + "]")) {
            fromData.bemerkund_speicherlieferant = getValues("bemerkund_speicherlieferant[" + project_id + "]");
        }
        if (getValues("bemerkung_blw[" + project_id + "]")) {
            fromData.bemerkung_blw = getValues("bemerkung_blw[" + project_id + "]");
        }
        if (getValues("bemerkung_spedition[" + project_id + "]")) {
            fromData.bemerkung_spedition = getValues("bemerkung_spedition[" + project_id + "]");
        }

        callFetch("purchase", "POST", fromData, {}).then((res) => {
            setRefresh(refresh + 1);
        });
    }

    const tableHeadings = [
        {
            name: '',
            width: '120px',
            selector: row => (
                <>
                    {row?.stornieren == 1 ? (
                        <>
                            <div className='text-danger'>
                                <SoftButton className="me-2 my-2" variant="outlined" color={'error'} size="small" iconOnly circular>
                                    <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                                </SoftButton>
                                Module
                            </div>
                            <div className='text-danger'>
                                <SoftButton className="me-2 mb-2" variant="outlined" color={'error'} size="small" iconOnly circular>
                                    <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                                </SoftButton>
                                Speicher
                            </div>
                            <div className='text-danger'>
                                <SoftButton className="me-2 mb-2" variant="outlined" color={'error'} size="small" iconOnly circular>
                                    <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                                </SoftButton>
                                Großh.
                            </div>
                        </>
                    ) : (
                        <>
                            {row?.purchase?.liefertermin_module ? (
                                <div className='text-success'>
                                    <SoftButton className="me-2 my-2" variant="outlined" color={'success'} size="small" iconOnly circular>
                                        <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                                    </SoftButton>
                                    Module
                                </div>
                            ) : (
                                <div className='text-primary'>
                                    <SoftButton className="replay me-2 my-2" variant="outlined" color={'primary'} size="small" iconOnly circular>
                                        <Icon sx={{ fontWeight: "bold" }}>replay</Icon>
                                    </SoftButton>
                                    Module
                                </div>
                            )}

                            {row?.purchase?.siefertermin_speicher ? (
                                <div className='text-success'>
                                    <SoftButton className="me-2 mb-2" variant="outlined" color={'success'} size="small" iconOnly circular>
                                        <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                                    </SoftButton>
                                    Speicher
                                </div>
                            ) : (
                                <div className='text-primary'>
                                    <SoftButton className="replay me-2 mb-2" variant="outlined" color={'primary'} size="small" iconOnly circular>
                                        <Icon sx={{ fontWeight: "bold" }}>replay</Icon>
                                    </SoftButton>
                                    Speicher
                                </div>
                            )}

                            {row?.purchase?.ac_liefertermin_grobhandler ? (
                                <div className='text-success'>
                                    <SoftButton className="me-2 mb-2" variant="outlined" color={'success'} size="small" iconOnly circular>
                                        <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                                    </SoftButton>
                                    Großh.
                                </div>
                            ) : (
                                <div className='text-primary'>
                                    <SoftButton className="replay me-2 mb-2" variant="outlined" color={'primary'} size="small" iconOnly circular>
                                        <Icon sx={{ fontWeight: "bold" }}>replay</Icon>
                                    </SoftButton>
                                    Großh.
                                </div>
                            )}
                        </>
                    )}
                </>
            ),
            sortable: false,
        },
        {
            name: t('Einkauf Nr.'),
            width: '120px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.purchase?.id ? 'PO-' + row?.purchase?.id : ''}</span>,
            sortable: false,
        },
        {
            name: t('Rekl.Nr.'),
            width: '120px',
            selector: row => <NavLink to={'/reclamation/' + row.id} className={row?.stornieren == 1 ? "text-danger" : "text-primary"}>{row.id}</NavLink>,
            sortable: false,
        },
        {
            name: t('Customer Name'),
            width: '170px',
            cell: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.customer_name}</span>,
            sortable: false,
        },
        {
            name: t('Street'),
            width: '170px',
            cell: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.street + ' ' + row?.nr}</span>,
            sortable: false,
        },
        {
            name: t('Plz'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.plz}</span>,
            sortable: false,
        },
        {
            name: t('Ort'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.ort}</span>,
            sortable: false,
        },
        {
            name: t('Telefon'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.telefonnummer}</span>,
            sortable: false,
        },
        {
            name: t('Mobilnummer'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.mobilnummer}</span>,
            sortable: false,
        },
        {
            name: t('E-mail Adresse'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.email}</span>,
            sortable: false,
        },
        {
            name: t('Anzahl Module'),
            width: '140px',
            selector: row => (
                <>
                    {row?.products && JSON.parse(row?.products).map((product, product_index) => (
                        <>
                            {(product?.id ? product?.id.split('-')?.[0] : '') == 'module' ? (
                                <>
                                    <SoftTypography key={product_index} className="p-0" style={{ fontSize: '11px' }} variant="caption">
                                        <strong>{product?.quantity}</strong>
                                    </SoftTypography>
                                    <br />
                                </>
                            ) : <></>}
                        </>
                    ))}
                </>
            ),
            sortable: false,
        },
        {
            name: t('Watt'),
            width: '100px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.quations?.module_eingeben == 'no' ? row?.quations?.module?.watt : row?.quations?.module_watt}</span>,
            sortable: false,
        },
        {
            name: t('Modultyp'),
            width: '220px',
            cell: row => (
                <>
                    {row?.products && JSON.parse(row?.products).map((product, product_index) => (
                        <>
                            {(product?.id ? product?.id.split('-')?.[0] : '') == 'module' ? (
                                <>

                                    <SoftTypography key={product_index} className="p-0" style={{ fontSize: '11px' }} variant="caption">
                                        <strong>{product?.title}</strong>
                                    </SoftTypography>
                                    <br />
                                </>
                            ) : <></>}
                        </>
                    ))}
                </>
            ),
            sortable: false,
        },
        {
            name: t('kWp'),
            width: '120px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{((row?.quations?.anzahl_module * (row?.quations?.module_eingeben == 'no' ? row?.quations?.module?.watt : row?.quations?.module_watt)) / 1000)}</span>,
            sortable: false,
        },
        {
            name: t('Speichertyp'),
            width: '270px',
            cell: row => (
                <>
                    {row?.products && JSON.parse(row?.products).map((product, product_index) => (
                        <>
                            {(product?.id ? product?.id.split('-')?.[0] : '') == 'speicher' ? (
                                <>
                                    <SoftTypography key={product_index} className="p-0" style={{ fontSize: '11px' }} variant="caption">
                                        <strong>{product?.quantity + ' Stck. | ' + product?.title}</strong>
                                    </SoftTypography>
                                    <br />
                                </>
                            ) : <></>}
                        </>
                    ))}
                </>
            ),
            sortable: false,
        },
        /*
        {
            name: t('kWh'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.quations?.total_plant_production}</span>,
            sortable: false,
        },
        */
        {
            name: t('Zusatzvereinbarung'),
            width: '450px',
            selector: row => (
                <>
                    <Select
                        placeholder={t('Select')}
                        options={[
                            { value: 'Zwischenzähler', label: 'Zwischenzähler' },
                            { value: 'Power to Heat', label: 'Power to Heat' },
                            { value: 'inkl. Technikpaket', label: 'inkl. Technikpaket' },
                            { value: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC', label: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC' },
                            { value: 'Zählerzusammenlegung', label: 'Zählerzusammenlegung' },
                            { value: 'inkl. Aufständerung Ost/West', label: 'inkl. Aufständerung Ost/West' },
                            { value: 'inkl. Aufständerung Süd', label: 'inkl. Aufständerung Süd' },
                            { value: 'Steuerberatergutschein SENEC', label: 'Steuerberatergutschein SENEC' },
                            { value: 'inkl. Kreuzverbund', label: 'inkl. Kreuzverbund' },
                            { value: 'inkl. Schlüsselfertiger Preis / All in one Paket', label: 'inkl. Schlüsselfertiger Preis / All in one Paket' },
                            { value: 'inkl. Blechziegel', label: 'inkl. Blechziegel' },
                            { value: 'TIGO Optimierer', label: 'TIGO Optimierer' },
                            { value: 'inkl. Notstrombox SENEC', label: 'inkl. Notstrombox SENEC' },
                            { value: 'inkl. Notstrombox E3DC', label: 'inkl. Notstrombox E3DC' },
                            { value: 'inkl. Wallbox 22kw', label: 'inkl. Wallbox 22kw' },
                            { value: 'inkl. Wallbox 11kw', label: 'inkl. Wallbox 11kw' },
                            { value: 'Taubenschutz', label: 'Taubenschutz' },
                            { value: 'Schneefanggitter', label: 'Schneefanggitter' }
                        ]}
                        value={row?.quations ? JSON.parse(row?.quations?.zusatzvereinbarungen) : []}
                        isDisabled={true}
                        isMulti
                    />
                </>
            ),
            sortable: false,
        },
        {
            name: t('Leistungsoptimierer'),
            width: '170px',
            selector: row => <span className={row?.stornieren == 1 ? "text-danger" : ""}>{row?.quations?.anzahl_der_leistungsoptimierer}</span>,
            sortable: false,
        },
        {
            name: t('Wechselrichter'),
            width: '170px',
            cell: row => (
                <>
                    {row?.products && JSON.parse(row?.products).map((product, product_index) => (
                        <>
                            {(product?.id ? product?.id.split('-')?.[0] : '') == 'wechselrichter' ? (
                                <>
                                    <SoftTypography key={product_index} className="p-0" style={{ fontSize: '11px' }} variant="caption">
                                        <strong>{product?.quantity + ' Stck. | ' + product?.title}</strong>
                                    </SoftTypography>
                                    <br />
                                </>
                            ) : <></>}
                        </>
                    ))}
                </>
            ),
            sortable: false,
        },
        {
            name: t('Montagetermin'),
            width: '170px',
            selector: row => (
                <>
                    <span className={row?.stornieren == 1 ? "text-danger" : ""}>
                        <i className='fa fa-calendar'></i> <span className='ms-2'>{row?.dachmontag ? dateFormat(row?.dachmontag?.date, "dd.mm.yyyy") : row?.elektromontage ? dateFormat(row?.elektromontage?.date, "dd.mm.yyyy") : ''}</span>
                    </span>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Bemerkung Modullieferant'),
            width: '300px',
            selector: row => (
                <textarea
                    className="form-control focus-textarea"
                    defaultValue={row?.purchase?.bemerkung_modullieferant}
                    style={{ height: '40px', width: '260px', fontSize: '11px' }}
                    {...register("bemerkung_modullieferant[" + row?.id + "]", {
                        required: true,
                    })}
                    onBlur={(e) => {
                        purchaseSave(row?.id);
                    }}
                ></textarea>
            ),
            sortable: false,
        },
        {
            name: t('Bemerkund Speicherlieferant'),
            width: '300px',
            selector: row => (
                <textarea
                    className="form-control focus-textarea"
                    defaultValue={row?.purchase?.bemerkund_speicherlieferant}
                    style={{ height: '40px', width: '260px', fontSize: '11px' }}
                    {...register("bemerkund_speicherlieferant[" + row?.id + "]", {
                        required: true,
                    })}
                    onBlur={(e) => {
                        purchaseSave(row?.id);
                    }}
                ></textarea>
            ),
            sortable: false,
        },
        {
            name: t('Bemerkung BLW'),
            width: '300px',
            selector: row => (
                <textarea
                    className="form-control focus-textarea"
                    defaultValue={row?.purchase?.bemerkung_blw}
                    style={{ height: '40px', width: '260px', fontSize: '11px' }}
                    {...register("bemerkung_blw[" + row?.id + "]", {
                        required: true,
                    })}
                    onBlur={(e) => {
                        purchaseSave(row?.id);
                    }}
                ></textarea>
            ),
            sortable: false,
        },
        {
            name: t('Bemerkung Spedition'),
            width: '300px',
            selector: row => (
                <textarea
                    className="form-control focus-textarea"
                    defaultValue={row?.purchase?.bemerkung_spedition}
                    style={{ height: '40px', width: '260px', fontSize: '11px' }}
                    {...register("bemerkung_spedition[" + row?.id + "]", {
                        required: true,
                    })}
                    onBlur={(e) => {
                        purchaseSave(row?.id);
                    }}
                ></textarea>
            ),
            sortable: false,
        },
        {
            name: t('Liefertermin Module'),
            width: '220px',
            selector: row => (
                <>
                    <input
                        className={'form-control form-control-sm mt-1 ' + (row?.stornieren == 1 ? "text-danger" : "")}
                        type='date'
                        placeholder="Select a date"
                        defaultValue={row?.purchase?.liefertermin_module}
                        {...register("liefertermin_module[" + row?.id + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        {row?.purchase?.liefertermin_module ? (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-ac m-0 btn-sm w-100" style={{ textTransform: 'capitalize' }}>Bestätigt Modul</a>
                        ) : (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-primary m-0 btn-sm w-100"
                                style={{ textTransform: 'capitalize' }}
                            >Bestätigen Modul</a>
                        )}
                    </div>
                    <div className='mb-1'>
                        {row?.purchase?.purchase_histories && row?.purchase?.purchase_histories.map(purchase_historie => (
                            <>
                                {purchase_historie?.liefertermin == 'liefertermin_module' ? (
                                    <NavLink className={'d-block'} to={'/'}>{purchase_historie?.user?.name + '- ' + dateFormat(purchase_historie?.created_at, "HH:ss dd.mm.yyyy")}</NavLink>
                                ) : ''}
                            </>
                        ))}
                    </div>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Liefertermin Speicher'),
            width: '220px',
            selector: row => (
                <>
                    <input
                        className={'form-control form-control-sm mt-1 ' + (row?.stornieren == 1 ? "text-danger" : "")}
                        type='date'
                        placeholder="Select a date"
                        defaultValue={row?.purchase?.siefertermin_speicher}
                        {...register("siefertermin_speicher[" + row?.id + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        {row?.purchase?.siefertermin_speicher ? (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-ac m-0 btn-sm w-100" style={{ textTransform: 'capitalize' }}>Bestätigt Speicher</a>
                        ) : (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-primary m-0 btn-sm w-100"
                                style={{ textTransform: 'capitalize' }}
                            >Bestätigen Speicher</a>
                        )}
                    </div>
                    <div className='mb-1'>
                        {row?.purchase?.purchase_histories && row?.purchase?.purchase_histories.map(purchase_historie => (
                            <>
                                {purchase_historie?.liefertermin == 'siefertermin_speicher' ? (
                                    <NavLink className={'d-block'} to={'/'}>{purchase_historie?.user?.name + '- ' + dateFormat(purchase_historie?.created_at, "HH:ss dd.mm.yyyy")}</NavLink>
                                ) : ''}
                            </>
                        ))}
                    </div>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Liefertermin Großhändler'),
            width: '200px',
            selector: row => (
                <>

                    <input
                        className={'form-control form-control-sm mt-1 ' + (row?.stornieren == 1 ? "text-danger" : "")}
                        type='date'
                        placeholder="Select a date"
                        defaultValue={row?.purchase?.ac_liefertermin_grobhandler}
                        {...register("ac_liefertermin_grobhandler[" + row?.id + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        {row?.purchase?.ac_liefertermin_grobhandler ? (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-ac m-0 btn-sm w-100" style={{ textTransform: 'capitalize' }}>Bestätigt Großh.</a>
                        ) : (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-primary m-0 btn-sm w-100"
                                style={{ textTransform: 'capitalize' }}
                            >Bestätigen Großh.</a>
                        )}
                    </div>
                    <div className='mb-1'>
                        {row?.purchase?.purchase_histories && row?.purchase?.purchase_histories.map(purchase_historie => (
                            <>
                                {purchase_historie?.liefertermin == 'ac_liefertermin_grobhandler' ? (
                                    <NavLink className={'d-block'} to={'/'}>{purchase_historie?.user?.name + '- ' + dateFormat(purchase_historie?.created_at, "HH:ss dd.mm.yyyy")}</NavLink>
                                ) : ''}
                            </>
                        ))}
                    </div>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Liefertermin Wechselrichter'),
            width: '220px',
            selector: row => (
                <>
                    <input
                        className={'form-control form-control-sm mt-1 ' + (row?.stornieren == 1 ? "text-danger" : "")}
                        type='date'
                        placeholder="Select a date"
                        defaultValue={row?.purchase?.liefertermin_wechselrichter}
                        {...register("liefertermin_wechselrichter[" + row?.id + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        {row?.purchase?.liefertermin_wechselrichter ? (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-ac m-0 btn-sm w-100" style={{ textTransform: 'capitalize' }}>Bestätigt Speicher</a>
                        ) : (
                            <a
                                onClick={(e) => {
                                    purchaseSave(row?.id);
                                }}
                                className="btn btn-primary m-0 btn-sm w-100"
                                style={{ textTransform: 'capitalize' }}
                            >Bestätigen Speicher</a>
                        )}
                    </div>
                    <div className='mb-1'>
                        {row?.purchase?.purchase_histories && row?.purchase?.purchase_histories.map(purchase_historie => (
                            <>
                                {purchase_historie?.liefertermin == 'liefertermin_wechselrichter' ? (
                                    <NavLink className={'d-block'} to={'/'}>{purchase_historie?.user?.name + '- ' + dateFormat(purchase_historie?.created_at, "HH:ss dd.mm.yyyy")}</NavLink>
                                ) : ''}
                            </>
                        ))}
                    </div>
                </>
            ),
            sortable: false,
        },
    ];

    useEffect(() => {
        setLoading(true);

        var parameters = "?search=" + props?.search + "&page=" + pageNumber + "&chooseplan=" + props?.chooseplan + "&planType=ac"
        if(props?.filterKeys){
            for (let [key, value] of Object.entries(props?.filterKeys)) {
                parameters += "&"+key+"=" + value
            }
        }

        callFetch("purchase"+parameters, "GET", []).then((res) => {
            setTableData(res.data);
            setLoading(false);
        });
    }, [props?.filterKeys, props?.refresh, props?.search, props?.chooseplan, pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                                type='button'
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                        type='button'
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                                type='button'
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} noValidate autoComplete="off">
                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                />
            </form>
        </>
    )
}

export default ReklamationIndexTable;
