import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import IndexTableTab from "./IndexTableTab";
import Cookies from 'js-cookie';
import Create from "./Create";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import PieChart from "./PieChart";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import callFetch from "../../helpers/callFetch";
import SoftBadgeDot from "components/SoftBadgeDot";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard"; 
import Card from "@mui/material/Card";
import Configurator from './Configurator';
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
  } from "context";
function Index() {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [controller, dispatch] = useSoftUIController();
    const [data, setData] = useState(0);
    const [type, setType] = useState('overdue');
    const [ticketTitle, setTicketTitle] = useState('Alle Reklamationen');
    useEffect(() => { 
        callFetch("reclamations-counter", "GET", []).then((res) => {
            setData(res.data);   
        });
     }, []) 

    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                    {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                        <a class="btn btn-icon btn-primary" data-bs-toggle="modal" data-bs-target="#createReclamationModalOpen">
                            {t('Reklamation anlegen')}
                        </a>
                    ) : <></>}

                    {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                        <div className="float-end">
                            <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'employee-export'} className="btn btn-outline-dark">
                                {t('Export CSV')}
                            </a> 
                        </div>
                    ) : <></>} 
                </div>
            </div>
            <div className="">
            <Grid container spacing={3}> 
                <Grid item xs={12} sm={3}>
                    <Card> 
                        <div onClick={() => { setType('overdue'); setTicketTitle('Überfällig Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                            variant="button"
                                            color={'text'}
                                            opacity={type == 'overdue' ? 1 : 0.6}
                                            textTransform="capitalize"
                                            fontWeight={'bold'}
                                            className={'ministatistics-title'}
                                            >
                                            {t('Überfällig Reklamationen')}
                                            </SoftTypography>
                                            <SoftTypography
                                            variant="h5"
                                            fontWeight="bold" 
                                            color={type == 'overdue' ? 'error' : 'dark'}
                                            opacity={type == 'overdue' ? 1 : 0.6}
                                            >
                                            {data?.overdue}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <Grid item xs={4}>
                                        <SoftBox
                                        variant="gradient"
                                        bgColor={"dark"}
                                        color={"white"}
                                        opacity={type == 'overdue' ? 1 : 0.6}
                                        width="3rem"
                                        height="3rem"
                                        marginLeft="auto"
                                        borderRadius="md"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        shadow="md"
                                        >
                                        <Icon fontSize="small" color="inherit">
                                            paid
                                        </Icon>
                                        </SoftBox>
                                    </Grid>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid> 
                <Grid item xs={12} sm={3}>
                    <Card> 
                        <div onClick={() => {setType('todays'); setTicketTitle('Heutige Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                            variant="button"
                                            color={'text'}
                                            opacity={type == 'todays' ? 1 : 0.6}
                                            textTransform="capitalize"
                                            fontWeight={'bold'}
                                            className={'ministatistics-title'}
                                            >
                                            {t('Heutige Reklamationen')}
                                            </SoftTypography>
                                            <SoftTypography
                                            variant="h5"
                                            fontWeight="bold" 
                                            color={type == 'todays' ? 'error' : 'dark'}
                                            opacity={type == 'todays' ? 1 : 0.6}
                                            >
                                            {data?.due_today}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <Grid item xs={4}>
                                        <SoftBox
                                        variant="gradient"
                                        bgColor={"dark"}
                                        color={"white"}
                                        opacity={type == 'todays' ? 1 : 0.6}
                                        width="3rem"
                                        height="3rem"
                                        marginLeft="auto"
                                        borderRadius="md"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        shadow="md"
                                        >
                                        <Icon fontSize="small" color="inherit">
                                            paid
                                        </Icon>
                                        </SoftBox>
                                    </Grid>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid> 
                 <Grid item xs={12} sm={3}>
                 <Card> 
                        <div onClick={() => { setType('open'); setTicketTitle('Offene Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                            variant="button"
                                            color={'text'}
                                            opacity={type == 'open' ? 1 : 0.6}
                                            textTransform="capitalize"
                                            fontWeight={'bold'}
                                            className={'ministatistics-title'}
                                            >
                                            {t('Offene Reklamationen')}
                                            </SoftTypography>
                                            <SoftTypography
                                            variant="h5"
                                            fontWeight="bold" 
                                            color={type == 'open' ? 'error' : 'dark'}
                                            opacity={type == 'open' ? 1 : 0.6}
                                            >
                                            {data?.open}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <Grid item xs={4}>
                                        <SoftBox
                                        variant="gradient"
                                        bgColor={"dark"}
                                        color={"white"}
                                        opacity={type == 'open' ? 1 : 0.6}
                                        width="3rem"
                                        height="3rem"
                                        marginLeft="auto"
                                        borderRadius="md"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        shadow="md"
                                        >
                                        <Icon fontSize="small" color="inherit">
                                            paid
                                        </Icon>
                                        </SoftBox>
                                    </Grid>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid> 
                <Grid item xs={12} sm={3} mb={3}> 
                <Card> 
                        <div onClick={() => { setType('watched'); setTicketTitle('Verfolgte Reklamationen')}} style={{cursor: 'pointer', padding: '16px'}}> 
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <SoftBox ml={0} lineHeight={1}>
                                            <SoftTypography
                                            variant="button"
                                            color={'text'}
                                            opacity={type == 'watched' ? 1 : 0.6}
                                            textTransform="capitalize"
                                            fontWeight={'bold'}
                                            className={'ministatistics-title'}
                                            >
                                            {t('Verfolgte Reklamationen')}
                                            </SoftTypography>
                                            <SoftTypography
                                            variant="h5"
                                            fontWeight="bold" 
                                            color={type == 'watched' ? 'error' : 'dark'}
                                            opacity={type == 'watched' ? 1 : 0.6}
                                            >
                                            {data?.watched}{" "}
                                        
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                
                                    <Grid item xs={4}>
                                        <SoftBox
                                        variant="gradient"
                                        bgColor={"dark"}
                                        color={"white"}
                                        opacity={type == 'watched' ? 1 : 0.6}
                                        width="3rem"
                                        height="3rem"
                                        marginLeft="auto"
                                        borderRadius="md"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        shadow="md"
                                        >
                                        <Icon fontSize="small" color="inherit">
                                            paid
                                        </Icon>
                                        </SoftBox>
                                    </Grid>
                                </Grid> 
                            </div> 
                    </Card>
                </Grid>   
                    
                
            </Grid>
            </div>
            <div className="row">
                <div className="col-8">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6 className="ministatistics-title">{t(ticketTitle)}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTableTab refresh={refresh} type={type}/>
                        </div>
                    </div>
                </div> 
                <div className="col-4">
                    <Card sx={{ overflow: "visible" }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} className="ministatistics-title">
                                <PieChart
                                    title="Chart"
                                    chart={{
                                        labels: ["Overdue", "Open", "Due Today"],
                                        datasets: {
                                        label: "Projects",
                                        backgroundColors: ["error", "warning", "info"],
                                        data: [data?.overdue, data?.open, data?.due_today],
                                        },
                                    }}
                                /> 
                            </Grid> 
                        </Grid> 
                   </Card>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6 className="ministatistics-title">{t('Alle Reklamationen')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable refresh={refresh} type={type}/>
                        </div>
                    </div>
                </div>  
            </div>

            <Create setrefresh={setRefresh} />
        </>
    );
}

export default Index;
