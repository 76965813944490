import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DepartmentManageModal from "./DepartmentManageModal";
import DesignationManageModal from "./DesignationManageModal";
import ImagePopup from "./ImagePopup";
import SoftSnackbar from "components/SoftSnackbar";

function EmpolyeeAdd({ onImageSelect, nameChange, showData, title }) {
    const { t } = useTranslation();
    const params = useParams();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [rawImage, setRawImage] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [files,setFile] = useState();
    const [image, setImage] = useState();
    const [employeeId, setEmployeeId] = useState('');
    const [receivedError, setReceivedError] = useState(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    //image crop modal
    const closeModal = () => {
        if(files){
            const reader = new FileReader();
            reader.onload = () =>{
                onImageSelect(URL.createObjectURL(files))
                setImage(files)
            }
            reader.readAsDataURL(files);
        }
        setModalVisible(false);
    }
    const openModal = () => {
      setModalVisible(true);
    };

    const croppedPhoto = async (photo) => {
        try {
            const response = await fetch(photo.url);
            const blob = await response.blob();
            // Create a File object from the Blob
            const file = new File([blob], 'photo.jpg', {
              type: 'image/jpeg', // Set the appropriate MIME type
              lastModified: new Date().getTime(), // Set the current timestamp
            });
    
            onImageSelect(URL.createObjectURL(file))
            setImage(file)
            setModalVisible(false);
          } catch (error) {
            console.error('Error fetching and creating file:', error);
          }
    }
  
    const handleImageChange = (event) =>{
        const file = event.target.files[0]
        setFile(file)
        if(file){
            setSelectedImage(URL.createObjectURL(file));
            const reader = new FileReader();
            reader.onload = () =>{
                setSelectedImage(URL.createObjectURL(file));
                openModal();
            }
            reader.readAsDataURL(file);
        }
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
      
        if (name) {
          let selectedOptionText = '';
          
          if (event.target.nodeName === 'SELECT') {
            const selectedOption = event.target.options[event.target.selectedIndex];
            selectedOptionText = selectedOption ? selectedOption.text : '';
          } else if (event.target.nodeName === 'INPUT' && event.target.type === 'text') {
            selectedOptionText = `${value}`; // Customize this value as needed
          }
      
          nameChange({
            ...showData,
            [name]: value,
            [name]: selectedOptionText,
          });
        }
      };
    useEffect(()=>{
        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res?.data);
            setRefresh2(refresh2 +1)
        });
        
        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res?.data);
        });
        
    },[refresh])

    useEffect(() => {
        if(params?.id && refresh2){
            callFetch("employees/create", "GET", []).then((res) => {
                setRoles(res?.data?.roles);
            });

            callFetch("employees/" + params.id + "/edit", "GET", []).then((res) => {
                setValue('employee_identity_number', res?.data?.employee_identity_number);
                setValue('name', res?.data?.name);
                setValue('email', res?.data?.email);
                setValue('mobile', res?.data?.mobile);
                setValue('role', res?.data?.role);
                setValue('gender', res?.data?.gender);
                setValue('department_id', res?.data?.department_id);
                setValue('joining_date', res?.data?.joining_date);
                setValue('date_of_birth', res?.data?.date_of_birth);
                setValue('address', res?.data?.address);
            });
        }else{
            callFetch("employees/create", "GET", []).then((res) => {
                setValue('employee_identity_number', res.data.id);
                setRoles(res?.data?.roles);
            });
        }
        
    }, [refresh2, params?.id]);
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Employee successfully updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );


    const onSubmit = (formData) => {
        formData.photo = !image ? '': image;
        setSaving(true);
        callFetch(params?.id ? `employees/${params?.id}`:"employees", "POST", formData, setError).then((res) => {
            setSaving(false);
            if(receivedError == null && res.message === 'success' && params?.id){
                openSuccessSB();
            } 
            setEmployeeId(res.employee_identity_number);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/employees'} /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t(`${title ? title:'Kontakdaten'}`)}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            {
                                params?.id && <input type="hidden" defaultValue="PUT" {...register("_method")} />
                            }
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Employee ID')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 123')}
                                        {...register("employee_identity_number", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.employee_identity_number && errors.employee_identity_number.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Employee Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Jhon Doe')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                        onChange={handleInputChange}
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Employee Email')} *
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control mb-4"
                                        placeholder={t('eg. email@mail.com')}
                                        {...register("email", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>{t('Profile Picture')}</label>
                                    <input type="file" className="form-control mb-4" {...register("photo")} onChange={handleImageChange} />
                                    <div className="invalid-feedback">{errors.photo && errors.photo.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Password')} *
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control mb-4"
                                        placeholder={t('eg.')+" ********"}
                                        {...register("password", {
                                            required: params?.id ? false: true,
                                        })}
                                        required={params?.id ? false: true}
                                    />
                                    <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Mobile')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 98765432')}
                                        {...register("mobile")} />
                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Role')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("role", {
                                                required: true,
                                            })}
                                            required onChange={handleInputChange}>
                                            <option value="">--</option>
                                            {roles && roles.map((role) => (
                                                <option key={role.id} value={role.id}>{t(role.name)}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Department')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("department_id", {
                                                required: true,
                                            })}
                                            style={{ float: 'left', width: '65%' }}
                                            required
                                            onChange={handleInputChange}
                                        >
                                            <option value="">--</option>
                                            {departments && departments.map((department) => (
                                                <option key={department.id} value={department.id}>{department.name}</option>
                                            ))}
                                        </select>
                                        &nbsp;
                                        <button className="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#departmentModal" style={{ padding: '11px 25px' }}>{t('Add')}</button>
                                        <div className="invalid-feedback">{errors.department_id && errors.department_id.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Gender')}
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("gender")}>
                                            <option value="">--</option>
                                            <option value="Male">{t('Male')}</option>
                                            <option value="Female">{t('Female')}</option>
                                            <option value="Other">{t('Others')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Joining Date')} *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("joining_date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.joining_date && errors.joining_date.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Date of Birth')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("date_of_birth")} />
                                        <div className="invalid-feedback">{errors.date_of_birth && errors.date_of_birth.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="form-group mb-4">
                                    <label>
                                        {t('Address')}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('eg. 1234 My street, City')}
                                        {...register("address")}></textarea>
                                    <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                </div>
                            </div>
                            <div className="col-12 mb-4 text-end">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>

                {/* Department, Designation Modal Start */}
                <DesignationManageModal refreshParent={() => setRefresh(refresh + 1)} />
                <DepartmentManageModal refreshParent={() => setRefresh(refresh + 1)} />
                {/* Department, Designation Modal End */}
                <ImagePopup showModal={isModalVisible} openModal={closeModal} photoURL={selectedImage} filePhoto={files} croppedPhoto={croppedPhoto} />
            </div>
        </div>;
}

export default EmpolyeeAdd;
