import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SoftAlert from "components/SoftAlert";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Distribution from "./create/Distribution";
import Order from "./create/Distribution/Order";
import Swal from "sweetalert2";
import SoftSnackbar from "components/SoftSnackbar";

function ProjectStatus(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [errormessage, setErrorMessage] = useState('');
    const [status, setStatus] = useState('');
    const [resaleStatus, setResaleStatus] = useState('');
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [data, setData] = useState(0);
    const [projectStatus, setProjectStatus] = useState('');

    const [description, setDescription] = useState('');
    const [attachments, setAttachment] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeStep, setActiveStep] = useState('');
    const isLastStep = activeStep === 1;

    const [resaleStatusChange, setResaleStatusChange] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }

    useEffect(() => {
        if (props?.projectid) {
            setValue('projectid', props.projectid);
        }

        if (props?.quations_id) {
            setValue('quations_id', props.quations_id);
        }

        if (props?.status) {
            setValue('status', props.status);
            setProjectStatus(props.status);
        }

        setErrorMessage('')
    }, [props?.quations_id, props?.projectid, props?.status]);

    const currentStatusConfirmMessage = () => {

        console.log('currentStatusConfirmMessage= ')


        callFetch("project/related/id/get/" + (props?.projectid ? props?.projectid : getValues('projectid') ? getValues('projectid') : params.id), "GET", []).then((res) => {
            if ((res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status ? res?.data?.termine_qoute?.status : '') && (res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status ? res?.data?.termine_qoute?.status : '') != '1-Verkauft' && status == '1-Verkauft') {
                const newSwal = Swal.mixin({
                    customClass: {
                        confirmButton: "button button-success",
                        cancelButton: "button button-error",
                    },
                    buttonsStyling: false,
                });

                newSwal
                    .fire({
                        title: "If you close the popup the status will be " + (res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status ? res?.data?.termine_qoute?.status : ''),
                        //icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: "no",
                        confirmButtonText: "Yes",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            //Swal.fire("Order Not created yet!", "success");
                            popupCLose();
                        }
                    });
            } else {
                popupCLose();
            }
        });
    };

    const popupCLose = () => {
        if (document.getElementById("projectStatusAngebotOrderClose" + (params?.id ? params?.id : props?.projectid))) {
            document.getElementById("projectStatusAngebotOrderClose" + (params?.id ? params?.id : props?.projectid)).click();
        }

        if (document.getElementById("dashboardProjectCardListRefresh")) {
            document.getElementById("dashboardProjectCardListRefresh").click();
        }

        document.querySelectorAll('.div.todays-card').forEach(function (a) {
            a.classList.remove('visibility-hidden')
        });

        document.querySelectorAll('div.todays-Unbewertet-tab').forEach(function (a) {
            // a.classList.remove('visibility-hidden')
            a.style.zIndex = 9999;
        });

        document.getElementById('projectStatusSales' + (params?.id ? params?.id : props?.projectid)).classList.remove('visibility-visible');
    }

    useEffect(() => {
        if (!(props?.projectid ? props?.projectid : params?.id)) return;
        if (props?.todayCard === true) {
            //console.log(props?.status);
            setValue('projectid', (props?.projectid ? props?.projectid : params?.id));
            setValue('status', (props?.status ? props?.status : ''));
            setProjectStatus((props?.status ? props?.status : ''));
        } else {
            callFetch("project/related/id/get/" + (props?.projectid ? props?.projectid : params?.id), "GET", []).then((res) => {
                setValue('projectid', res?.data?.id);
                setValue('status', (res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status));
                setProjectStatus((res?.data?.status ? res?.data?.status : res?.data?.termine_qoute?.status));
                setResaleStatus((res?.data?.resalestatus?.title ? res?.data?.resalestatus?.title : ''))
            });
        }
    }, [(props?.projectid ? props?.projectid : params?.id)]);

    useEffect(() => {

        //console.log(props?.refresh)
        //console.log('props?.status')
        //console.log(props?.status)
        //console.log(props?.todayCard)

        if (props?.refresh && props?.todayCard === true) {
            setValue('status', (props?.status ? props?.status : ''));
            setProjectStatus((props?.status ? props?.status : ''));
        }

    }, [props?.refresh, props?.status]);

    const onSubmitResaleStatus = (status) => {

        setResaleStatusChange(false);
        setResaleStatus(status);

        var formData = getValues();
        formData.status = status;

        callFetch("project/resaleStatus", "POST", formData, setError).then((res) => {
            if (res?.message === 'success') {
                setResaleStatusChange(true);
            }
        });

        console.log(status)
    }

    const onSubmit = () => {
        var formData = getValues();

        if (formData.status == '5-Kunde war nicht da') {
            document.getElementById('projectStatusWithFileUploadOpenBtn' + (params?.id ? params?.id : props?.projectid)).click();
            return;
        }

        setSaving(true);
        setErrorMessage('');
        callFetch("project/attribute/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (res?.ok) {
                setSubmitSuccess(true);

                if (document.getElementById('timeline-refresh-btn')) {
                    document.getElementById('timeline-refresh-btn').click();
                }

                if (document.getElementById('dashboardProjectCardListRefresh')) {
                    document.getElementById('dashboardProjectCardListRefresh').click();
                }
            } else {
                setErrorMessage(res?.message);

                if (props?.salesPopup === true) {

                    if (formData.status == '1-Verkauft') {
                        setActiveStep(1);
                    } else {
                        setActiveStep(0);
                    }

                    document.getElementById('projectSalesStatus' + (params?.id ? params?.id : props?.projectid)).click();

                    document.querySelectorAll('.div.todays-card').forEach(function (a) {
                        a.classList.add('visibility-hidden')
                    });

                    document.querySelectorAll('div.todays-Unbewertet-tab').forEach(function (a) {
                        //a.classList.add('visibility-hidden')
                        a.style.zIndex = 'unset';
                    });

                    document.getElementById('projectStatusSales' + (params?.id ? params?.id : props?.projectid)).classList.add('visibility-visible');
                }
            }
        });
    };

    const onSubmit2 = () => {

        var formData = getValues();
        formData.attachments = JSON.stringify(attachments);

        setSaving(true);
        setErrorMessage('');
        setSubmitSuccess(false);
        callFetch("project/attribute/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (res?.ok) {
                setSubmitSuccess(true);
                setAttachment([]);
                setValue('description', '');
                setValue('attachments', '');

                if (document.getElementById('timeline-refresh-btn')) {
                    document.getElementById('timeline-refresh-btn').click();
                }
            } else {
                setErrorMessage(res?.message);
            }
        });
    }

    return (
        <>
            {props?.salesStatus != false ? (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("QU") !== -1 ? (
                        <select
                            className="form-control"
                            {...register("status", {
                                onChange: (e) => {
                                    setStatus(e.target.value)
                                    onSubmit();
                                }
                            })}
                            //disabled={props?.status == '1-Verkauft' ? true : false}
                            disabled={props?.status == '1-Verkauft' || projectStatus == '1-Verkauft' ? true : false}
                        >
                            <option value="">--</option>
                            <option value="1-Verkauft">{t('1-Verkauft')}</option>
                            <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                            <option value="3-Angebot">{t('3-Angebot')}</option>
                            <option value="4-Überleger">{t('4-Überleger')}</option>
                            <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                            <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                            <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                            <option value="9-Kundenseitig abgesagt">{t('9-Kundenseitig abgesagt')}</option>
                            <option value="10-Entscheider waren nicht da">{t('10-Entscheider waren nicht da')}</option>
                            <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
                        </select>
                    ) : (
                        <select
                            className="form-control"
                            {...register("status", {})}
                            disabled
                        >
                            <option value="">--</option>
                            <option value="1-Verkauft">{t('1-Verkauft')}</option>
                            <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                            <option value="3-Angebot">{t('3-Angebot')}</option>
                            <option value="4-Überleger">{t('4-Überleger')}</option>
                            <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                            <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                            <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                            <option value="9-Kundenseitig abgesagt">{t('9-Kundenseitig abgesagt')}</option>
                            <option value="10-Entscheider waren nicht da">{t('10-Entscheider waren nicht da')}</option>
                            <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
                        </select>
                    )}
                </>
            ) : ''}

            {props?.resallerStatus === true ? (
                <select
                    className="form-control"
                    value={resaleStatus}
                    onChange={(e) => {
                        onSubmitResaleStatus(e.target.value);
                    }}
                    disabled={resaleStatus == '1-Verkauft' ? true : false}
                >
                    <option value="">--</option>
                    <option value="1-Verkauft" disabled>{t('1-Auftrag')}</option>
                    <option value="3-Angebot" disabled>{t('3-Angebot')}</option>
                    <option value="4-Wiedervorlage">{t('4-Wiedervorlage')}</option>
                    <option value="5-Finanzierung geplatzt">{t('5-Finanzierung geplatzt')}</option>
                    <option value="6-Zu Teuer">{t('6-Zu Teuer')}</option>
                    <option value="7-Zu Alt">{t('7-Zu Alt')}</option>
                    <option value="7-Schlechte Bewertung">{t('7-Schlechte Bewertung')}</option>
                    <option value="9-Lokaler Anbieter bevorzugt">{t('9-Lokaler Anbieter bevorzugt')}</option>
                    <option value="10-Fehlerhafte Kommunikation zum VKB">{t('10-Fehlerhafte Kommunikation zum VKB')}</option>
                    <option value="10-Über Wiederrufsrecht verkauft">{t('10-Über Wiederrufsrecht verkauft')}</option>
                    <option value="10-Haben unter Druck unterschrieben / Ging zu schnell">{t('10-Haben unter Druck unterschrieben / Ging zu schnell')}</option>
                </select>
            ) : ''}


            {props?.errorDisplay !== false ? (
                <>
                    {errormessage ? (
                        <SoftAlert color={'dark'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                            {errormessage == 'Kein Auftrag vorhanden. Bitte erstellen Sie hier ein Auftrag' ? (
                                <>
                                    <SoftTypography variant="body2" color="white">
                                        Kein Auftrag vorhanden. Bitte erstellen Sie{" "}
                                        <SoftTypography component="a" style={{}} href={'/projects/' + (params?.id ? params?.id : props?.projectid) + '#auftrag'} variant="plain" fontWeight="medium" color="white">hier</SoftTypography>{" "}ein Auftrag
                                    </SoftTypography>
                                </>
                            ) : errormessage == 'Kein Angebot vorhanden. Bitte erstellen Sie hier ein Angebot' ? (
                                <>
                                    <SoftTypography variant="body2" color="white">
                                        Kein Angebot vorhanden. Bitte erstellen Sie{" "}
                                        <SoftTypography component="a" href={'/projects/' + (params?.id ? params?.id : props?.projectid) + '#distribution'} variant="plain" fontWeight="medium" color="white">hier</SoftTypography>{" "}ein Angebot
                                    </SoftTypography>
                                </>
                            ) : t(errormessage)}
                        </SoftAlert>
                    ) : submitSuccess ? (
                        <SoftAlert color={'success'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                            <SoftTypography variant="body2" color="white">
                                {t('Speichern erfolgreich')}
                            </SoftTypography>
                        </SoftAlert>
                    ) : ''}
                </>
            ) : ""}

            {resaleStatusChange ? (
                <SoftAlert color={'success'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                    <SoftTypography variant="body2" color="white">
                        {t('Speichern erfolgreich')}
                    </SoftTypography>
                </SoftAlert>
            ) : ''}



            <a className="d-none" id={"projectStatusWithFileUploadOpenBtn" + (params?.id ? params?.id : props?.projectid)} data-bs-toggle="modal" data-bs-target={"#projectStatusWithFileUpload" + (params?.id ? params?.id : props?.projectid)}></a>
            <div className="modal fade" id={"projectStatusWithFileUpload" + (params?.id ? params?.id : props?.projectid)} tabIndex="-1" role="dialog" aria-labelledby={"projectStatusWithFileUpload" + (params?.id ? params?.id : props?.projectid)} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title" id="projectStatusWithFileUploadLabel">{t('5-Kunde war nicht da')}</h5>
                            <button type="button" className="btn-close text-dark" id="projectStatusWithFileUploadClose" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <SoftAlert color={'error'} sm={{ fontWeight: '700', fontSize: '0.75rem' }}>
                                {t('Bitte laden sie ein Bild mit Visitenkarte am Postkasten des Kunden hoch und schreiben Sie eine Bemerkung mit min. 10 Buchstaben.')}
                            </SoftAlert>


                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                            >
                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('Description')}
                                    </SoftTypography>
                                </SoftBox>

                                <textarea  {...register("description", { required: true, })} onChange={(e) => { setDescription(e.target.value) }} className="form-control"></textarea>
                            </SoftBox>

                            <div className="col-sm-12 mt-3">
                                <div className="form-group">
                                    <label htmlFor="status_upload_files" className="text-primary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-link"></i> {t('Upload Files')}</label>
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            setLoading(true);
                                            var formData = {};
                                            for (let [key, val] of Object.entries(e.target.files)) {
                                                formData['files[' + key + ']'] = e.target.files[key];
                                            }

                                            callFetch('attachment', "POST", formData, setError).then((res) => {
                                                setLoading(false);
                                                if (res.message == 'success') {
                                                    let data = attachments;
                                                    for (let [key, val] of Object.entries(res.data)) {
                                                        data[data.length] = val;
                                                    }
                                                    setAttachment(data);
                                                    setValue('attachments', JSON.stringify(data));
                                                }
                                            });
                                        }}
                                        id="status_upload_files"
                                        className="d-none"
                                        multiple
                                        accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                                    />
                                </div>
                            </div>

                            <SoftBox p={0} className="order-processing">
                                <Grid container spacing={3}>
                                    {attachments && attachments.map(function (data, i) {
                                        return (
                                            <Grid item key={i} className="text-center">
                                                <div>
                                                    <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment}>
                                                        {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                                            <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment} />
                                                        ) : (
                                                            <div className="placeholder">{data?.extension}</div>
                                                        )}

                                                    </a>
                                                </div>
                                                <a
                                                    onClick={() => {
                                                        removeAttachment(data?.id);
                                                    }}
                                                >{t('Remove')}</a>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </SoftBox>

                        </div>
                        <div className="modal-footer">
                            {!saving && (
                                <>
                                    {attachments.length <= 0 || description.length < 10 ? (
                                        <button type="button"
                                            className={"btn btn-disabled mb-0"}>
                                            {t('Save')}
                                        </button>
                                    ) : (
                                        <button type="button"
                                            onClick={(e) => {
                                                if (attachments.length <= 0 || description.length <= 0) {
                                                    console.log('validation error');
                                                } else {
                                                    onSubmit2();
                                                }

                                            }}
                                            className={"btn btn-primary mb-0"} data-bs-dismiss="modal" aria-label="Close">
                                            {t('Save')}
                                        </button>
                                    )}
                                </>
                            )}
                            {saving && (
                                <button type="button" className="btn btn-disabled mb-0">
                                    {t('Save...')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <a className="d-none" id={"projectSalesStatus" + (params?.id ? params?.id : props?.projectid)} data-bs-toggle="modal" data-bs-target={"#projectStatusSales" + (params?.id ? params?.id : props?.projectid)}></a>
            <div className="modal fade" id={"projectStatusSales" + (params?.id ? params?.id : props?.projectid)} tabIndex="-1" role="dialog" aria-labelledby={"projectStatusSales" + (params?.id ? params?.id : props?.projectid)} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title" id="projectStatusWithFileUploadLabel">{t('Project Sales')}</h5>
                            <button onClick={() => {
                                //activeStep === 1
                                currentStatusConfirmMessage();

                            }} type="button" className="btn-close text-dark">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <button type="button" className="btn-close text-dark d-none" id={"projectStatusAngebotOrderClose" + (params?.id ? params?.id : props?.projectid)} data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <SoftBox mt={1} mb={1}>
                                <Grid justifyContent="center">
                                    <Grid item xs={12} lg={8}>
                                        <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                            <Step id="AngeboteSalesTab" onClick={() => setActiveStep(0)}>
                                                <StepLabel>1. {t('Angebote')}</StepLabel>
                                            </Step>
                                            <Step id="OrderSalesTab" onClick={() => setActiveStep(1)}>
                                                <StepLabel>2. {t('Order')}</StepLabel>
                                            </Step>
                                        </Stepper>
                                    </Grid>
                                </Grid>
                            </SoftBox>

                            {activeStep === 0 ? (
                                <Distribution projectid={props?.projectid} overview={true} menu={false} sidebar={false} fullwidth={false} />
                            ) : activeStep === 1 ? (
                                <Order projectid={props?.projectid} overview={true} menu={false} sidebar={false} fullwidth={false} />
                            ) : ''}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectStatus;
