import { useMemo, useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import LoadingCard from "components/LoadingCard";

function ProjectStatusCards(props) {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(0);
    //const [users, setUsers] = useState([]);
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'All',
    });

    useEffect(() => {
        setLoading(true);
        callFetch("statistics/project-status-card", "POST", filterData, setError).then((res) => {
            setData(res.data);
            setLoading(false);
            if (!res.ok) return;
        });
    }, [refresh]);

    useEffect(() => {
        if(!props?.filterData?.created_at) return;
        
        if(props?.filterData?.created_at != filterData?.created_at){
            var data = filterData;
            data.created_at =  props?.filterData?.created_at;
            setFilterData(data);
            setRefresh(refresh+1)
        }
    }, [props?.filterData?.created_at]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={3}>
                    {loading ? (
                        <LoadingCard
                            sx={{height: '80px'}}
                        />
                    ) : (
                        <MiniStatisticsCard
                            title={{ text: "Angebote", fontWeight: "medium" }}
                            percentage={{
                                text: (data?.total_angebote_amount || data?.total_angebote_amount == 0 ? (
                                    <NumericFormat 
                                        value={data?.total_angebote_amount} 
                                        displayType="text" 
                                        thousandSeparator={"."} 
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix={' € '}
                                    />
                                ) : '--'),
                                color: 'success',
                                variant: 'caption',
                                fontWeight: "medium"
                            }}
                            count={data?.total_angebote || data?.total_angebote == 0 ? data?.total_angebote : '--'}
                            icon={{ color: "dark", component: "sell" }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    {loading ? (
                        <LoadingCard
                            sx={{height: '80px'}}
                        />
                    ) : (
                        <MiniStatisticsCard
                            title={{ text: "Auftrage", fontWeight: "medium"}}
                            percentage={{
                                text: (data?.total_auftrage_ammount || data?.total_auftrage_ammount == 0 ? (
                                    <NumericFormat 
                                        value={data?.total_auftrage_ammount} 
                                        displayType="text" 
                                        thousandSeparator={"."} 
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix={' € '}
                                    />
                                ) : '--'),
                                color: 'success',
                                variant: 'caption',
                                fontWeight: "medium"
                            }}
                            count={data?.total_auftrage || data?.total_auftrage == 0 ? data?.total_auftrage : '--'}
                            icon={{ color: "dark", component: "fact_check" }}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    {loading ? (
                        <LoadingCard
                            sx={{height: '80px'}}
                        />
                    ) : (
                        <MiniStatisticsCard
                            title={{ text: "Offene Projekte", fontWeight: "medium" }}
                            count={data?.total_open_projects || data?.total_open_projects == 0 ? data?.total_open_projects : '--'}
                            icon={{ color: "dark", component: "file_open" }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    {loading ? (
                        <LoadingCard
                            sx={{height: '80px'}}
                        />
                    ) : (
                        <MiniStatisticsCard
                            title={{ text: "Storno", fontWeight: "medium" }}
                            count={data?.total_storno_projects || data?.total_storno_projects == 0 ? data?.total_storno_projects : '--'}
                            icon={{ color: "dark", component: "backspace" }}
                        />
                    )}
                </Grid>
            </Grid>          
        </>
        )
    }
    
export default ProjectStatusCards;