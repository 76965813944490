import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { callFetch } from "helpers/callFetch";
import Cookies from 'js-cookie';

function Reklamation(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [comisioning, setComisioning] = useState('0');
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();

    useEffect(() => {


        console.log(props?.reclamation?.dc_reclamation_abnahmeprotokoll?.id)
        console.log(props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status)
        console.log(props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status)

        var comi = props?.reclamation?.department == 'Dachmontage' ? props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status : props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status;

        if (!comi) return;

        console.log(comi)

        //console.log('props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status')
        //console.log(props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status)

        setComisioning(parseInt(comi));

    }, [props?.reclamation?.id, props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status, props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status, props?.reclamation?.dc_reclamation_abnahmeprotokoll?.id]);


    const onSubmit = (formData) => {

        formData.acdcid = (props?.reclamation?.department == 'Dachmontage' ? props?.reclamation?.dc_reclamation_abnahmeprotokoll?.id : props?.reclamation?.ac_reclamation_abnahmeprotokoll?.id);
        formData.abnahme = 'reklmation';
        formData.type = (props?.reclamation?.department == 'Dachmontage' ? 'dc' : 'ac');
        formData.commissioning = comisioning;
        formData.description = '';

        console.log('onSubmit');
        console.log(formData);

        setSaving(true);
        callFetch("comisioning-status", "POST", formData, setError).then((res) => {



            document.getElementById("ComisioningModalReklamationClose" + props?.reclamation?.id).click();
            setSaving(false);

            if (comisioning == "2") {
                if (props?.reclamation?.department == 'Dachmontage') {
                    document.getElementById('openDCAbnahmeTicket').click();
                } else {
                    document.getElementById('openACAbnahmeTicket').click();
                }

                document.getElementById('setTicketDefaultAssignorderprocessingId').click();
            }

            if (!res.ok) return;
            setSubmitSuccess(true);
            document.getElementById('timeline-refresh-btn').click()
        });
    }


    return (
        <>
            <div className="timeline-block mb-3">
                <span className="timeline-step">
                    <i className="ni ni-bell-55 text-success text-gradient" />
                </span>
                <div className="timeline-content">
                    <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '} <span style={{ fontWeight: '300', fontStyle: 'italic' }}>{(props?.reclamation?.ticket?.assignorderprocessing_id ? 'DC-' + props?.reclamation?.ticket?.assignorderprocessing_id : '')}</span></h6>
                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(props?.reclamation?.created_at, "dd.mm.yyyy")}</p>
                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(props?.reclamation?.created_at, "HH:ss")}</p>

                    <p className="text-sm m-0">
                        <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                        <SoftTypography variant="caption"><NavLink to={'/reclamation/' + props?.reclamation?.id}>{'R-' + props?.reclamation?.id}</NavLink></SoftTypography>
                    </p>

                    <p className="text-sm m-0 mb-0">
                        <SoftTypography variant="caption"><strong>{t('Abteilung')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{props?.reclamation?.department}</SoftTypography>
                    </p>

                    <p className="text-sm m-0 mb-0">
                        <SoftTypography variant="caption"><strong>{t('Prioritat')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{props?.reclamation?.priority}</SoftTypography>
                    </p>

                    <p className="text-sm m-0 mb-0">
                        <SoftTypography variant="caption"><strong>{t('Erstellt von')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{props?.reclamation?.user?.name}</SoftTypography>
                    </p>

                    {props?.reclamation?.department == 'Dachmontage' || props?.reclamation?.department == 'Elektromontage' ? (
                        <p className="text-sm m-0 mb-0">
                            <SoftTypography variant="caption"><strong>{t('Leitstand')}: </strong></SoftTypography>
                            <SoftTypography variant="caption">{props?.reclamation?.dc_freigeben == 1 ? t('DC Approved') : props?.reclamation?.ac_freigeben == 1 ? t('AC Approved') : '--'}</SoftTypography>
                        </p>
                    ) : ''}


                    {props?.reclamation?.reclamation_plan && props?.reclamation?.reclamation_plan.length ? props?.reclamation?.reclamation_plan.map((reclamationplan) => (
                        <>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Technician')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{reclamationplan?.employee?.name ? reclamationplan?.employee?.name : '--'}</SoftTypography>
                            </p>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Panung Datum')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{reclamationplan?.date ? dateFormat(reclamationplan.date, "dd.mm.yyyy") : '--'}</SoftTypography>
                            </p>
                        </>
                    )) : props?.reclamation?.department == 'Dachmontage' || props?.reclamation?.department == 'Elektromontage' ? (
                        <>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Technician')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{'--'}</SoftTypography>
                            </p>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Panung Datum')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{'--'}</SoftTypography>
                            </p>
                        </>
                    ) : ''}

                    {props?.reclamation?.products && JSON.parse(props?.reclamation?.products).map(function (product, i) {
                        return (
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Product')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{product?.quantity} Stück. {product?.title}</SoftTypography>
                            </p>

                        );
                    })}


                    {props?.reclamation?.department == 'Dachmontage' ? (
                        <p className="text-sm mb-1">
                            <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography>
                            <SoftTypography
                                variant="caption"
                                color={props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status == 1 ? 'success'
                                    : props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status == 2 ? ''
                                        : 'warning'
                                }
                            >
                                {props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status == 1 ?
                                    <a className="text-success" target='_blank' href={process.env.REACT_APP_BACKEND_URL + 'reclamation/dcabname/' + props?.reclamation?.dc_reclamation_abnahmeprotokoll?.id}>{t('Erfolgreich')}</a>
                                    : props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status == 2 ? t('Nicht Erfolgreich')
                                        : t('Nicht bewertet')}
                            </SoftTypography>

                            {JSON.parse(Cookies.get('permissions')).indexOf("dabhU") !== -1 && props?.reclamation?.dc_reclamation_abnahmeprotokoll?.id ? (
                                <i
                                    className="fa fa-pencil ms-2"
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        document.getElementById('ComisioningModalReklamationOpen' + props?.reclamation?.id).click();
                                    }}
                                ></i>
                            ) : ''}
                        </p>
                    ) : props?.reclamation?.department == 'Elektromontage' ? (
                        <p className="text-sm mb-1">
                            <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography>
                            <SoftTypography
                                variant="caption"
                                color={props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status == 1 ? 'success'
                                    : props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status == 2 ? ''
                                        : 'warning'}
                            >
                                {props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status == 1 ?
                                    <a className="text-success" target='_blank' href={process.env.REACT_APP_BACKEND_URL + 'reclamation/acabname/' + props?.reclamation?.ac_reclamation_abnahmeprotokoll?.id}>{t('Erfolgreich')}</a>
                                    : props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status == 2 ? t('Nicht Erfolgreich')
                                        : t('Nicht bewertet')}
                            </SoftTypography>
                            {JSON.parse(Cookies.get('permissions')).indexOf("acAbhU") !== -1 && props?.reclamation?.ac_reclamation_abnahmeprotokoll?.id ? (
                                <i
                                    className="fa fa-pencil ms-2"
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        document.getElementById('ComisioningModalReklamationOpen' + props?.reclamation?.id).click();
                                    }}
                                ></i>
                            ) : ''}
                        </p>
                    ) : ''}


                    {props?.reclamation?.reclamation_plan && props?.reclamation?.reclamation_plan.map((reclamationplan) => (
                        <>
                            {reclamationplan.email_trans && reclamationplan.email_trans.map((email_tran) => (
                                <>

                                    {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                        <>
                                            {email ? (
                                                <>
                                                    <p className="text-sm m-0 mb-1">
                                                        <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                        <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                    </p>
                                                    <p className="text-sm m-0 mb-1">
                                                        <SoftTypography variant="caption">{email}</SoftTypography>
                                                    </p>
                                                </>
                                            ) : ''}

                                        </>
                                    ))}
                                </>
                            ))}
                        </>
                    ))}
                </div>
            </div>



            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register('id')} value={props?.reclamation?.project_nr} />

                <a href="#" data-bs-toggle="modal" id={"ComisioningModalReklamationOpen" + props?.reclamation?.id} data-bs-target={"#ComisioningModalReklamation" + props?.reclamation?.id} className="btn btn-outline-secondary px-5 d-none">ComisioningModalReklamation</a>
                <div className="modal fade" id={"ComisioningModalReklamation" + props?.reclamation?.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t(props?.type == 'dc' ? 'Dc Commissioning Successfully Done' : 'Ac Commissioning Successfully Done')}?</h5>
                                <button
                                    type="button"
                                    className="btn-close text-dark"
                                    id={"ComisioningModalReklamationClose" + props?.reclamation?.id}
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="d-flex align-items-center justify-content-between mt-2" >
                                        <div className="d-flex align-items-center">
                                            <label className="mt-2" >{t('Yes')} &nbsp;&nbsp;&nbsp;</label>
                                            <div className="form-check d-inline-block">
                                                <input
                                                    type="radio"
                                                    {...register("commissioning", {
                                                        required: true,
                                                    })}
                                                    className="form-check-input me-md-3"
                                                    value={1}
                                                    onClick={() => setComisioning(1)}
                                                    checked={comisioning == '1' ? true : false}
                                                    required
                                                />
                                            </div>

                                            <label className="mt-2" >{t('No')} &nbsp;&nbsp;&nbsp;</label>
                                            <div className="form-check d-inline-block">
                                                <input
                                                    type="radio"
                                                    {...register("commissioning", {
                                                        required: true,
                                                    })}
                                                    className="form-check-input me-md-7"
                                                    value={2}
                                                    onClick={() => setComisioning(2)}
                                                    checked={comisioning == '2' ? true : false}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">{errors.commissioning && errors.commissioning.message}</div>

                                </div>

                                {/*comisioning == '2' ?
                                    <div id="commissioning">
                                        <label>{t('Description')}</label>
                                        <textarea className="form-control" {...register('description')}>

                                        </textarea>
                                    </div>
                                    : <div id="commissioning" style={{ display: 'none' }}>
                                        <label>{t('Description')}</label>
                                        <textarea className="form-control" {...register('description')}>

                                        </textarea></div>*/}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-outline-dark btn-start mt-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {t("Cancel")}
                                </button>
                                {!saving && (
                                    <button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            background: '#d63384',
                                            color: '#ffffff'
                                        }}
                                    >
                                        {t(comisioning == '1' ? 'Auftrag unterschreiben' : 'Bestätigen')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Bestätigen ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Reklamation;
