/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import { CustomButtonInput } from "@fullcalendar/react";
// @mui material components
import Card from "@mui/material/Card";
import { useRef, useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

function Calendar({ dashboard, searchField, searchKey, header, abhnameFilter, getAbhnamheStatus, year, day, month, week, ...rest }) {
  const { t } = useTranslation();
  const calendarRef = useRef(null);

  const [currentDate, setCurrentDate] = useState(new Date());

  const renderMonthDates = () => {
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const dates = [];

    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const isWeekend = date.getDay() === 0 || date.getDay() === 6; // Sunday (0) or Saturday (6)
      const isActive = isSameDate(date, new Date()); // Check if date is same as current date

      // Determine if date is active
      const dateClassName = isActive ? 'date active' : 'date';

      // Get short day name
      const dayShort = getDayShort(date.getDay());

      // Your custom HTML structure
      dates.push(
        <div key={i} className="width-14 calendar-item">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p className="day-short">{dayShort}</p>
            <p className={dateClassName} onClick={() => handleDateClick(date)}>{i}</p>
            <p className="priority"><span className=""></span></p>
          </div>
        </div>
      );
    }

    return dates;
  }


  const getDayShort = (dayIndex) => {
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']; // Short names of days
    return days[dayIndex];
  }

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }



  const handleDateClick = (date) => {
    const calendarApi = calendarRef.current?.getApi();
    console.log(calendarApi);
    if (calendarApi) {
      // calendarApi.initialDate = new Date(Date.UTC(2018, 7, 1)); 
      calendarApi.gotoDate(date);
    }
  };

  const handleNextButtonClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi.next();
  };



  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
      ...el,
      className: validClassNames.find((item) => item === el.className)
        ? `event-${el.className}`
        : "event-info",
    }))
    : [];
  return (
    <Card sx={{ height: "100%" }}>

      <SoftBox pt={2} px={2} lineHeight={1} style={{ height: '200px'}}>

        {header.title ? (
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </SoftTypography>
        ) : null}
        {header.date ? (
          <SoftTypography component="p" variant="button" color="text" fontWeight="medium">
            {header.date}
          </SoftTypography>
        ) : null}


        {searchField || abhnameFilter ? (
          <>
            <div className=" d-flex align-items-center justify-content-end">
              {searchField && <>
                <img src="/assets/img/searchicon.png" className="calendar-search-icon" />
                <input type="text" onChange={(e) => searchKey(e.target.value)} className=" form-control" placeholder="Names, VK,AC,DC " style={{ borderColor: '#D0D5DD', width: '280px', height: '44px', paddingLeft: '45px' }} />
              </>}

              {abhnameFilter &&
                <>
                  <img src="/assets/img/Icon.png" style={{ height: '10px', position: 'absolute', top: '15px', left: '320px' }} />
                  <select
                    onChange={(e) => getAbhnamheStatus(e.target.value)}
                    className=" form-control mx-2"
                    style={{ maxWidth: '140px', height: '44px', borderColor: '#D0D5DD', paddingLeft: '60px' }}
                  >
                    <option value={''}>Filtern</option>
                    <option value={'Erfolgreiche AC'}>AC Abnahme Erfolgreich</option>
                    <option value={'nicht Erfolgreiche AC'}>AC Abnahme nicht Erfolgreich</option>
                    <option value={'Erfolgreiche DC'}>DC Abnahme Erfolgreich</option>
                    <option value={'nicht Erfolgreiche DC'}>DC Abnahme nicht Erfolgreich</option>
                  </select>
                </>
              }
            </div>
          </>
        ) : ''}

        <p className="calendar-day-month"><span className="text-bold">{t(day)}</span>, {t(month)} {year}</p>
        <p className="calendar-week-year">KW {week} - {year}</p>
        <p className="calendar-count-projects">{events?.length} {t('Projects')}</p>
      </SoftBox>

      <CalendarRoot p={2}>

        <FullCalendar
          ref={calendarRef}
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          events={events}
          height="100%"

        />

      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
