import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "../../helpers/callFetch";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dateFormat, { masks } from "dateformat";
import Cookies from "js-cookie";
import SoftSnackbar from "components/SoftSnackbar";
import team5 from "assets/images/team-5.jpg";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
function Comments(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("success");
  const [messages, setMessages] = useState([]);
  const [total, setTotal] = useState("");
  useEffect(() => {
    if(!props?.id) return;

    setValue("from_id", props.id);
    setValue("from", props.from);
    callFetch("comments/" + props.from + '/' + props.id, "GET", []).then((res) => {
      console.log(res.comments);
      setData(res.comments);
    });
  }, [props.id]);



  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const renderdisplayMessage = (
    <SoftSnackbar
      color={""}
      icon=""
      title={t("")}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={displayMessage}
      onClose={() => setDisplayMessage(false)}
      close={() => setDisplayMessage(false)}
      bgSuccess
    />
  );

  const onSubmit = (formData) => {
    setSaving(true);
    callFetch("store-comment", "POST", formData, setError).then((res) => {
      callFetch("comments/" + props.from + '/' + props.id, "GET", []).then((res) => {
        // setMessages(res.data);
        console.log(res);
        setData(res.comments);
        setValue('text', '');
        // setTotal(res.total);
      });
      setSaving(false);
      setSubmitSuccess(true);
    });
  };

  return (
    <>
      <div style={{ maxHeight: '250px' }} className="scroll-cs">
        {data && data.map((comment, key) => {
          return <SoftBox key={comment.name} display="flex" mt={key === 0 ? 0 : 3}>
            <SoftBox flexShrink={0} className="comments-users-thumbnail bg-white">
              <SoftAvatar
                src={'/assets/img/placeholder.png'}
                alt={comment.name}
                className="comments-users-thumbnail"
              />
            </SoftBox>
            <SoftBox flexGrow={1} ml={2}>
              <SoftTypography
                variant="h5"
                fontWeight="medium"
                fontSize="0.875rem"
                textTransform="capitalize"
              >
                {comment.user.name}
              </SoftTypography>
              <SoftBox mt={1} mb={1} lineHeight={0.75}>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  fontSize="0.75rem"
                  color="text"
                >
                  {comment.text}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" flexWrap="wrap" alignItems="center">
                <SoftBox display="flex" alignItems="center" mr={1}>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    fontSize="0.6rem"
                    color="text"
                  >
                    {dateFormat(comment?.created_at, "dd.mm.yyyy, HH:ss")}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        })}
      </div>


      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 mb-0">
          <div className="d-flex g-0">
            <div className="justify-content-start">
              <SoftBox flexShrink={0} mr={2} className="comments-users-thumbnail bg-white">
                <SoftAvatar
                  src={'/assets/img/placeholder.png'}
                  alt="profile picture"
                  className="comments-users-thumbnail"
                />
              </SoftBox>
            </div>
            <div className="justify-content-start" style={{ width: '80%' }}>
              <input type="hidden" {...register("from_id")} />
              <div className="form-group">
                <SoftInput
                  placeholder={t("Type here...")}
                  multiline
                  rows={2}
                  {...register("text")}
                />
              </div>
            </div>
            <div className="justify-content-start">
              {!saving && (
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ margin: "10px 30px" }}
                >
                  {t("Send")}
                </button>
              )}
              {saving && (
                <button type="submit" className="btn btn-disabled" disabled>
                  {t("Sending ...")}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Comments;
