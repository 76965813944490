import { React, useState } from "react";
import { useForm } from "react-hook-form";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";

function Illustration() {
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { t } = useTranslation();
  let tempAuth = Cookies.get('token') ? true : false;
  const [errorMessage, setErrorMessage] = useState('');
  const [signinSuccess, setSigninSuccess] = useState(tempAuth);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('dashboard');
  const [emailErrorTwo, setEmailErrorTwo] = useState(false);
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const { i18n } = useTranslation();

  const onSubmit = (formData) => {
    setLoading(true);
    setPasswordError(false);
    setEmailError(false);
    callFetch('signin', 'POST', formData, setError)
      .then(loginData => {
        
        setLoading(false);

        let l = { flag: 'de', lang: 'de', name: 'Deutsch' }
        i18n.changeLanguage(l.lang);
        Cookies.set('lang', JSON.stringify(l));

        if (loginData.message == 'The entered password is invalid.') {
          setPasswordError(true);
          setEmailError(false);
          setEmailErrorTwo(false);
          return;
        }
        if (loginData.message == 'The email must be a valid email address.') {
          setEmailErrorTwo(true);
          setEmailError(false);
          setPasswordError(false);
          return;
        }
        if (loginData.message == "The email address you entered isn't connected to an account.") {
          setEmailError(true);
          setEmailErrorTwo(false);
          setPasswordError(false);
          return;
        }

        if (!loginData.ok) {
          console.log(loginData);

          if (loginData?.status && loginData?.status == 'error') {
            setErrorMessage(loginData?.message);
          }

          return;
        } else {
          setErrorMessage('');
        }

        setPasswordError(false);
        setEmailError(false);
        Cookies.set('token', loginData.data.token);
        Cookies.set('user', JSON.stringify({
          id: loginData.data.user.id,
          name: loginData.data.user.name,
          email: loginData.data.user.email,
          mobile: loginData.data.user.mobile,
          gender: loginData.data.user.gender,
          date_of_birth: loginData.data.user.date_of_birth,
          address: loginData.data.user.address,
          photo: loginData.data.user.photo,
          rolename: loginData.data.user.roles[0].name,
          roleid: loginData.data.user.roles[0].id,
        }));

        Cookies.set('permissions', JSON.stringify(loginData.data.permissions));
        Cookies.set('user_cache', loginData.data.user.cache);

        setTimeout(() => {
          window.location.href = process.env.REACT_APP_FRONTEND_URL + redirectUrl;
        }, 1500);
        //setSigninSuccess(true);
      });
  }


  return signinSuccess ? <Navigate to={'/' + redirectUrl} /> :
    <>
      <IllustrationLayout
        title="Login"
        description={t('Tragen Sie Ihre E-Mail und Passwort ein')}
      /*
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
      */
      >
        <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <SoftBox mb={2}>
            <SoftInput
              type="email"
              placeholder="Email"
              size="large"
              {...register('email', {
                required: true
              })}
            />

            {errors.email ?
              <div className="invalid-feedback d-block">{t(errors.email.message)}</div>
              : emailError ? <div className="invalid-feedback d-block">{t("The email address you entered isn't connected to an account.")}</div>
                : emailErrorTwo ? <div className="invalid-feedback d-block">{t('The email must be a valid email address.')}</div>
                  : errorMessage ? <div className="invalid-feedback d-block">{t(errorMessage)}</div>
                    : ""}
          </SoftBox>

          <SoftBox mb={2}>
            <SoftInput
              type="password"
              placeholder="Password"
              size="large"
              {...register('password', {
                required: true
              })}
            />

            {errors.password ? <div className="invalid-feedback d-block">{t(errors.password.message)}</div> : passwordError ? <div className="invalid-feedback d-block">{t('The entered password is invalid.')}</div> : ''}
          </SoftBox>

          <SoftBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;{t('Erinnere dich')}
            </SoftTypography>
          </SoftBox>

          <SoftBox mt={4} mb={1}>

            {loading ? (
              <button style={{ background: 'linear-gradient(121.78deg, #21D4FD 9.06%, #2152FF 88.15%)', boxShadow: 'none' }} type="button" disabled className="btn btn-primary w-100 mb-0">{t('Sign In')}</button>
            ) : (
              <button style={{ background: 'linear-gradient(121.78deg, #21D4FD 9.06%, #2152FF 88.15%)', boxShadow: 'none' }} type="submit" className="btn btn-primary w-100 mb-0">{t('Sign In')}</button>
            )}

          </SoftBox>
        </SoftBox>
      </IllustrationLayout >
    </>;
}

export default Illustration;