import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { number } from 'prop-types';
//import Timeline from './Timeline';

function IndexTableProjectWithSalses(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [assignedDateTimeUser, setAssignedDateTimeUser] = useState([]);
    const [project_Data, setProjectData] = useState([]);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const verkauferFeatch = () => {
        callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
            setProjectData(res.data);
        });
    }

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh + 1);
            }
        })
    }


    const validateDateTimeVerkaufer = (row_id = 0) => {
        /*
        reset({});

        setTimeout(() =>{
            var dateTimeUserData = [];
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('verkaufer'))) {
                callFetch("projects/create?date="+getValues("date["+selectedrow_key+"]")+'&time='+getValues("time["+selectedrow_key+"]"), "GET", []).then((res) => {                        
                    var available_employees = [];
                    for (let [available_employee_key, available_employee_value] of Object.entries(res?.data?.employees)) {
                        available_employees.push(available_employee_value?.user?.id);
                    }

                    var dateTimeUser = getValues("date["+selectedrow_key+"]")+'_'+getValues("time["+selectedrow_key+"]")+'_'+getValues("verkaufer["+selectedrow_key+"]");
                    if(available_employees.indexOf(Number(getValues("verkaufer["+selectedrow_key+"]"))) !== -1){                            
                        if(dateTimeUserData.indexOf(dateTimeUser) !== -1){
                            setValue("verkaufer["+selectedrow_key+"]", '');                            
                        }else{
                            dateTimeUserData.push(dateTimeUser);
                        }
                    }else{
                        setValue("verkaufer["+selectedrow_key+"]", '');    
                    }
                    
                });
            }
            
        },300)
        */
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {
                setSaving(false);
                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
            }
        });
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: t(''),
            width: '20px',
            cell: row => (
                <>
                    <input
                        {...register("selectedrow[" + row.id + "]", {
                            checked: true / false,
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        //checked={getValues("selectedrow["+row.id+"]") == row.id ? true : false }
                        type="checkbox" />
                    &nbsp;
                    {/*'# '+row.id*/}
                </>),
            sortable: true,
        },
        {
            name: t('Date'),
            width: '150px',
            cell: row => (<>
                <input
                    className="form-control"
                    className="form-control"
                    {...register("date[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                date: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    defaultValue={row.date}
                    required
                    type="date" id="example-date-input" />
            </>),
        },
        {
            name: t('Anrede'),
            width: '150px',
            cell: row => <select
                className="form-control"
                {...register("anrede[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            anrede: e.target.value
                        });
                    }
                })}
                defaultValue={row.anrede}
            >
                <option value="">--</option>
                <option value="Herr">{t('Herr')}</option>
                <option value="Frau">{t('Frau')}</option>
            </select>,
        },
        {
            name: t('Vorname'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("vorname[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            vorname: e.target.value
                        });
                    }
                })}
                defaultValue={row.vorname}
                required
            />,
        },
        {
            name: t('Name'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("name[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            name: e.target.value
                        });
                    }
                })}
                defaultValue={row.name}
            />,
        },
        {
            name: t('Straße'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("street[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            street: e.target.value
                        });
                    }
                })}
                defaultValue={row.street}
                required
            />,
        },
        {
            name: t('Nr'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("nr[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            nr: e.target.value
                        });
                    }
                })}
                defaultValue={row.nr}
            />,
        },
        {
            name: t('PLZ'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("plz[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            plz: e.target.value
                        });
                    }
                })}
                defaultValue={row.plz}
                required
            />,
        },
        {
            name: t('Ort'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("ort[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ort: e.target.value
                        });
                    }
                })}
                defaultValue={row.ort}
                required
            />,
        },
        {
            name: t('Telefonnummer'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("telefonnummer[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            telefonnummer: e.target.value
                        });
                    }
                })}
                defaultValue={row.telefonnummer}
                required
            />,
        },
        {
            name: t('Time'),
            width: '150px',
            cell: row => (
                <select
                    className="form-control"
                    {...register("time[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                time: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.time}
                >
                    <option value="">--</option>
                    <option value="08:00">08:00 - 10:00</option>
                    <option value="10:00">10:00 - 12:00</option>
                    <option value="12:00">12:00 - 14:00</option>
                    <option value="14:00">14:00 - 16:00</option>
                    <option value="16:00">16:00 - 18:00</option>
                    <option value="18:00">18:00 - 20:00</option>
                </select>
            ),
        },
        {
            name: t('Verkaufer'),
            width: '250px',
            cell: row => (<>
                <select
                    className="form-control"
                    {...register("verkaufer[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                verkaufer: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.verkaufer}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>
            </>),
        },
        {
            name: t('Angebot Summe'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control"
                placeholder={t('')}
                {...register("angebot_netto[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            angebot_netto: e.target.value
                        });
                    }
                })}
                defaultValue={row.angebot_netto}
                required
            />,
        },
        {
            name: t('Auftrag Summe'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control"
                placeholder={t('')}
                {...register("auftrag_netto[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            auftrag_netto: e.target.value
                        });
                    }
                })}
                defaultValue={row.auftrag_netto}
                required
            />,
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row =>
                <select
                    className="form-control"
                    {...register("status[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                status: e.target.value
                            });
                        }
                    })}
                    defaultValue={row.status}
                    required
                >
                    <option value="">--</option>
                    <option value="1-Verkauft">{t('1-Verkauft')}</option>
                    <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                    <option value="3-Angebot">{t('3-Angebot')}</option>
                    <option value="4-Überleger">{t('4-Überleger')}</option>
                    <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                    <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                    <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                    <option value="9-Kundenseitig abgesagt">{t('9-Kundenseitig abgesagt')}</option>
                    <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
                </select>,
        },
        /*
        {
            name: t('Firma'),
            width: '150px',
            cell: row =><input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("firma["+row.id+"]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            firma: e.target.value
                        });
                    }
                })}
                defaultValue={row.firma}
                required
            />,
        },
        */
        {
            name: t('Modul Hersteller'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("modul[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            modul: e.target.value
                        });
                    }
                })}
                defaultValue={row.modul}
                required
            />,
        },
        {
            name: t('Watt-Leistung'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control"
                placeholder={t('')}
                {...register("watt[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            watt: e.target.value
                        });
                    }
                })}
                defaultValue={row.watt}
                required
            />,
        },
        {
            name: t('kWp'),
            width: '150px',
            cell: row => <input
                type="number"
                className="form-control"
                placeholder={t('')}
                {...register("kwp[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            kwp: e.target.value
                        });
                    }
                })}
                defaultValue={row.kwp}
                required
            />,
        },
        {
            name: t('Speicher'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("speicher[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            speicher: e.target.value
                        });
                    }
                })}
                defaultValue={row.speicher}
                required
            />,
        },
        {
            name: t('DC Montage Datum'),
            width: '150px',
            cell: row => <input
                type="date"
                className="form-control"
                placeholder={t('')}
                {...register("dc_montage_date[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            dc_montage_date: e.target.value
                        });
                    }
                })}
                defaultValue={row.dc_montage_date}
                required
            />,
        },
        {
            name: t('DC Techniker'),
            width: '250px',
            cell: row =>
                <select
                    className="form-control"
                    {...register("dc_technician[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                dc_technician: e.target.value
                            });
                        }
                    })}
                    required
                    defaultValue={row.dc_technician}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>,
        },
        {
            name: t('AC Montage Datum'),
            width: '150px',
            cell: row => <input
                type="date"
                className="form-control"
                placeholder={t('')}
                {...register("ac_montage_date[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ac_montage_date: e.target.value
                        });
                    }
                })}
                defaultValue={row.ac_montage_date}
                required
            />,
        },
        {
            name: t('AC Techniker'),
            width: '250px',
            cell: row =>
                <select
                    className="form-control"
                    {...register("ac_technician[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                ac_technician: e.target.value
                            });
                        }
                    })}
                    required
                    defaultValue={row.ac_technician}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>,
        },
    ];

    useEffect(() => {
        if (!props.filename.length) return;

        if (props?.filename) {
            verkauferFeatch();
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);

                validateDateTimeVerkaufer();
                // console.log(res.data);
            });
        }

    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />


                {/**/}
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>

                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>

                <div className='text-center'>
                    <button className='btn btn-danger mx-1' onClick={() => {
                        document.getElementById('outputModal-button-close').click();
                    }}>{t('Cancel')}</button>

                    {loading == false ? (
                        <>
                            {!saving && (
                                <button type="submit" className="btn btn-primary">
                                    {t(props.type + ' upload')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled" disabled>
                                    {t(props.type + ' upload ...')}
                                </button>
                            )}
                        </>
                    ) : (
                        <button type="submit" className="btn btn-disabled" disabled>
                            {t('Loading ...')}
                        </button>
                    )}

                </div>
            </>
        );
    };

    return <>
        <div className='px-3'>
            <div className="row">
                <div className="col-sm-1">
                    <input type="checkbox" className='mt-2' id='select_all' onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }} />
                    <label htmlFor="select_all">{t('Select All')}</label>
                </div>
                <div className="col-sm-3">
                    {loading == false ? (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            onChange={(e) => {
                                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                    if (selectedrow_value) {
                                        setValue("date[" + selectedrow_key + "]", e.target.value);
                                        updateFileData({
                                            id: selectedrow_key,
                                            date: e.target.value
                                        });
                                    }
                                }
                            }}
                        />
                    ) : (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            disabled
                        />
                    )}

                </div>
                <div className="col-sm-8">
                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Deleting ...')}</button>

                            ) : (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Delete')}</button>
                    )}
                </div>
            </div>

        </div>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
        </form>
    </>
}

export default IndexTableProjectWithSalses;
