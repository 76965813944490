import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import { getValue } from "@mui/system";
import SoftSnackbar from "components/SoftSnackbar";
function FileUpload(props) { 
    console.log(props);
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null); 
    const [file, setFile] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => closeSuccessSB(false)}
            close={() => closeSuccessSB(false)}
            bgSuccess
        />
    );

    const [errorSB, setErrorSB] = useState(false);
    const closeErrorSB = () => setErrorSB(false);
    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="error"
            title={t('The file must not be greater than 5 MB.')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgDanger
        />
    );
    const handleFileChange = (e) => {
            setLoading(true);
            const file = e.target.files[0];
            if (file) {
                setSelectedFile(file);
                setFile(false);
            }
    }

    const handleFileRemove = () => {
        setValue("file", "")
        setSelectedFile(null)
    }

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
         setFile(props?.file);
    }, [props?.file]);

    const onSubmit = (formData) => {
        setSaving(true); 
        formData.file = selectedFile;
        formData.id = props.id; 
        callFetch("wechselrichter-file-upload", "POST", formData, setError).then((res) => {
            setSaving(false);
            setLoading(false);
            if(res && res.errors && res.errors.file){
                setErrorSB(true);
                setSelectedFile(null);
                setFile(props?.file);
            } 
            if (!res.ok) return;
            setSubmitSuccess(true);
            // setSuccessSB(true);
        });
    };

    return <>
                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <div className="card">
                        <div className="card-header">
                            <label>{t('Datenblätter etc.')} *</label>
                            <input
                                type="file"
                                id="upload"
                                className="form-control"
                                placeholder={t('')}
                                {...register("file")}
                                onChange={(e) => {handleFileChange(e); setTimeout(() => {document.getElementById('submitForm').click()}, 2000)}}
                            />

<                       button type="submit" id="submitForm" className="btn btn-primary d-none">
                            {t('Save')}
                        </button>
                        </div>
                        <div className="card-body pt-0 position-relative">
                            {loading ? <div className="loading-file-upload-wrapper">
                                {true ?  <div><i className="fa fa-circle-o-notch fa-spin loading-file-upload-loader"></i> <span> Uploading...</span> </div> : ''}
                            </div> : ''}
                            {file == true ? props?.data?.data?.file?.endsWith('.pdf') ? <iframe
                                // src={`https://docs.google.com/gview?url=${process.env.REACT_APP_BACKEND_URL + 'storage/module/' + data.data.file}&embedded=true`}
                                src={process.env.REACT_APP_BACKEND_URL + 'storage/wechselrichter/' + props?.data?.data?.file}
                                title="PDF Viewer"
                                width="100%"
                                height="350px" // Adjust the height as needed
                            /> : <img
                            src={process.env.REACT_APP_BACKEND_URL + 'storage/wechselrichter/' + props?.data?.data?.file}
                            alt="Selected"
                            width="100%"
                            height="100%"
                            className="border-radius-md w-100 h-100"
                        />  : '' }
                            {selectedFile?.type.startsWith('image/') ? (
                                // Display the image
                                <>
                                    <img
                                        src={selectedFile && URL.createObjectURL(selectedFile)}
                                        alt="Selected"
                                        width="100%"
                                        height="100%"
                                        className="border-radius-md w-100 h-100"
                                    />
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : selectedFile?.type.startsWith('application/') ? (
                                // Display a link to download the file
                                <>
                                    <iframe
                                        src={URL.createObjectURL(selectedFile)}
                                        title="PDF Viewer"
                                        width="100%"
                                        height="350px" // Adjust the height as needed
                                        className="border-radius-md"
                                    ></iframe>
                                    &nbsp;
                                    <i style={{ top: "-5px" }} className="fa-solid fa-circle-xmark text-danger cursor-pointer position-absolute end-4" onClick={handleFileRemove}></i>
                                </>
                            ) : <>

                            </>
                            }
                        </div>
                    </div>
                </form>
                {renderErrorSB}
                {renderSuccessSB}
                </>
}

export default FileUpload;