import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams, NavLink } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

// @mui core components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import EmailForm from "./EmailForm";
import Timeline from "./Timeline";
import Select, { components } from 'react-select';

import ProjectDistribution from './Distribution/Index';
import Overview from '../Overview';
import SoftSnackbar from "components/SoftSnackbar";
import Cookies from 'js-cookie';
import { NumericFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';
import DropZoneTabs from 'components/DropZoneTabs/Index';
import { item } from "examples/Sidenav/styles/sidenavItem";

function Distribution(props) {

    let params = useParams();
    const { t } = useTranslation();
    const [modules, setModules] = useState([]);
    const [speichers, setSpeichers] = useState([]);
    const [wechselrichters, setWechselrichters] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [productItemsRefresh, setproductItemsRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [data, setData] = useState(false);
    const [zusatzvereinbarungen, setZusatzvereinbarungen] = useState(null);
    const [meter_location_floor, setMeter_location_floor] = useState(null);
    const [house_connection_location, setHouse_connection_location] = useState(null);
    const [storage_wrspace, setStorage_wrspace] = useState(null);
    const [show, setShow] = useState(0);
    const [radio, setRadio] = useState(true)
    const [speicherEingeben, setSpeicherEingeben] = useState('no');
    const [moduleEingeben, setModuleEingeben] = useState('no');
    const [wechselrichterEingeben, setWechselrichter] = useState('no');
    const [customEmail, setCustomEmail] = useState('');
    const [readOnly, setReadOnly] = useState(true);
    const [selectedQuationId, setSelectedQuationId] = useState('');
    const [quationRefresh, setQuationRefresh] = useState(0);
    const [selectedproduct, setSelectedproduct] = useState([]);
    const [create, setCreate] = useState(false);
    const [resaleAngebot, setResaleAngebot] = useState(false);
    const [newQuation, setNewQuation] = useState(false);
    const [bundles, setBundles] = useState([]);
    const [meter_numbers, setMeter_number] = useState(['']);
    const [mitarbeiterkennung, setMitarbeiterkennung] = useState(false);
    const [productItems, setProductItems] = useState({
        ust_positionen: 0,
        ust_positionen_amount: 0,
        subTotal: 0,
        gesami_netto: 0,
        items: [{
            id: 0,
            type: 'bundle',
            product_type: '',
            quantity: 1,
            price: 0,
            sales_person_price: 0,
            tax: 0,
            discount: 0,
            totalDiscount: 0,
            total: 0,
            lieferanten_id: 0,
            item: []
        }]
    });

    const [reactSelectStyle, setReactSelectStyle] = useState({
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: '32px',
            //height: '32px',
            fontSize: '0.75rem',
            padding: '0rem 0rem',
            borderRadius: '0.5rem',
        }),
    })

    const [dropZoneTabs, setDropZoneTabs] = useState([
        {
            name: 'dachansicht',
            titel: 'Hausansicht Front',
            require: true
        },
        {
            name: 'dachsparren',
            titel: 'Hausansicht Front 2',
            require: true
        },
        {
            name: 'dachgiebel',
            titel: 'Hausansicht Taufhöhe',
            require: true
        },
        {
            name: 'gesamtansicht_von_haus_und_dach',
            titel: 'Hausansicht Rückseite',
            require: true
        },
        {
            name: 'innendachansicht',
            titel: 'Innendachansicht',
            require: true
        },
        {
            name: 'kompletter_zahlerschrank',
            titel: 'Kompletter Zählerschrank',
            require: true
        },
        {
            name: 'zahler',
            titel: 'Zähler',
            require: true
        },
        {
            name: 'wechselrichter_standort',
            titel: 'Wechselrichter-Standort',
            require: true
        },
        {
            name: 'bei_nachust',
            titel: 'Hausanschlusskasten',
            require: true
        },
        {
            name: 'ziegel_mit_meterstab',
            titel: 'Ziegel mit Meterstab',
            require: true
        },
    ]);
    const [tabFiles, setTabFiles] = useState({});
    const [dropZonValidationError, setDropZonValidationError] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const setCallBackData = (data) => {
        setTabFiles(data);

        setDropZonValidationError('');
        //if (JSON.parse(Cookies.get('permissions')).indexOf("qINReq") === -1) {
        if (false) {
            for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
                if (dropZoneTab?.require === true && (!data[dropZoneTab?.name] || data[dropZoneTab?.name]?.length <= 0)) {
                    setDropZonValidationError(dropZoneTab?.titel + ' is required')
                    return;
                }
            }
        }
    }

    const CustomOption = ({ innerProps, option, itemIndex }) => {

        // console.log('option')
        //console.log(option)
        // console.log(productItems?.items[itemIndex]?.product_type)

        return (
            <>
                {productItems?.items[itemIndex]?.product_type ? (
                    <>
                        {productItems?.items[itemIndex]?.product_type === option?.type ? (
                            <li {...innerProps} className={"custom-option " + (productItems?.items[itemIndex]?.item?.value == option?.value ? 'active' : '')}>
                                {option?.label}
                            </li>
                        ) : ''}
                    </>
                ) : (
                    <>
                        <li {...innerProps} className={"custom-option " + (productItems?.items[itemIndex]?.item?.value == option?.value ? 'active' : '')}>
                            {productItems?.items[itemIndex]?.type == 'bundle' ? <strong>{option?.label}</strong> : option?.label}

                            {productItems?.items[itemIndex]?.type == 'bundle' && option.id ? (
                                <div>
                                    {option?.items ? option?.items.map((item, item_index) => (
                                        <>
                                            <p
                                                style={{
                                                    fontSize: '0.65rem',
                                                    fontWeight: 'normal',
                                                }}
                                                className="m-0 p-0"
                                            >{item[item?.type]?.name + ' - ' + item?.number + ' Stück'}</p>
                                        </>
                                    )) : ''}
                                </div>
                            ) : ''}
                        </li>
                    </>
                )}

            </>
        )
    };


    const countOccurrences = (obj, key, value) => {
        let count = 0;
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (prop === key && obj[prop] === value) {
                    count++;
                } else if (typeof obj[prop] === 'object') {
                    count += countOccurrences(obj[prop], key, value);
                }
            }
        }
        return count;
    }

    const searchKeyValue = (obj, key, value) => {
        let count = 0;
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {

                // //console.log(obj[prop])
                // if (obj[prop]?.type === 'speicher') {
                //     if (obj[prop]?.speicher?.spei_type && value == obj[prop]?.speicher?.spei_type) {
                //         console.log(obj[prop]?.speicher?.spei_type);
                //     }
                // }

                if (prop === key && obj[prop] === value) {
                    count++;
                } else if (typeof obj[prop] === 'object') {
                    count += countOccurrences(obj[prop], key, value);
                }
            }
        }
        return count;
    }

    const searchSpeicherTypeValue = (obj, value) => {
        let count = 0;
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (obj[prop]?.type === 'speicher') {
                    if (obj[prop]?.speicher?.speicher_type?.id && value == obj[prop]?.speicher?.speicher_type?.id) {
                        //console.log(obj[prop]?.speicher?.speicher_type?.id);
                        count++;
                    }
                }
            }
        }
        return count;
    }

    const getWattsFromBundles = (lieferanten_id, speicher_type) => {
        var wattsss = [];
        for (let [key, bundle] of Object.entries(bundles)) {
            for (let [option_key, option] of Object.entries(bundle?.options)) {
                for (let [option_item_key, option_item] of Object.entries(option?.items)) {
                    if (bundle?.id == lieferanten_id && option_item?.speicher?.speicher_type?.id == speicher_type && option_item?.type === 'speicher' && option_item?.speicher?.watt && wattsss.indexOf(option_item?.speicher?.watt) == -1) {
                        wattsss.push(option_item?.speicher?.watt);
                    }
                }
            }
        }
        return wattsss;
    }

    const getSpeicherTypesFromBundles = (lieferanten_id) => {
        var speicherTypes = [];
        for (let [key, bundle] of Object.entries(bundles)) {
            for (let [option_key, option] of Object.entries(bundle?.options)) {
                for (let [option_item_key, option_item] of Object.entries(option?.items)) {

                    var alreadyAdd = countOccurrences(speicherTypes, 'id', option_item?.speicher?.speicher_type?.id);


                    if (bundle?.id == lieferanten_id && option_item?.type === 'speicher' && option_item?.speicher?.speicher_type?.name && alreadyAdd == 0) {
                        speicherTypes.push({
                            id: option_item?.speicher?.speicher_type?.id,
                            name: option_item?.speicher?.speicher_type?.name,
                        });
                    }
                }
            }
        }
        return speicherTypes;
    }

    const zusatzproduktLiferentCount = (obj, key, value) => {
        let count = 0;
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (obj['product_from'] === 'zusatzprodukte' && prop === key && obj[prop] === value) {
                    count++;
                } else if (typeof obj[prop] === 'object') {
                    count += zusatzproduktLiferentCount(obj[prop], key, value);
                }
            }
        }
        return count;
    }

    const CustomGroupLabel = ({ innerProps, group, itemIndex }) => {

        if (productItems?.items[itemIndex]?.product_form === 'zusatzprodukte') {
            if (productItems.items[itemIndex].product_type) {
                var totalOptions = zusatzproduktLiferentCount(group?.options, 'type', productItems.items[itemIndex].product_type);
            } else {
                var totalOptions = countOccurrences(group?.options, 'product_from', productItems.items[itemIndex].product_form);
            }
        } else {
            var totalOptions = countOccurrences(group?.options, 'product_from', productItems.items[itemIndex].product_form);
        }

        return (
            <>
                {(productItems?.items[itemIndex].type == 'bundle' && group?.options.length) || (productItems?.items[itemIndex].type != 'bundle' && totalOptions > 0) ? (
                    <li
                        class={"nav-item nav-link py-1 " + (group?.id === (productItems?.items[itemIndex].lieferanten_id ? productItems?.items[itemIndex].lieferanten_id : (productItems?.items[itemIndex].type == 'bundle' ? bundles[0]?.id : products[0]?.id)) ? 'active' : '')}
                        id={generateId(group?.label) + "-tab"}
                        data-bs-target={"#" + generateId(group?.label)}
                        onClick={() => {
                            var update = productItems;
                            update.items[itemIndex].lieferanten_id = group?.id;
                            //update.items[itemIndex].searchWatt = '';

                            if (productItems?.items[itemIndex].type == 'bundle') {
                                update.items[itemIndex].searchWatt = '';
                                update.items[itemIndex].searchType = '';

                                update.items[itemIndex].price = 0;
                                update.items[itemIndex].item = [];
                            }

                            setProductItems(update);
                            setproductItemsRefresh(productItemsRefresh + 1);
                        }}
                        data-bs-toggle="tab"
                        type="button"
                        role="tab"
                        aria-controls={generateId(group?.label)}
                        aria-selected="true"
                    >
                        {group?.label}
                    </li>
                ) : ''}
            </>
        )
    };

    const generateId = (text) => {
        var str = text ? text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-').replace(/\s+/g, '') : Math.floor((Math.random() + 1) * 900000);

        return str;
    }

    const withOrWithoutSpeicher = (with_speicher = 'all', option) => {

        var data = true;

        if (with_speicher === 'all') {
            var data = true;
        } else if (with_speicher === true) {

            var searchSpeicher = searchKeyValue(option.items, 'type', "speicher");

            if (searchSpeicher > 0) {
                var data = true;
            } else {
                var data = false;
            }

            //console.log('searchSpeicher')
            //console.log(searchSpeicher)

        } else if (with_speicher === false) {
            var searchSpeicher = searchKeyValue(option.items, 'type', "speicher");

            if (searchSpeicher <= 0) {
                var data = true;
            } else {
                var data = false;
            }
        }


        return data;
    }

    const withWattSearch = (watt = '', option) => {
        var data = false;
        if (watt === '') {
            var data = true;
        } else if (watt > 0) {
            var searchCount = searchKeyValue(option.items, 'watt', watt);
            if (searchCount > 0) {
                var data = true;
            } else {
                var data = false;
            }
        }
        return data;
    }

    const withTypeSearch = (type = '', option) => {

        //console.log(option.items)



        var data = false;
        if (type === '') {
            var data = true;
        } else if (type > 0) {
            var searchCount = searchSpeicherTypeValue(option.items, type);

            //var searchSpeicher = searchKeyValue(option.items, 'type', "speicher");

            //if(searchSpeicher > 0){
            //console.log(option?.items)
            //}

            //console.log(type)
            //console.log('searchCount')
            // console.log(searchCount)

            if (searchCount > 0) {

                //console.log(option?.items)
                var data = true;
            } else {
                var data = false;
            }
        }
        return data;
    }

    const CustomMenu = (props) => {

        const itemIndex = props.selectProps.itemIndex;

        return (
            <components.Menu {...props} style={{ width: '700px' }}>
                <div
                    style={{
                        //minWidth: '700px',
                        //width: '500px',
                        //border: 'none'
                        //overflow: 'auto'
                    }}
                    className="react-select-custom-group-dropdown"
                >
                    <div className="row m-0">



                        {productItems.items[itemIndex].type === 'product' ? (
                            <div
                                className={productItems?.items[itemIndex]?.product_form === 'zusatzprodukte' ? 'col-3 p-0' : "col-4 p-0"}
                                //className={'col p-0'}
                                style={{
                                    borderRight: '1px solid #c5c5c5'
                                }}
                            >
                                <h6 className="m-0 option-header">Produktkategorie</h6>
                                <ul class="nav nav-tabs d-block">
                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.product_form == 'zusatzprodukte' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_form = 'zusatzprodukte';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >Zusatzprodukte</li>

                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.product_form == 'module' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_form = 'module';
                                            update.items[itemIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >Module</li>

                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.product_form == 'speicher' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_form = 'speicher';
                                            update.items[itemIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >Speicher</li>

                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems.items[itemIndex]?.product_form == 'wechselrichter' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_form = 'wechselrichter';
                                            update.items[itemIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >Wechselrichter</li>
                                </ul>
                            </div>
                        ) : ''}

                        {productItems?.items[itemIndex]?.product_form === 'zusatzprodukte' ? (
                            <div
                                className={productItems?.items[itemIndex]?.product_form === 'zusatzprodukte' ? 'col-3 p-0' : "col-4 p-0"}
                                //className={'col p-0'}
                                style={{
                                    borderRight: '1px solid #c5c5c5'
                                }}
                            >
                                <h6 className="m-0 option-header">Kategorie</h6>

                                <ul class="nav nav-tabs d-block">
                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems.items[itemIndex].product_type == '' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_type = '';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >Alle</li>
                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems.items[itemIndex].product_type === 'ac' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_type = 'ac';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >AC Produkt</li>
                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems.items[itemIndex].product_type === 'dc' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_type = 'dc';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >DC Produkt</li>
                                    <li
                                        className={"nav-item nav-link py-1 " + (productItems.items[itemIndex].product_type === 'Dienstleistung' ? 'active' : '')}
                                        onClick={() => {
                                            //product_type
                                            var update = productItems;
                                            update.items[itemIndex].product_type = 'Dienstleistung';
                                            setProductItems(update);
                                            setproductItemsRefresh(productItemsRefresh + 1);
                                        }}
                                    >Dienstleistung</li>
                                </ul>

                            </div>
                        ) : ''}

                        <div
                            className={productItems?.items[itemIndex]?.product_form === 'zusatzprodukte' ? 'col-3 p-0' : "col p-0"}
                            //className={'col p-0'}
                            style={{
                                borderRight: '1px solid #c5c5c5'
                            }}
                        >
                            <h6 className="m-0 option-header">Hersteller</h6>
                            <ul class="nav nav-tabs d-block" role="tablist">
                                {props?.options.map((group) => (
                                    <CustomGroupLabel
                                        key={group.label}
                                        group={group}
                                        itemIndex={itemIndex}
                                    />
                                ))}
                            </ul>
                        </div>


                        {productItems.items[itemIndex].type === 'bundle' ? (
                            <>
                                {productItems?.items[itemIndex].lieferanten_id ? (
                                    <div
                                        className={'col p-0'}
                                        style={{
                                            borderRight: '1px solid #c5c5c5'
                                        }}
                                    >
                                        <h6 className="m-0 option-header">Speicher vorh.</h6>
                                        <ul class="nav nav-tabs d-block">
                                            <li
                                                className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.with_speicher === 'all' ? 'active' : '')}
                                                onClick={() => {
                                                    //product_type
                                                    var update = productItems;
                                                    update.items[itemIndex].with_speicher = 'all';
                                                    setProductItems(update);
                                                    setproductItemsRefresh(productItemsRefresh + 1);
                                                }}
                                            >{t('All')}</li>
                                            <li
                                                className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.with_speicher === true ? 'active' : '')}
                                                onClick={() => {
                                                    //product_type
                                                    var update = productItems;
                                                    update.items[itemIndex].with_speicher = true;

                                                    setProductItems(update);
                                                    setproductItemsRefresh(productItemsRefresh + 1);
                                                }}
                                            >{t('With Speicher')}</li>
                                            <li
                                                className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.with_speicher === false ? 'active' : '')}
                                                onClick={() => {
                                                    //product_type
                                                    var update = productItems;
                                                    update.items[itemIndex].with_speicher = false;
                                                    update.items[itemIndex].searchWatt = '';
                                                    update.items[itemIndex].searchType = '';

                                                    update.items[itemIndex].price = 0;
                                                    update.items[itemIndex].item = [];


                                                    setProductItems(update);
                                                    setproductItemsRefresh(productItemsRefresh + 1);
                                                }}
                                            >{t('Without Speicher')}</li>
                                        </ul>
                                    </div>
                                ) : ''}

                                {productItems?.items[itemIndex]?.with_speicher ? (
                                    <>

                                        {productItems?.items[itemIndex].lieferanten_id ? (
                                            <div
                                                className={'col p-0'}
                                                style={{
                                                    borderRight: '1px solid #c5c5c5'
                                                }}
                                            >
                                                <h6 className="m-0 option-header">Speicher Typ</h6>
                                                <ul class="nav nav-tabs d-block">
                                                    {/*
                                                    <li
                                                        className={"nav-item nav-link py-1 " + (!productItems?.items[itemIndex]?.searchType || productItems?.items[itemIndex]?.searchType === '' ? 'active' : '')}
                                                        onClick={() => {
                                                            //product_type
                                                            var update = productItems;
                                                            update.items[itemIndex].searchType = '';
                                                            setProductItems(update);
                                                            setproductItemsRefresh(productItemsRefresh + 1);
                                                        }}
                                                    >{t('All')}</li>
                                                    */}

                                                    {getSpeicherTypesFromBundles(productItems?.items[itemIndex].lieferanten_id).map((bundle_speicher_types) => (
                                                        <li
                                                            className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.searchType == bundle_speicher_types?.id ? 'active' : '')}
                                                            onClick={() => {
                                                                var update = productItems;
                                                                update.items[itemIndex].searchType = bundle_speicher_types?.id;
                                                                update.items[itemIndex].searchWatt = '';

                                                                update.items[itemIndex].price = 0;
                                                                update.items[itemIndex].item = [];

                                                                setProductItems(update);
                                                                setproductItemsRefresh(productItemsRefresh + 1);
                                                            }}
                                                        >{t(bundle_speicher_types?.name)}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : ''}


                                        {productItems?.items[itemIndex]?.searchType ? (
                                            <div
                                                className={'col p-0'}
                                                style={{
                                                    borderRight: '1px solid #c5c5c5'
                                                }}
                                            >
                                                <h6 className="m-0 option-header">Speicher kWh</h6>
                                                <ul class="nav nav-tabs d-block">
                                                    {/*
                                                    <li
                                                        className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.searchWatt === '' ? 'active' : '')}
                                                        onClick={() => {
                                                            var update = productItems;
                                                            update.items[itemIndex].searchWatt = '';
                                                            setProductItems(update);
                                                            setproductItemsRefresh(productItemsRefresh + 1);
                                                        }}
                                                    >{t('All')}</li>
                                                    */}

                                                    {getWattsFromBundles(productItems?.items[itemIndex].lieferanten_id, productItems?.items[itemIndex]?.searchType).map((bundle_watt) => (
                                                        <li
                                                            className={"nav-item nav-link py-1 " + (productItems?.items[itemIndex]?.searchWatt == bundle_watt ? 'active' : '')}
                                                            onClick={() => {
                                                                var update = productItems;
                                                                update.items[itemIndex].searchWatt = bundle_watt;

                                                                update.items[itemIndex].price = 0;
                                                                update.items[itemIndex].item = [];

                                                                setProductItems(update);
                                                                setproductItemsRefresh(productItemsRefresh + 1);
                                                            }}
                                                        >{t(bundle_watt + ' Watt')}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : ''}

                                    </>
                                ) : ''}
                            </>
                        ) : ''}

                        {(productItems.items[itemIndex].type === 'bundle' ? ((productItems?.items[itemIndex].lieferanten_id && productItems?.items[itemIndex]?.with_speicher === false) || (productItems?.items[itemIndex].lieferanten_id && productItems?.items[itemIndex].searchWatt)) : productItems?.items[itemIndex].lieferanten_id) ? (
                            <div
                                //className={productItems.items[itemIndex].type === 'bundle' && productItems?.items[itemIndex]?.with_speicher === false ? 'col-6' : productItems?.items[itemIndex]?.product_form === 'zusatzprodukte' || productItems.items[itemIndex].type === 'bundle' ? 'col-3' : productItems.items[itemIndex].type === 'product' ? "col-4" : 'col-8'} 
                                className={'col p-0'}
                            >
                                <h6 className="m-0 option-header">{productItems.items[itemIndex].type === 'bundle' ? 'Bundle' : 'Produkt'}</h6>
                                <div class="tab-content">

                                    {props?.options.map((group) => (
                                        <div class={"tab-pane fade show " + (group?.id === (
                                            productItems?.items[itemIndex].lieferanten_id ? productItems?.items[itemIndex].lieferanten_id
                                                : (productItems?.items[itemIndex].type == 'bundle' ? bundles[0]?.id
                                                    : products[0]?.id
                                                )
                                        ) ? 'active'
                                            : ''
                                        )} id={generateId(group.label)} role="tabpanel" aria-labelledby={generateId(group.label) + "-tab"}>
                                            <ul className="custom-options">
                                                {props?.options.find((group) => group?.id === (
                                                    productItems?.items[itemIndex].lieferanten_id ? productItems?.items[itemIndex].lieferanten_id
                                                        : (productItems?.items[itemIndex].type == 'bundle' ? bundles[0]?.id
                                                            : products[0]?.id
                                                        )
                                                ))?.options.map((option) => (
                                                    <>
                                                        {(productItems?.items[itemIndex].type == 'bundle' ? (withTypeSearch(productItems?.items[itemIndex]?.searchType, option) && withWattSearch(productItems?.items[itemIndex]?.searchWatt, option) && withOrWithoutSpeicher(productItems?.items[itemIndex]?.with_speicher, option)) : true) && option?.product_from === productItems?.items[itemIndex]?.product_form ? (
                                                            <CustomOption
                                                                key={option.value}
                                                                innerProps={{
                                                                    onClick: () => {
                                                                        var update = productItems;
                                                                        update.items[itemIndex].price = (option?.price ? option?.price : 0);
                                                                        update.items[itemIndex].item = option;

                                                                        //console.log(option)

                                                                        setProductItems(update);
                                                                        setproductItemsRefresh(productItemsRefresh + 1);

                                                                        //console.log('Mitarbeiterkennung open')
                                                                        setMitarbeiterkennung(true);



                                                                        type LoginFormResult = {
                                                                            mitarbeiterkennung: string
                                                                        }

                                                                        let mitarbeiterkennungInput: HTMLInputElement
                                                                        let show_hide_mitarbeiterkennung: HTMLDivElement

                                                                        //Sales person price
                                                                        const swalWithBootstrapButtons = Swal.mixin({
                                                                            customClass: {
                                                                                confirmButton: 'btn btn-primary me-2',
                                                                                cancelButton: 'btn btn-danger',
                                                                                input: "form-control form-control-sm mt-4",
                                                                            },
                                                                            buttonsStyling: false
                                                                        });

                                                                        return swalWithBootstrapButtons.fire({
                                                                            title: t("Mitarbeiterkennung"),
                                                                            html: `
                                                                    <div class="input-group">
                                                                        <input type="password" id="mitarbeiterkennung" class="form-control" pattern="[0-9]*" inputmode="numeric">
                                                                        <div class="input-group-append">
                                                                            <span id="show_hide_mitarbeiterkennung" style="left: -35px; top: 10px; position: relative; z-index: 5; cursor: pointer;">
                                                                                <i class="fa fa-eye-slash " aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                `,
                                                                            showCancelButton: true,
                                                                            confirmButtonText: t('Save'),
                                                                            cancelButtonText: t('Cancel'),
                                                                            focusConfirm: false,
                                                                            didOpen: () => {
                                                                                const popup = Swal.getPopup();
                                                                                mitarbeiterkennungInput = popup.querySelector('#mitarbeiterkennung')
                                                                                show_hide_mitarbeiterkennung = popup.querySelector('#show_hide_mitarbeiterkennung')
                                                                                mitarbeiterkennungInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm()
                                                                                show_hide_mitarbeiterkennung.addEventListener("click", () => {

                                                                                    if (mitarbeiterkennungInput.getAttribute('type') == 'password') {
                                                                                        mitarbeiterkennungInput.setAttribute('type', 'number')
                                                                                    } else {
                                                                                        mitarbeiterkennungInput.setAttribute('type', 'password')
                                                                                    }
                                                                                });
                                                                            },
                                                                            preConfirm: () => {
                                                                                const mitarbeiterkennung = mitarbeiterkennungInput.value
                                                                                if (!mitarbeiterkennung || !/^[0-9]+$/.test(mitarbeiterkennung)) {
                                                                                    Swal.showValidationMessage(`gültige Kennung eingeben`)
                                                                                } else {
                                                                                    var update = productItems;
                                                                                    update.items[itemIndex].price = Number(update.items[itemIndex].price) + Number(mitarbeiterkennung ? mitarbeiterkennung : 0);
                                                                                    update.items[itemIndex].sales_person_price = Number(mitarbeiterkennung);
                                                                                    setProductItems(update);
                                                                                    setproductItemsRefresh(productItemsRefresh + 4);
                                                                                }
                                                                                return { mitarbeiterkennung }
                                                                            },
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {

                                                                            }

                                                                            //console.log('Mitarbeiterkennung close')
                                                                            setMitarbeiterkennung(false);
                                                                        });
                                                                    }
                                                                }}
                                                                label={option.label}
                                                                option={option}
                                                                itemIndex={itemIndex}
                                                            />
                                                        ) : ''}
                                                    </>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </components.Menu>
        )
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <i class="fa-solid fa-chevron-down"></i>
            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        if (radio && document.getElementById('nav-home-tab')) {
            document.getElementById('nav-home-tab').click()
        }
    }, [radio])


    useEffect(() => {

        var update = productItems;
        var totalPrice = 0;
        for (let [key, item] of Object.entries(productItems?.items)) {
            var total = (Number(item.quantity) * Number(item.price)); //without tax, discount

            var discount = ((Number(item.discount) / 100) * Number(total)).toFixed(2);
            var total = (Number(total) - Number(discount)); //without discount
            var tax = ((Number(item.tax) / 100) * Number(total)).toFixed(2);
            var total = (Number(total) + Number(tax)); //after tax, discount

            totalPrice += Number(total);
            update.items[key].totalDiscount = discount;
            update.items[key].totalTax = tax;
            update.items[key].total = total;
        }

        update.subTotal = totalPrice;
        update.gesami_netto = totalPrice;

        setProductItems(update);
        setRefresh3(refresh3 + 1);

        calCulateAnlagenProduktion();

    }, [productItemsRefresh]);

    useEffect(() => {

        if (JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1) {
            setReadOnly(false);
        } else {
            setReadOnly(true);
        }
        setValue('project_id', (props?.projectid ? props?.projectid : params.id));

        callFetch("products/all", "GET", []).then((res) => {
            setBundles(res?.data?.bundles);
            setProducts(res?.data?.products);
            setModules(res?.data?.modules);
            console.log(res?.data?.modules);
            setWechselrichters(res?.data?.wechselrichters);
            setSpeichers(res?.data?.speichers);
            setAllProducts(res?.data?.allProducts);
            setEditDelay(editDelay + 1);
        });
    }, [refresh]);

    useEffect(() => {
        if (editDelay > 0) {
            var perameters = "?project_id=" + (props?.projectid ? props?.projectid : params.id);
            if (selectedQuationId) {
                perameters += '&quationId=' + selectedQuationId;
            }

            callFetch("projectdistribution/" + (props?.projectid ? props?.projectid : params.id) + "/edit" + perameters, "GET", []).then((res) => {

                var resData = res?.data;
                if (!resData?.quations) {
                    resData.quations = {};
                }
                setData(resData);

                //console.log(res?.data);
                setValue('status', res?.data?.status);
                setValue('project_id', res?.data?.id);
                setValue('email', (res?.data?.email && res?.data?.email != 'null' ? res?.data?.email : 'service@bayerische-landeswerke.de'));
                //get data for Update quation
                if (res?.data?.quations && res?.data?.quations?.id) {
                    setCreate(false);
                    setSelectedQuationId(res?.data?.quations?.id);
                    setSpeicherEingeben(res?.data?.quations?.speicher_eingeben);
                    setWechselrichter(res?.data?.quations?.wechselrichter_eingeben);
                    setModuleEingeben(res?.data?.quations?.module_eingeben);
                    setCustomEmail(res?.data?.quations?.email);

                    if (res?.data?.quations?.meter_number) {
                        var metNumbers = res?.data?.quations?.meter_number;
                        setMeter_number(metNumbers.split(','));
                    }

                    if (res?.data?.quations?.productItems) {
                        setProductItems(JSON.parse(res?.data?.quations?.productItems));
                    }

                    if (res?.data?.quations?.newQuation) {
                        setNewQuation((res?.data?.quations?.newQuation === 'no' ? false : true));
                    }

                    for (let [key, value] of Object.entries(res?.data?.quations)) {
                        if (key == 'filesdata') {
                            if (value) {
                                setTabFiles(JSON.parse(value));
                            }
                        } else if (key == 'netto_brotto') {
                            setShow(Number(value));
                        } else if (key == 'module_description') {
                            if (value != "undefined") {
                                for (let [description_key, description_value] of Object.entries(JSON.parse(value))) {
                                    setValue('module_description[' + description_key + ']', description_value);
                                }
                            }
                        } else if (key == 'angebotsdatum') {
                            var dateValue = new Date(res?.data?.quations?.created_at); // Remove extra zeros
                            var formattedDate = dateValue.toISOString().slice(0, 10);
                            setValue('angebotsdatum', (value == null || value == 'null' ? formattedDate : value));
                        } else if (key == 'products') {

                            var slectProduct = [];
                            var productIds = [];
                            for (let [key, PVal] of Object.entries(value)) {
                                //console.log(PVal?.product)
                                slectProduct.push(PVal?.product);
                                productIds.push(PVal?.product?.id);
                            }
                            setSelectedproduct(slectProduct);
                            setValue('products', JSON.stringify(productIds));
                        } else if (key != 'user') {
                            setValue(key, (value == 'null' ? '' : value));
                        }
                    }
                    setZusatzvereinbarungen(getValues('zusatzvereinbarungen') ? JSON.parse(getValues('zusatzvereinbarungen')) : null);

                    setMeter_location_floor(getValues('meter_location_floor') ? JSON.parse(getValues('meter_location_floor')) : null);
                    setStorage_wrspace(getValues('storage_wrspace') ? JSON.parse(getValues('storage_wrspace')) : null);
                    setHouse_connection_location(getValues('house_connection_location') ? JSON.parse(getValues('house_connection_location')) : null);

                } else {
                    setCreate(true);
                    setNewQuation(true);
                    setSelectedQuationId('');
                    //setQuationRefresh(quationRefresh + 1);
                    //get data for create quation

                    setValue('speicher_eingeben', speicherEingeben);
                    setValue('wechselrichter_eingeben', wechselrichterEingeben);
                    setValue('module_eingeben', moduleEingeben);

                    setValue('wirtschaftlichkeitsberechnung', 'Nein');
                    setValue('angebot_per_post_senden', 'Nein');

                    //By default Email Address
                    //setValue('email', 'service@bayerische-landeswerke.de');
                    setCustomEmail('service@bayerische-landeswerke.de');

                    //By default Netto
                    setShow(1);
                    setValue('netto_brotto', 1)


                    for (let [key, value] of Object.entries(res.data)) {
                        if (['user', 'quations', 'id', 'user_id'].indexOf(key) == -1) {
                            if (key == 'nr') {
                                setValue('hausnummer', (value == null || value == 'null' ? "" : value));
                            } else if (key == 'ziegelart') {
                                setValue('dacheindeckung', (value == null || value == 'null' ? "" : value));
                            }
                            else {
                                setValue(key, (value == null || value == 'null' ? "" : value));
                            }
                        }
                    }
                }
            });
        }
    }, [editDelay, quationRefresh]);

    useEffect(() => {
        setRefresh2(refresh2 + props.refresh);
    }, [props.refresh]);

    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const closeErrorSB = () => setErrorSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => closeSuccessSB(false)}
            close={() => closeSuccessSB(false)}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="info"
            icon="info"
            title={t('Create the quation first')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const addNewAngebot = () => {
        //console.log('addNewAngebot')
        setCreate(true);
        setResaleAngebot(true);
        setTimeout(() => {
            document.getElementById('editdstBtn').click();
        }, 1000)
    }

    const editAngebot = (id) => {
        setSelectedQuationId(id);
        setCreate(false);
        setQuationRefresh(quationRefresh + 1);
    }


    const primaryAngebot = (primary, quation_id) => {
        var formData = {};
        formData.primary = (primary ? '1' : '0');
        formData.id = quation_id;
        formData.project_id = (props?.projectid ? props?.projectid : params.id);

        callFetch('primary-angebot', "POST", formData, setError).then((res) => {

        });
    }



    const onSubmit = (formData) => {

        setDropZonValidationError('');

        //if (JSON.parse(Cookies.get('permissions')).indexOf("qINReq") === -1) {
        if (false) {
            for (let [key, dropZoneTab] of Object.entries(dropZoneTabs)) {
                if (dropZoneTab?.require === true && (!tabFiles[dropZoneTab?.name] || tabFiles[dropZoneTab?.name]?.length <= 0)) {
                    setDropZonValidationError(dropZoneTab?.titel + ' is required')
                    return;
                }
            }
        }

        //console.log(getValues('angebotsdatum'));
        if (newQuation === true) {
            formData.gesami_brutto = productItems.gesami_netto * 1.19;
            formData.gesami_netto = productItems.gesami_netto;
            formData.newQuation = 'yes';
        } else {
            formData.newQuation = 'no';
        }

        formData.filesdata = JSON.stringify(tabFiles);

        formData.productItems = JSON.stringify(productItems);
        formData.module_description = JSON.stringify((formData.module_description ? formData.module_description : []));
        formData.speicher_without_energiespeichersystem = (formData.speicher_without_energiespeichersystem == true ? 1 : 0);

        if (resaleAngebot) {
            // console.log('resaleAngebot')
            formData.resale = '1';
        } else {
            formData.resale = '0';
        }

        formData.meter_number = meter_numbers.join(',');

        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');

        if (loadingElement) {
            loadingElement.classList.add("btn-secondary");
            loadingElement.innerHTML = t('wird geladen...');
        }

        //console.log('create=' + create)
        if (create) {
            formData._method = 'POST';
        }
        formData.payment_option = 70;

        callFetch((data?.quations?.id && create === false ? "projectdistribution/" + (props?.projectid ? props?.projectid : params.id) : 'projectdistribution'), "POST", formData, setError).then((res) => {
            setRefresh2(refresh2 + 1);
            setEditDelay(editDelay + 1);

            setCreate(false);
            setResaleAngebot(false);

            if (create) {
                setSelectedQuationId('');
                setQuationRefresh(quationRefresh + 1);
            }

            if (formData?.angebot_per_post_senden == 'Ja') {
                emailAngebotPerPostSenden();
            }

            setTimeout(() => {
                setSaving(false);

                if (loadingElement) {
                    loadingElement.classList.remove("btn-secondary");
                    loadingElement.innerHTML = t('Save');
                }

                if (document.getElementById("OrderSalesTab")) {
                    document.getElementById("OrderSalesTab").click();
                }

                if (document.getElementById("angebot-from-area")) {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    document.getElementById("angebot-from-area").scrollTop = 0;
                }
                openSuccessSB();
                //if (!res.ok) return;
                //setSubmitSuccess(true);
            }, 2000);
        });
    };

    const onChangeHandeler = (e) => {
        const name = e.target.name;
        if (name == 'eigenverbrauch' || name == 'stromkosten') {
            kwh_preis_calculate();
        }

        if (name == 'angebot_per_post_senden') {
            if (getValues('angebot_per_post_senden') == 'Ja') {
                setValue('email', 'service@bayerische-landeswerke.de');
            } else {
                setValue('email', customEmail);
            }
        }

        if (name == 'gesami_netto') {
            const gesami_netto = Number(productItems.gesami_netto);
            const gesami_brutto = gesami_netto * 1.19;
            setValue('gesami_brutto', gesami_brutto.toFixed(2))
        }
    }

    const kwh_preis_calculate = () => {
        const stromkosten = getValues('stromkosten');
        const eigenverbrauch = getValues('eigenverbrauch');
        const value = stromkosten / eigenverbrauch;
        setValue('kwh_preis', value.toFixed(2))
    }

    const calCulateGesDachflache = () => {

        var module_eingeben = getValues('module_eingeben');
        var anzahl_module = getValues('anzahl_module');
        if (module_eingeben == 'no') {
            for (let [key, value] of Object.entries(modules)) {
                if (value?.id == getValues('modul')) {
                    var moduleDescription = JSON.parse(value.description);
                    var width = moduleDescription?.Modulabmessung_width ? moduleDescription?.Modulabmessung_width : 0;
                    var height = moduleDescription?.Modulabmessung_height ? moduleDescription?.Modulabmessung_height : 0;
                }
            }
        } else {
            var width = getValues('module_description[Modulabmessung_width]');
            var height = getValues('module_description[Modulabmessung_height]');
        }

        var ges_dachflache = (width ? (width / 1000) : 0) * (height ? (height / 1000) : 0) * (anzahl_module ? anzahl_module : 0);
        setValue('ges_dachflache', ges_dachflache.toFixed(2));
    }


    const calCulateModuleWatt = () => {


        var totalQuantity = 0;
        var totalWatt = 0;

        //console.log(productItems);

        for (let [key, item] of Object.entries(productItems?.items)) {
            if (item?.type === 'product' && item?.item?.watt) {
                var pQuantity = Number(item?.quantity);
                var pWatt = Number(item?.item?.watt);

                if (pWatt && pWatt > 0) {
                    totalQuantity += pQuantity;
                    totalWatt += (pWatt * pQuantity);
                }
            } else if (item?.type === 'bundle' && item?.item?.items) {

                var bQuantity = Number(item?.quantity);
                //var pQuantity = Number(item?.quantity);

                var bTotalWatt = 0;
                var bTotalQty = 0;

                for (let [BKey, bItem] of Object.entries(item?.item?.items)) {
                    if (bItem?.type === "module") {
                        var pQuantity = Number(bItem?.number);


                        var pWatt = Number(bItem.module?.watt);
                        bTotalWatt += (pWatt * pQuantity);
                        bTotalQty += pQuantity;

                        //console.log(bItem)

                        //console.log('bQuantity='+bQuantity+', pQuantity='+pQuantity+', pWatt='+pWatt);
                    }
                }

                bTotalWatt = (bTotalWatt * bQuantity);
                bTotalQty = (bTotalQty * bQuantity);

                totalQuantity += bTotalQty;
                totalWatt += bTotalWatt;

                //console.log('bTotalWatt='+bTotalWatt+', bTotalQty='+bTotalQty);
            }
        }

        //console.log('pWatt='+totalWatt+', qty='+totalQuantity);

        //setValue('anzahl_module', totalQuantity);
        return {
            totalWatt: totalWatt,
            totalQuantity: totalQuantity,
        };
    }

    const calCulateAnlagenProduktion = () => {

        var module_eingeben = getValues('module_eingeben');
        var sonnenstunden = getValues('sonnenstunden');
        var anzahl_module = getValues('anzahl_module');
        var own_consumption = getValues('own_consumption');

        if (newQuation === true) {
            var moduleWatt = calCulateModuleWatt();
            var module_watt = (moduleWatt.totalWatt / moduleWatt.totalQuantity);
            var anzahl_module = moduleWatt.totalQuantity;

            setValue('anzahl_module', anzahl_module);

        } else if (module_eingeben == 'no') {
            for (let [key, value] of Object.entries(modules)) {
                if (value?.id == getValues('modul')) {
                    var module_watt = value?.watt;
                }
            }
        } else {
            var module_watt = getValues('module_watt');
        }

        var total_plant_production = (sonnenstunden ? sonnenstunden : 0) * ((anzahl_module ? anzahl_module : 0) * (module_watt ? module_watt : 0));
        var total_plant_production = (total_plant_production / 1000);

        var feed_in = total_plant_production - (own_consumption ? own_consumption : 0)
        setValue('total_plant_production', ((total_plant_production).toFixed(2)));
        setValue('feed_in', ((feed_in).toFixed(2)));

        setRefresh3(refresh3 + 3)
    }

    const emailAngebotPerPostSenden = (formData = {}) => {

        if (!data?.quations?.id) return;

        setSaving(true);

        callFetch("quation/" + data?.quations?.id + '?pdf_generate',
            "GET", []).then((res) => {
                if (res?.message == 'success') {

                    formData.type_id = data?.quations?.id;
                    formData.type = 'angebot';
                    formData.pdf = true;
                    formData.email = 'info@bayerische-landeswerke.de';

                    setSaving(true);
                    callFetch("sendemail", "POST", formData, setError).then((res) => {
                        setSaving(false);
                        if (!res.ok) return;
                        setSubmitSuccess(true);
                    });
                } else {
                    setSaving(false);
                }
                setSubmitSuccess(true);
            })
    };

    return (
        <>
            <div className="row">
                <div className={props?.fullwidth != false ? "col-lg-9 mt-lg-0 inner-main" : 'col-12 inner-main'} id="angebot-from-area">

                    {props?.menu != false ? (
                        <>
                            <ProjectDistribution />
                        </>
                    ) : ''}

                    {props?.overview != false ? (
                        <>
                            <Overview
                                quation_id={data?.quations?.id}
                                title={t('Quotation')}
                                customHtaml={<>
                                    <div className="row" style={{ padding: '0px 15px 0px 15px' }}>
                                        <div className="col-12 col-md-2 mt-0">
                                            <label>{t('E-mail Adresse')} *</label>
                                        </div>
                                        <div className="col-12 col-md-4 mt-0 mb-2">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                placeholder={t('')}
                                                {...register("email", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-check">
                                                <label>{t('Wirtschaftlichkeitsberechnung')}</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            setValue('wirtschaftlichkeitsberechnung', 'Ja');
                                                            data.quations.wirtschaftlichkeitsberechnung = 'Ja';
                                                        } else {
                                                            setValue('wirtschaftlichkeitsberechnung', 'Nein');
                                                            data.quations.wirtschaftlichkeitsberechnung = 'Nein';
                                                        }
                                                        setData(data);
                                                        setRefresh3(refresh3 + 3)
                                                    }}
                                                    checked={data?.quations?.wirtschaftlichkeitsberechnung === 'Ja' ? true : false}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-check">
                                                <label>{t('Angebot per Post senden')}</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            setValue('angebot_per_post_senden', 'Ja');
                                                            data.quations.angebot_per_post_senden = 'Ja';
                                                        } else {
                                                            setValue('angebot_per_post_senden', 'Nein');
                                                            data.quations.angebot_per_post_senden = 'Nein';
                                                        }
                                                        setData(data);
                                                        setRefresh3(refresh3 + 3)
                                                    }}
                                                    checked={data?.quations?.angebot_per_post_senden === 'Ja' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            ></Overview>
                        </>
                    ) : ''}

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" id="editdst">
                                <input
                                    type="hidden"
                                    className="form-control form-control-sm"
                                    placeholder={t('')}
                                    {...register("project_id")}
                                />

                                {data?.quations?.id ?
                                    <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                    : ''}

                                {/*
                                <Card id="Kontaktinformationen" sx={{ overflow: "visible" }} className="mt-3">
                                    <SoftBox p={3} pb={0}>
                                        <SoftTypography variant="h5">{t('Kontaktinformationen')}</SoftTypography>
                                        <SoftBox>
                                            <div className="form-check mt-3">
                                                <input onClick={() => setRadio(!radio)} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked={radio && true} />
                                                <label onClick={() => setRadio(!radio)} className="form-check-label" for="flexRadioDefault3">
                                                    {t('Invoice Address Same')}
                                                </label>
                                            </div>
                                            <nav>
                                                <div className="nav nav-tabs ms-3" id="nav-tab" role="tablist">
                                                    <button className={`nav-link active`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><SoftTypography variant="button" >Kontaktinformationen</SoftTypography></button>
                                                    <button className={`nav-link ${radio ? 'disabled' : ''}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><SoftTypography variant="button" >{t('Invoice Addresss')}</SoftTypography></button>
                                                </div>
                                            </nav>
                                        </SoftBox>
                                    </SoftBox>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <SoftBox component="form" pb={3} px={3}>
                                                <div className="row g-3">
                                                    <div className="col-md-8">
                                                        <label>{t('Straße')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder={t('')}
                                                            {...register("street", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>{t('Hausnummer')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder={t('')}
                                                            {...register("hausnummer", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.hausnummer && errors.hausnummer.message}</div>
                                                    </div>

                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <label>{t('PLZ')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder={t('')}
                                                            {...register("plz", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                    </div>

                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <label>{t('Ort')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder={t('')}
                                                            {...register("ort", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                    </div>

                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <label>{t('Telefonnummer')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder={t('')}
                                                            {...register("telefonnummer", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                    </div>

                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <label>{t('E-mail Adresse')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder={t('')}
                                                            {...register("email", {
                                                                required: true,
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                    </div>
                                                </div>
                                            </SoftBox>
                                        </div>
                                        {
                                            radio !== true && <div className={`tab-pane fade ${radio === true && 'd-none'}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                <SoftBox component="form" pb={3} px={3}>
                                                    <div className="row g-3">
                                                        <div className="col-md-12">
                                                            <label>{t('Straße')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_street", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Hausnummer')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_hausnummer", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_hausnummer && errors.invoice_hausnummer.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('PLZ')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_plz", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_plz && errors.invoice_plz.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Ort')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_ort", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_ort && errors.invoice_ort.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Telefonnummer')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_telefonnummer", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_telefonnummer && errors.invoice_telefonnummer.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Mobilnummer')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_mobilnummer", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_mobilnummer && errors.invoice_mobilnummer.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('E-mail Adresse')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder={t('')}
                                                                {...register("invoice_email", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_email && errors.invoice_email.message}</div>
                                                        </div>
                                                    </div>
                                                </SoftBox>
                                            </div>
                                        }
                                    </div>
                                </Card>
                                */}

                                <Card id="eckdaten-pv" sx={{ overflow: "visible" }} className="mt-3">
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Eckdaten PV')}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox component="form" pb={3} px={3}>
                                        <div className="row g-3">
                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Stromverbrauch in kwh')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("eigenverbrauch", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => onChangeHandeler(e)
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.eigenverbrauch && errors.eigenverbrauch.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Stromkosten im Jahr(€)')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("stromkosten", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => onChangeHandeler(e)
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.stromkosten && errors.stromkosten.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Kwh Preis(€)')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    step={0.001}
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("kwh_preis", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.kwh_preis && errors.kwh_preis.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Anzahl der Module')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("anzahl_module", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            calCulateGesDachflache();
                                                            calCulateAnlagenProduktion();
                                                        }
                                                    })}
                                                    required
                                                    readOnly={newQuation === true ? true : false}
                                                />
                                                <div className="invalid-feedback">{errors.anzahl_module && errors.anzahl_module.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Anzahl der zu belegenden Dächer')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("anzahl_der_dacher", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.anzahl_der_dacher && errors.anzahl_der_dacher.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Zahlung')}</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                            <select
                                                    className="form-control form-control-sm"
                                                    {...register("zahlung", {
                                                        required: false,
                                                        disabled: readOnly
                                                    })}>
                                                    <option value="">--</option>
                                                    <option value="Barzahlung">Barzahlung</option>
                                                    <option value="Finanzierung">Finanzierung</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.zahlung && errors.zahlung.message}</div>
                                            </div>
                                        </div>
                                    </SoftBox>
                                </Card>

                                <Card id="Datenerfassung" sx={{ overflow: "visible" }} className="mt-3">
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Datenerfassung')}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox component="form" pb={3} px={3}>
                                        <div className="row g-3">

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Sonnenstunden')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("sonnenstunden", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            calCulateAnlagenProduktion();
                                                        }
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.sonnenstunden && errors.sonnenstunden.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Anlagenproduktion ges. in Kw/h')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">

                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("total_plant_production", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.total_plant_production && errors.total_plant_production.message}</div>
                                            </div>

                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Eigenverbrauch in Kw/h')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("own_consumption", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            calCulateAnlagenProduktion();
                                                        }
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.own_consumption && errors.own_consumption.message}</div>
                                            </div>


                                            <div className="col-12 col-md-2 mt-0">
                                                <label>{t('Einspeisung in Kw/h')} *</label>
                                            </div>
                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    placeholder={t('')}
                                                    {...register("feed_in", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.feed_in && errors.feed_in.message}</div>
                                            </div>



                                            <div class="col-12">{/* Separator */}</div>




                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Sondervereinbarung')}</label>
                                                    <textarea className="form-control form-control-sm" rows={4} {...register('remark_financial_advisers', {
                                                        required: false,
                                                        disabled: readOnly
                                                    })}></textarea>
                                                </div>
                                            </div>

                                            <h6>Bemerkung Montage : (Kabelweg, Zählerschrank, Grabungsarbeiten, Zwischenzähler, WR / Batterie Platz)</h6>





                                            <div class="col-12">{/* Separator */}</div>




                                            <div className="col-md-6 mt-0 mb-1">
                                                <div className="form-check">
                                                    <label>{t('Gerüst Notwendig')}</label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setValue('gerust_notwendig', 'JA');
                                                                data.quations.gerust_notwendig = 'JA';
                                                            } else {
                                                                setValue('gerust_notwendig', 'NEIN');
                                                                data.quations.gerust_notwendig = 'NEIN';
                                                            }
                                                            setData(data);
                                                            setRefresh3(refresh3 + 3)
                                                        }}
                                                        checked={data?.quations?.gerust_notwendig === 'JA' ? true : false}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mt-0 mb-1">
                                                <div className="form-check">
                                                    <label>{t('Freileitung Isolierung')}</label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setValue('frealeatung_isolierung', 'JA');
                                                                data.quations.frealeatung_isolierung = 'JA';
                                                            } else {
                                                                setValue('frealeatung_isolierung', 'NEIN');
                                                                data.quations.frealeatung_isolierung = 'NEIN';
                                                            }
                                                            setData(data);
                                                            setRefresh3(refresh3 + 3)
                                                        }}
                                                        checked={data?.quations?.frealeatung_isolierung === 'JA' ? true : false}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mt-0 mb-1">
                                                <div className="form-check">
                                                    <label>{t('Grabungsarbeiten')}</label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setValue('grabungsarbeiten', 'Yes');
                                                                data.quations.grabungsarbeiten = 'Yes';
                                                            } else {
                                                                setValue('grabungsarbeiten', 'No');
                                                                data.quations.grabungsarbeiten = 'No';
                                                            }
                                                            setData(data);
                                                            setRefresh3(refresh3 + 3)
                                                        }}
                                                        checked={data?.quations?.grabungsarbeiten === 'Yes' ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </SoftBox>
                                </Card>

                                {/*
                                <Card className="mt-3">
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Files')}</SoftTypography>
                                    </SoftBox>

                                    <SoftBox pb={3} px={3}>
                                        <DropZoneTabs
                                            tabs={dropZoneTabs}
                                            defaultFiles={tabFiles}
                                            defaultTab={'dachansicht'}
                                            callBackData={setCallBackData}
                                            dropZonValidationError={dropZonValidationError}
                                        />
                                    </SoftBox>
                                </Card>
                                */}


                                {readOnly == false || newQuation === false ? (
                                    <Card id="bundle-product" sx={{ overflow: "visible" }} className="mt-3">
                                        <SoftBox p={3}>
                                            <SoftTypography variant="h5">{t('Positionen')}</SoftTypography>
                                        </SoftBox>
                                        <SoftBox pb={3} px={3}>
                                            {newQuation === true ? (
                                                <>

                                                    <div class="table-responsive">
                                                        <table className="table table-borderless table-sm" style={{ minWidth: '850px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <td style={{ width: '50px' }}><label>{t('Pos.')}</label></td>
                                                                    <td style={{ width: '18px' }}></td>
                                                                    <td style={{ minWidth: '350px' }}><label>{t('Artikel')}</label></td>
                                                                    <td style={{ width: '100px' }}><label>{t('Menge')}</label></td>
                                                                    <td style={{ width: '150px' }}><label>{t('Preis (€)')}</label></td>
                                                                    <td style={{ width: '200px' }}><label>{t('Steuer')}</label></td>
                                                                    <td style={{ width: '200px' }}><label>{t('Rabatt (%)')}</label></td>
                                                                    <td style={{ width: '150px' }}><label>{t('Pos. Gesamt (€)')}</label></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {productItems?.items.map((productItem, j) => (
                                                                    <tr>
                                                                        <td>
                                                                            <p
                                                                                className="p-0 m-0 mt-2"
                                                                                style={{
                                                                                    fontSize: '0.75rem',
                                                                                    fontWeight: 'normal'
                                                                                }}
                                                                            >
                                                                                {j + 1}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <i
                                                                                className="fa fa-trash text-danger mt-2"
                                                                                onClick={() => {
                                                                                    var update = productItems;
                                                                                    update.items.splice(j, 1);
                                                                                    setProductItems(update);
                                                                                    setproductItemsRefresh(productItemsRefresh + 1)
                                                                                }}
                                                                            ></i>
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                value={productItems.items[j].item}
                                                                                options={productItem?.type == 'bundle' ? bundles : products}
                                                                                itemIndex={j}
                                                                                components={{
                                                                                    Option: CustomOption,
                                                                                    GroupLabel: CustomGroupLabel,
                                                                                    Menu: CustomMenu,
                                                                                    DropdownIndicator
                                                                                }}
                                                                                //menuIsOpen={true}
                                                                                styles={{
                                                                                    control: (baseStyles, state) => ({
                                                                                        ...baseStyles,
                                                                                        minHeight: '32px',
                                                                                        //height: '32px',
                                                                                        fontSize: '0.75rem',
                                                                                        padding: '0rem 0rem',
                                                                                        borderRadius: '0.5rem',
                                                                                    }),
                                                                                    menu: (provided, state) => ({
                                                                                        ...provided,
                                                                                        width: (productItems.items[j].type === 'bundle' && productItems.items[j].searchWatt ? '820px' : productItems?.items[j]?.product_form == 'zusatzprodukte' || productItems.items[j].type === 'bundle' ? '770px' : '600px'), // Adjust the width as needed
                                                                                        //backgroundColor: '#f2f2f2', // Background color
                                                                                        // borderRadius: '4px', // Rounded corners
                                                                                        //boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Box shadow
                                                                                    }),
                                                                                }}
                                                                            />

                                                                            {productItem?.type == 'bundle' && productItems.items[j].item.id ? (
                                                                                <div>
                                                                                    <p
                                                                                        style={{
                                                                                            fontSize: '0.75rem',
                                                                                            fontWeight: 'bold',
                                                                                            color: '#2D3748'
                                                                                        }}
                                                                                        className="m-0 p-0 mt-1"
                                                                                    >{productItems.items[j].item?.label}</p>
                                                                                    {productItems.items[j].item?.items ? productItems.items[j].item?.items.map((item, item_index) => (
                                                                                        <>
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: '0.65rem',
                                                                                                    fontWeight: 'normal',
                                                                                                }}
                                                                                                className="m-0 p-0"
                                                                                            >{item[item?.type]?.name + ' - ' + item?.number + ' Stück'}</p>
                                                                                        </>
                                                                                    )) : ''}
                                                                                </div>
                                                                            ) : ''}
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="number"
                                                                                min={0}
                                                                                onChange={(e) => {
                                                                                    var update = productItems;
                                                                                    update.items[j].quantity = (e.target.value < 0 ? 0 : e.target.value);
                                                                                    setProductItems(update);
                                                                                    setproductItemsRefresh(productItemsRefresh + 1)

                                                                                }}
                                                                                value={productItems.items[j].quantity}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {mitarbeiterkennung ? (
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="number"
                                                                                    styp={0.01}
                                                                                    readOnly
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="number"
                                                                                    styp={0.01}
                                                                                    min={0}
                                                                                    onChange={(e) => {
                                                                                        var update = productItems;
                                                                                        update.items[j].price = (e.target.value < 0 ? 0 : e.target.value);
                                                                                        setProductItems(update);
                                                                                        setproductItemsRefresh(productItemsRefresh + 1)
                                                                                    }}
                                                                                    value={productItems.items[j].price}
                                                                                />
                                                                            )}

                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <select
                                                                                    className="form-control form-control-sm w-50"
                                                                                    type="number"
                                                                                    onChange={(e) => {
                                                                                        var update = productItems;
                                                                                        update.items[j].tax = e.target.value;
                                                                                        setProductItems(update);
                                                                                        setproductItemsRefresh(productItemsRefresh + 1)
                                                                                    }}
                                                                                    value={productItems.items[j].tax}
                                                                                >
                                                                                    <option value={0}>0%</option>
                                                                                    <option value={19}>19%</option>
                                                                                </select>

                                                                                <p
                                                                                    className="p-0 m-0 mt-2 ms-1"
                                                                                    style={{
                                                                                        fontSize: '0.75rem',
                                                                                        fontWeight: 'normal',
                                                                                        color: 'red',
                                                                                    }}
                                                                                >
                                                                                    -
                                                                                    <NumericFormat
                                                                                        value={Number(mitarbeiterkennung ? 0 : productItems.items[j].totalTax)}
                                                                                        displayType="text"
                                                                                        thousandSeparator={"."}
                                                                                        decimalSeparator=","
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale
                                                                                        suffix={' € '}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <input
                                                                                    className="form-control form-control-sm w-50"
                                                                                    type="number"
                                                                                    styp={0.01}
                                                                                    min={0}
                                                                                    max={10}
                                                                                    onChange={(e) => {
                                                                                        var update = productItems;
                                                                                        update.items[j].discount = (e.target.value < 0 ? 0 : e.target.value > 10 ? 10 : e.target.value);
                                                                                        setProductItems(update);
                                                                                        setproductItemsRefresh(productItemsRefresh + 1)
                                                                                    }}
                                                                                    value={productItems.items[j].discount}
                                                                                />

                                                                                <p
                                                                                    className="p-0 m-0 mt-2 ms-1"
                                                                                    style={{
                                                                                        fontSize: '0.75rem',
                                                                                        fontWeight: 'normal',
                                                                                        color: 'red',
                                                                                    }}
                                                                                >
                                                                                    -
                                                                                    <NumericFormat
                                                                                        value={Number(mitarbeiterkennung ? 0 : productItems.items[j].totalDiscount)}
                                                                                        displayType="text"
                                                                                        thousandSeparator={"."}
                                                                                        decimalSeparator=","
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale
                                                                                        suffix={' € '}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <p
                                                                                className="p-0 m-0 mt-2"
                                                                                style={{
                                                                                    fontSize: '0.75rem',
                                                                                    fontWeight: 'normal'
                                                                                }}
                                                                            >
                                                                                <NumericFormat
                                                                                    value={Number(mitarbeiterkennung ? 0 : productItems.items[j].total)}
                                                                                    displayType="text"
                                                                                    thousandSeparator={"."}
                                                                                    decimalSeparator=","
                                                                                    decimalScale={2}
                                                                                    fixedDecimalScale
                                                                                    suffix={' € '}
                                                                                />
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan={2}></td>
                                                                    <td colSpan={3}>
                                                                        <button
                                                                            type="button"
                                                                            className="btn mb-2 mt-3"
                                                                            style={{
                                                                                background: '#2684FF',
                                                                                color: '#ffffff',
                                                                                textTransform: 'uppercase',
                                                                                fontWeight: 600
                                                                            }}
                                                                            onClick={() => {
                                                                                var items = productItems;
                                                                                items.items.push({
                                                                                    id: 0,
                                                                                    type: 'bundle',
                                                                                    product_type: '',
                                                                                    quantity: 1,
                                                                                    price: 0,
                                                                                    sales_person_price: 0,
                                                                                    tax: 0,
                                                                                    discount: 0,
                                                                                    totalDiscount: 0,
                                                                                    total: 0,
                                                                                    lieferanten_id: 0,
                                                                                    item: []
                                                                                })

                                                                                setProductItems(items);
                                                                                setproductItemsRefresh(productItemsRefresh + 1)
                                                                            }}
                                                                        >+ Bundle</button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn ms-2 mb-2 mt-3"
                                                                            style={{
                                                                                background: '#2684FF',
                                                                                color: '#ffffff',
                                                                                textTransform: 'uppercase',
                                                                                fontWeight: 600
                                                                            }}
                                                                            onClick={() => {
                                                                                var items = productItems;
                                                                                items.items.push({
                                                                                    id: 0,
                                                                                    type: 'product',
                                                                                    product_type: '',
                                                                                    quantity: 1,
                                                                                    price: 0,
                                                                                    sales_person_price: 0,
                                                                                    tax: 0,
                                                                                    discount: 0,
                                                                                    totalDiscount: 0,
                                                                                    total: 0,
                                                                                    lieferanten_id: 0,
                                                                                    item: []
                                                                                })
                                                                                setProductItems(items);
                                                                                setproductItemsRefresh(productItemsRefresh + 1)
                                                                            }}
                                                                        >+ Zusatzprodukt</button>
                                                                    </td>
                                                                    <td colSpan={3}></td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={2}></td>
                                                                    <td colSpan={3}></td>
                                                                    <td colSpan={2}>
                                                                        <p
                                                                            className="p-0 m-0 mt-1"
                                                                            style={{
                                                                                fontSize: '0.75rem',
                                                                                fontWeight: 'normal'
                                                                            }}
                                                                        >
                                                                            Positionen Netto
                                                                        </p>
                                                                        <p
                                                                            className="p-0 m-0 mt-2"
                                                                            style={{
                                                                                fontSize: '0.75rem',
                                                                                fontWeight: 'normal'
                                                                            }}
                                                                        >
                                                                            USt. Positionen (<NumericFormat
                                                                                value={Number(mitarbeiterkennung ? 0 : productItems?.ust_positionen)}
                                                                                displayType="text"
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator=","
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                suffix={' % '}
                                                                            />)
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <p
                                                                            className="p-0 m-0 mt-1"
                                                                            style={{
                                                                                fontSize: '0.75rem',
                                                                                fontWeight: 'normal'
                                                                            }}
                                                                        >
                                                                            <NumericFormat
                                                                                value={Number(mitarbeiterkennung ? 0 : productItems?.subTotal)}
                                                                                displayType="text"
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator=","
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                suffix={' € '}
                                                                            />
                                                                        </p>
                                                                        <p
                                                                            className="p-0 m-0 mt-2"
                                                                            style={{
                                                                                fontSize: '0.75rem',
                                                                                fontWeight: 'normal'
                                                                            }}
                                                                        ><NumericFormat
                                                                                value={Number(mitarbeiterkennung ? 0 : productItems?.ust_positionen_amount)}
                                                                                displayType="text"
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator=","
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                suffix={' € '}
                                                                            /></p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}></td>
                                                                    <td colSpan={2}
                                                                        style={{ borderTop: '1px solid #E2E8F0' }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                fontSize: '0.75rem',
                                                                                fontWeight: 'bold',
                                                                                color: '#2D3748'
                                                                            }}
                                                                            className="m-0 p-0 mt-1"
                                                                        >Gesamt Brutto</p>
                                                                    </td>
                                                                    <td
                                                                        style={{ borderTop: '1px solid #E2E8F0' }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                fontSize: '0.75rem',
                                                                                fontWeight: 'bold',
                                                                                color: '#2D3748'
                                                                            }}
                                                                            className="m-0 p-0 mt-1"
                                                                        >
                                                                            <NumericFormat
                                                                                value={Number(mitarbeiterkennung ? 0 : productItems?.gesami_netto)}
                                                                                displayType="text"
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator=","
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                suffix={' € '}
                                                                            />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="row g-3">
                                                    {/*
                                                    <div className="col-12 col-md-2 mt-0">
                                                        <label>{t('Dacheindeckung')} *</label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">

                                                    </div>
                                                */}

                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <div className="form-check">
                                                            <label>{t('Speicher')}</label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        setSpeicherEingeben('yes');
                                                                        setValue('speicher_eingeben', 'yes');
                                                                        data.quations.speicher_eingeben = 'yes';
                                                                    } else {
                                                                        setSpeicherEingeben('no');
                                                                        setValue('speicher_eingeben', 'no');
                                                                        data.quations.speicher_eingeben = 'no';
                                                                    }
                                                                    setData(data);
                                                                    setRefresh3(refresh3 + 3)
                                                                }}
                                                                checked={data?.quations?.speicher_eingeben === 'yes' ? true : false}
                                                            />
                                                        </div>
                                                    </div>

                                                    {speicherEingeben === 'no' ? (
                                                        <>
                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Speicher')} </label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <select
                                                                    className="form-control form-control-sm"
                                                                    {...register("speicher", {
                                                                        required: false,
                                                                        disabled: readOnly
                                                                    })}>
                                                                    <option value="">--</option>
                                                                    {speichers && speichers.map((speicher) => (
                                                                        <option key={speicher.id} value={speicher.id}>{speicher.name}</option>
                                                                    ))}
                                                                </select>
                                                                <div className="invalid-feedback">{errors.speicher && errors.speicher.message}</div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Speicher Titel')} </label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder={t('')}
                                                                    {...register("speicher_titel", {
                                                                        required: false,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.speicher_titel && errors.speicher_titel.message}</div>
                                                            </div>

                                                            <div className="col-12 mt-0 mb-1">
                                                                <label>{t('Speicher Description')}</label>
                                                                <textarea
                                                                    className="form-control form-control-sm"
                                                                    rows={4}
                                                                    {...register('speicher_description', {
                                                                        required: false,
                                                                        disabled: readOnly
                                                                    })}
                                                                ></textarea>
                                                            </div>

                                                            <div className="col-12 mt-0 mb-1">
                                                                <div className="form-check">
                                                                    <label>{t('Ohne Energiespeicher')}</label>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox" value={""}
                                                                        {...register("speicher_without_energiespeichersystem", {
                                                                            disabled: readOnly
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className="invalid-feedback">{errors.speicher_without_energiespeichersystem && errors.speicher_without_energiespeichersystem.message}</div>
                                                            </div>
                                                        </>
                                                    )}


                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <div className="form-check">
                                                            <label>{t('Wechselrichter')}</label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        setWechselrichter('yes');
                                                                        setValue('wechselrichter_eingeben', 'yes');
                                                                        data.quations.wechselrichter_eingeben = 'yes';
                                                                    } else {
                                                                        setWechselrichter('no');
                                                                        setValue('wechselrichter_eingeben', 'no');
                                                                        data.quations.wechselrichter_eingeben = 'no';
                                                                    }
                                                                    setData(data);
                                                                    setRefresh3(refresh3 + 3)
                                                                }}
                                                                checked={data?.quations?.wechselrichter_eingeben === 'yes' ? true : false}
                                                            />
                                                        </div>
                                                    </div>

                                                    {wechselrichterEingeben === 'no' ? (
                                                        <>
                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Wechselrichter (Kein Pflichtfeld)')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <select
                                                                    className="form-control form-control-sm"
                                                                    {...register("wechselrichter_id", {
                                                                        required: false,
                                                                        disabled: readOnly
                                                                    })}>
                                                                    <option value="">--</option>
                                                                    {wechselrichters && wechselrichters.map((wechselrichter) => (
                                                                        <option key={wechselrichter.id} value={wechselrichter.id}>{wechselrichter.name}</option>
                                                                    ))}
                                                                </select>
                                                                <div className="invalid-feedback">{errors.wechselrichter_id && errors.wechselrichter_id.message}</div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Wechselrichter Titel')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder={t('')}
                                                                    {...register("wechselrichter_titel", {
                                                                        required: false,
                                                                        disabled: readOnly
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.wechselrichter_titel && errors.wechselrichter_titel.message}</div>
                                                            </div>

                                                            <div className="col-12 mt-0 mb-1">
                                                                <label>{t('Wechselrichter Description')}</label>
                                                                <textarea
                                                                    className="form-control form-control-sm"
                                                                    rows={4}
                                                                    {...register('wechselrichter_description', {
                                                                        required: false,
                                                                        disabled: readOnly
                                                                    })}
                                                                ></textarea>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col-md-6 mt-0 mb-1">
                                                        <div className="form-check">
                                                            <label>{t('Modul')}</label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    if (e.target.checked === true) {
                                                                        setModuleEingeben('yes');
                                                                        setValue('module_eingeben', 'yes');
                                                                        data.quations.module_eingeben = 'yes';
                                                                    } else {
                                                                        setModuleEingeben('no');
                                                                        setValue('module_eingeben', 'no');
                                                                        data.quations.module_eingeben = 'no';
                                                                    }
                                                                    calCulateGesDachflache();
                                                                    calCulateAnlagenProduktion();
                                                                    setData(data);
                                                                    setRefresh3(refresh3 + 3)
                                                                }}
                                                                checked={data?.quations?.module_eingeben === 'yes' ? true : false}
                                                            />
                                                        </div>
                                                    </div>

                                                    {moduleEingeben === 'no' ? (
                                                        <>
                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Modul')} *</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <select
                                                                    className="form-control form-control-sm"
                                                                    {...register("modul", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            calCulateGesDachflache();
                                                                            calCulateAnlagenProduktion();
                                                                        }
                                                                    })}
                                                                    required
                                                                >
                                                                    <option value="">--</option>
                                                                    {modules && modules.map((modul) => (
                                                                        <option key={modul.id} value={modul.id}>{modul.name}</option>
                                                                    ))}
                                                                </select>
                                                                <div className="invalid-feedback">{errors.modul && errors.modul.message}</div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Title')} *</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_name", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Short Title')} *</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_short_title", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Watt')} *</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="number"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_watt", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            calCulateAnlagenProduktion();
                                                                        }
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Markenhersteller')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Markenhersteller]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Typ')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Typ]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Zelltyp')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Zelltyp]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Leistungstoleranz')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Leistungstoleranz]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Modulabmessung') + ' (' + t('Width') + ')'}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="number"
                                                                    step={0.01}
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Modulabmessung_width]", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            calCulateGesDachflache();
                                                                        }
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Modulabmessung') + ' (' + t('Height') + ')'}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="number"
                                                                    step={0.01}
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Modulabmessung_height]", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            calCulateGesDachflache();
                                                                        }
                                                                    })}
                                                                    required
                                                                />
                                                            </div>


                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Gewicht')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Gewicht]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Modulwirkungsgrad')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Modulwirkungsgrad]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Max. Druckbelastung')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[MaxDruckbelastung]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Leistungsgarantie')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Leistungsgarantie]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-0">
                                                                <label>{t('Produktgarantie')}</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-0 mb-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Produktgarantie]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col-12 col-md-2 mt-0">
                                                        <label>{t('Zusatzvereinbarungen')}</label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-2">
                                                        <Select
                                                            placeholder={t('Select')}
                                                            options={[
                                                                { value: 'Zwischenzähler', label: 'Zwischenzähler' },
                                                                { value: 'Power to Heat', label: 'Power to Heat' },
                                                                { value: 'inkl. Technikpaket', label: 'inkl. Technikpaket' },
                                                                { value: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC', label: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC' },
                                                                { value: 'Zählerzusammenlegung', label: 'Zählerzusammenlegung' },
                                                                { value: 'inkl. Aufständerung Ost/West', label: 'inkl. Aufständerung Ost/West' },
                                                                { value: 'inkl. Aufständerung Süd', label: 'inkl. Aufständerung Süd' },
                                                                { value: 'Steuerberatergutschein SENEC', label: 'Steuerberatergutschein SENEC' },
                                                                { value: 'inkl. Kreuzverbund', label: 'inkl. Kreuzverbund' },
                                                                { value: 'inkl. Schlüsselfertiger Preis / All in one Paket', label: 'inkl. Schlüsselfertiger Preis / All in one Paket' },
                                                                { value: 'inkl. Blechziegel', label: 'inkl. Blechziegel' },
                                                                { value: 'TIGO Optimierer', label: 'TIGO Optimierer' },
                                                                { value: 'inkl. Notstrombox SENEC', label: 'inkl. Notstrombox SENEC' },
                                                                { value: 'inkl. Notstrombox E3DC', label: 'inkl. Notstrombox E3DC' },
                                                                { value: 'inkl. Wallbox 22kw', label: 'inkl. Wallbox 22kw' },
                                                                { value: 'inkl. Wallbox 11kw', label: 'inkl. Wallbox 11kw' },
                                                                { value: 'Taubenschutz', label: 'Taubenschutz' },
                                                                { value: 'Schneefanggitter', label: 'Schneefanggitter' }
                                                            ]}
                                                            value={zusatzvereinbarungen}
                                                            onChange={(e) => {
                                                                setZusatzvereinbarungen(e);
                                                                setValue('zusatzvereinbarungen', JSON.stringify(e));
                                                            }}
                                                            isMulti
                                                            isDisabled={readOnly}
                                                            styles={reactSelectStyle}
                                                            components={{ DropdownIndicator }}
                                                        />
                                                        <div className="invalid-feedback">{errors.zusatzvereinbarungen && errors.zusatzvereinbarungen.message}</div>
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                        <label>{t('Zusatzvereinbarungen 2')}</label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-2">
                                                        <Select
                                                            placeholder={t('Select')}
                                                            options={allProducts}
                                                            getOptionLabel={(option) => ((option.name))}
                                                            getOptionValue={(option) => option.id}
                                                            value={selectedproduct}
                                                            onChange={(e) => {
                                                                //setZusatzvereinbarungen(e);
                                                                //setValue('zusatzvereinbarungen', JSON.stringify(e));
                                                                var productIds = [];
                                                                for (let [key, value] of Object.entries(e)) {
                                                                    productIds.push(value.id)
                                                                }
                                                                setValue('products', JSON.stringify(productIds));
                                                                setSelectedproduct(e);
                                                            }}
                                                            isMulti
                                                            isDisabled={readOnly}
                                                            styles={reactSelectStyle}
                                                            components={{ DropdownIndicator }}
                                                        />
                                                        <div className="invalid-feedback">{errors.zusatzvereinbarungen && errors.zusatzvereinbarungen.message}</div>
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                        <label>{t('Angebotsdatum')}</label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                        <input type="date"
                                                            {...register("angebotsdatum", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            className="form-control form-control-sm"
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-2 mt-0">
                                                        <label>{t('Zahlungsart: (Pflicht)')}</label>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                        <select
                                                            //style={{ height: '2.570rem', borderRadius: '0.5rem', padding: '0.25rem 0.75rem', border: '0.0625rem solid rgb(210, 214, 218)' }}
                                                            {...register("payment_method", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            className="form-control form-control-sm"
                                                        >
                                                            <option value="">{t('Auswählen')}</option>
                                                            <option value="Finanzierer">Finanzierer</option>
                                                            <option value="Eigenkapital">Eigenkapital</option>
                                                        </select>
                                                    </div>

                                                    {readOnly == false ? (
                                                        <>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-6 col-md-2 d-flex align-items-center justify-content-between" >
                                                                        <div onClick={() => {
                                                                            setShow(1);
                                                                            setValue('netto_brotto', 1)
                                                                        }
                                                                        } className="form-check">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={show === 1 && true} />
                                                                            <label className="form-check-label" for="flexRadioDefault2">
                                                                                Netto
                                                                            </label>
                                                                        </div>

                                                                        <div onClick={() => {
                                                                            setShow(0);
                                                                            setValue('netto_brotto', 0)
                                                                        }} className="form-check">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={show === 0 && true} />
                                                                            <label className="form-check-label" for="flexRadioDefault1">
                                                                                Brutto
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            {show === 0 && <>
                                                                <div className="col-12 col-md-2 mt-0">
                                                                    <label>{t('Gesamt Netto')} *</label>
                                                                </div>
                                                                <div className="col-12 col-md-4 mt-0 mb-1">
                                                                    <div className="input-group ">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control form-control-sm"
                                                                            placeholder={t('')}
                                                                            {...register("gesami_netto", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                                onChange: (e) => onChangeHandeler(e)
                                                                            })}
                                                                            required
                                                                        />
                                                                        <div className="input-group-append ms-2">
                                                                            <span className="input-group-text px-5">€</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.gesami_netto && errors.gesami_netto.message}</div>
                                                                </div>

                                                                <div className="col-12 col-md-2 mt-0">
                                                                    <label>{t('Gesamt Brutto')} *</label>
                                                                </div>
                                                                <div className="col-12 col-md-4 mt-0 mb-1">
                                                                    <div className="input-group">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control form-control-sm"
                                                                            placeholder={t('')}
                                                                            {...register("gesami_brutto", {
                                                                                required: true,
                                                                                disabled: readOnly
                                                                            })}
                                                                            required
                                                                        />
                                                                        <div className="input-group-append ms-2">
                                                                            <span className="input-group-text px-5">€</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invalid-feedback">{errors.gesami_brutto && errors.gesami_brutto.message}</div>
                                                                </div>
                                                            </>}

                                                            {
                                                                show === 1 && <>
                                                                    <div className="col-12 col-md-2 mt-0">
                                                                        <label>{t('Gesamt Netto')} *</label>
                                                                    </div>
                                                                    <div className="col-12 col-md-4 mt-0 mb-1">
                                                                        <div className="input-group">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control form-control-sm"
                                                                                placeholder={t('')}
                                                                                {...register("gesami_netto", {
                                                                                    required: true,
                                                                                    disabled: readOnly,
                                                                                    onChange: (e) => onChangeHandeler(e)
                                                                                })}
                                                                                required
                                                                            />
                                                                            <div className="input-group-append ms-2">
                                                                                <span className="input-group-text px-5">€</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="invalid-feedback">{errors.gesami_netto && errors.gesami_netto.message}</div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    ) : ''}
                                                </div>
                                            )}
                                        </SoftBox>
                                    </Card>
                                ) : ''}
                                <div className="row g-3 mt-1">
                                    <div className="col-12 mt-4">
                                        {JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1 ? (
                                            <>
                                                {!saving && (
                                                    <button type="submit" className="btn btn-primary" id="editdstBtn">
                                                        {t('Save')}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button type="button" className="btn btn-secondary" disabled>
                                                        {t('wird geladen...')}
                                                    </button>
                                                )}
                                            </>
                                        ) : <></>}

                                        {data?.quations?.id ? (
                                            <>
                                                {JSON.parse(Cookies.get('permissions')).indexOf("PDU") !== -1 ? (
                                                    <>
                                                        <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.quations?.id} className="btn btn-outline-dark ms-2" >{t('Quoation PDF')}</a>
                                                    </>
                                                ) : <></>}

                                                {data?.quations?.dachtyp != null && (
                                                    <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation-new/' + data.quations.id} className="btn btn-outline-dark ms-2">
                                                        {t('New Quotation PDF')}
                                                    </a>
                                                )}
                                            </>
                                        ) : <></>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {props?.sidebar != false ? (
                    <>
                        <div className="col-lg-3 inner-main">
                            <div className="row">
                                <div className="col-sm-12">
                                    {data?.quations?.id ? <button className="btn btn-primary" onClick={() => { document.getElementById('digitalSignQuation').click() }} style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}>{t('Unterschreiben')}</button> : <button className="btn btn-secondary" onClick={() => { setErrorSB(true) }} style={{ width: '100%' }}>{t('Unterschreiben')}</button>}

                                    {JSON.parse(Cookies.get('permissions')).indexOf("Reseller") !== -1 ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                addNewAngebot();
                                            }}
                                            style={{ width: '100%', backgroundColor: '#72AC27', borderColor: '#72AC27' }}
                                        >{t('Angebot Hinzufügen')}
                                        </button>
                                    ) : <></>}
                                </div>
                                <div className="col-12">
                                    {data?.allquations ? data?.allquations.map((quation, i) => (
                                        <div
                                            className={"card mb-3 "}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: (quation?.id == data?.quations?.id ? ' #00549740' : '')
                                            }}
                                        >
                                            <div className="card-body p-3">
                                                <div className="d-flex">
                                                    <div>
                                                        <div className="">
                                                            {/*<i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />*/}
                                                            <div

                                                            >
                                                                <input
                                                                    name="primary_quation"
                                                                    type="radio"
                                                                    className="mt-3"
                                                                    defaultChecked={quation?.primary == '1' ? true : false}
                                                                    onChange={(e) => {

                                                                        var updatedata = data;
                                                                        for (let [key, value] of Object.entries(updatedata.allquations)) {
                                                                            //console.log(key)
                                                                            if (value?.id == quation?.id) {
                                                                                updatedata.allquations[key].primary = '1';
                                                                            } else {
                                                                                updatedata.allquations[key].primary = '0';
                                                                            }
                                                                        }

                                                                        setData(updatedata);
                                                                        primaryAngebot(e.target.checked, e.target.value);
                                                                    }}
                                                                    value={quation?.id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-3"
                                                        onClick={() => {
                                                            editAngebot(quation?.id);
                                                        }}
                                                    >
                                                        <div className="numbers">
                                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Quotation Nr.')}</p>
                                                            <h5 className="font-weight-bolder mb-0">
                                                                {quation?.id} {' - ' + quation?.user?.name}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div>

                                {/*<div className="col-12">
                                    <div className="card mb-3">
                                        <div className="card-body p-3">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <div className="ms-3">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                                        <h5 className="font-weight-bolder mb-0">
                                                            {data?.quations?.user?.name ? data?.quations?.user?.name : '-'}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>

                            <div className="card mb-3">
                                <div className="card-header pb-0">
                                    <h6>{t('Email')}</h6>
                                </div>
                                <div className="card-body">
                                    <EmailForm projectid={props?.projectid ? props?.projectid : params.id} id={data?.quations?.id} type={'angebot'} pdf={true} netto={data?.quations?.gesami_netto} />
                                </div>
                            </div>
                            <Timeline refresh={refresh2} />
                        </div>
                    </>
                ) : ''}

                {renderSuccessSB}
                {renderErrorSB}
            </div>
        </>
    );
}

export default Distribution;
