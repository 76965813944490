import { useMemo, useEffect, useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftSelect from "components/SoftSelect";
import PieChart from "examples/Charts/PieChart";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftButton from "components/SoftButton";
import { useTranslation } from 'react-i18next';

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

import LoadingCard from "components/LoadingCard";

function ProjectStatusPieChart(props) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    
    const [loading, setLoading] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [users, setUsers] = useState([]);
    const [verkaufer, setVerkaufer] = useState({ value: "", label: "VKB Name" });
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'Current Week',
    });

    useEffect(() => {
        setLoading(true);
        callFetch("statistics/project-status-pie-chart", "POST", filterData, setError).then((res) => {
            setPieChartData(res.data);
            setUsers(res.users);
            setLoading(false);
            if (!res.ok) return;
        });
    }, [refresh]);

    useEffect(() => {
        if(!props?.filterData?.created_at) return;
        
        if(props?.filterData?.created_at != filterData?.created_at){
            var data = filterData;
            data.created_at =  props?.filterData?.created_at;
            setFilterData(data);
            setRefresh(refresh+1)
        }
    }, [props?.filterData?.created_at]);

    return (
        <>
                <Card sx={{ overflow: "visible" }}>
                    <Grid container alignItems="end" display="flex" px={2}>
                        <Grid item xs={12} sm={12} lg={4}></Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            pl={1}
                            marginTop="1.5rem"
                        >
                            <SoftSelect
                                size="small"
                                placeholder="VKB Name"
                                options={users}
                                defaultValue={verkaufer}
                                onChange={(e)=>{                                
                                    var data = filterData;
                                    data.verkaufer =  e.value;                                
                                    setFilterData(data);
                                    setVerkaufer(e);
                                    setRefresh(refresh+1)
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            pl={1}
                            marginTop="1.5rem"
                        >
                            <SoftSelect
                                size="small"
                                placeholder="Select"
                                options={[
                                    { value: "All", label: t("All") },
                                    { value: "Today", label: t("Today") },
                                    { value: "Yeasterday", label: t("Yeasterday") },
                                    { value: "Current Week", label: t("Current Week") },
                                    { value: "Last 7 days", label: t("Last 7 days") },
                                    { value: "Last 30 days", label: t("Last 30 days") },
                                ]}
                                onChange={(e)=>{
                                    var data = filterData;
                                    data.created_at =  e.value;
                                    setFilterData(data);
                                    setRefresh(refresh+1)
                                }}
                                value={{ value: filterData?.created_at, label: t(filterData?.created_at)}}
                            />
                        </Grid>
                    </Grid>
                    {/*{loading ? (
                        <LoadingCard
                            sx={{height: '356px'}}
                        />
                    ) : (
                    <>*/}
                    <SoftBox p={2} mt={3}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={5}>
                                <PieChart
                                    chart={pieChartData}
                                    height="180px"
                                />
                            </Grid>
                        <Grid item xs={6} sm={3} lg={4} mt={1.6}>
                            <SoftBox px={0}>
                                
                                {pieChartData?.labels ?                            
                                pieChartData?.labels?.map((status, index) =>
                                    <SoftBox mb={0.5} key={index}>
                                        <SoftBadgeDot
                                            color={pieChartData?.datasets?.backgroundColors[index]}
                                            size="sm"
                                            //badgeContent={status+' - '+pieChartData?.datasets?.data[index]}
                                            badgeContent={'Note '+(index+1)+' - '+pieChartData?.datasets?.data[index]}
                                        />
                                    </SoftBox>
                                ) : <></>}
                            </SoftBox>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            sm={2}
                            lg={2}
                            mt={1.2}
                            textAlign="left"
                        >
                            <SoftTypography
                                variant="button"
                                color="secondary"
                                fontWeight="regular"
                                fontSize="11px"
                                marginLeft="10px"
                            >
                            Storno
                            </SoftTypography>
                            {/*
                                <br />
                                <SoftTypography
                                    variant="button"
                                    color="secondary"
                                    fontWeight="regular"
                                    fontSize="11px"
                                    marginLeft="10px"
                                >
                                N.Bewertet
                                </SoftTypography>
                            */}
                        </Grid>
                        {/**/}

                        <Grid
                            item
                            xs={3}
                            lg={1}
                            sm={1}
                            mt={1.3}
                            textAlign="center"
                        >
                            <SoftBox
                                color="white"
                                bgColor="#E53E3E"
                                borderRadius="md"
                                shadow="md"
                                opacity={1}
                                fontSize="12px"
                                width="3rem"
                                height="1.5rem"
                                textAlign="center"
                                pt={0.5}
                            >
                            {pieChartData?.datasets?.total_storno_projects ? pieChartData?.datasets?.total_storno_projects : '--'}
                            </SoftBox>

                            {/*
                                <SoftBox
                                    color="white"
                                    bgColor="#E53E3E"
                                    borderRadius="md"
                                    shadow="md"
                                    opacity={1}
                                    fontSize="12px"
                                    width="3rem"
                                    height="1.5rem"
                                    textAlign="center"
                                    mt={0.8}
                                    pt={0.5}
                                >
                                43
                                </SoftBox>
                            */}
                        </Grid>
                        </Grid>
                    </SoftBox>
                    <SoftBox
                        pt={0}
                        pb={4}
                        px={2}
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        mt="auto"
                    >
                        {" "}
                        <SoftBox
                            width={{ xs: "100%", sm: "100%" }}
                            style={{
                                textAlign: "right",
                                marginTop: "45px",
                            }}
                            mt={{ xs: 0, sm: "auto" }}
                        >
                        {/* <SoftButton variant="gradient" color="dark">READ MORE</SoftButton>*/}
                        </SoftBox>
                    </SoftBox>
                    {/*</>
                    )}*/}
                </Card>
        </>
        )
    }
    
export default ProjectStatusPieChart;