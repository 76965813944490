import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import FilesIndexTable from './FilesIndexTable';
import CustomFiles from './CustomFiles';
import OrderFiles from './OrderFiles';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography"
import SoftDropzone from "components/SoftDropzone";
import Timeline from "./Timeline";
import ProjectStatus from '../ProjectStatus';
import DCFiles from "./DCFiles";
import ACFiles from "./ACFiles";

function FilesForOverView(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setValue('id', params.id);
    }, [params.id]); 

    
    const notify = () => toast("Success");
    const user = JSON.parse(Cookies.get('user'));
    const submitBtn = useRef(); 
    const [attachments, setAttachment] = useState([]); 
    const[newDropzone, setNewDropzone] = useState(true);
    const[editForm, setEditForm] = useState(false);



    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    } 
 

    const onSubmit = (formData) => {  
        setSaving(true);
        formData.id = params.id;
        formData.for = 'project_file';
        callFetch("custom-file-upload", "POST", formData, setError).then((res) => { 
            setRefresh(refresh + 1); 
            setAttachment([]); 
            setValue('attachments', '');
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
            clearForm();
            //notify();
        });
    };

    const clearForm = () =>{
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setNewDropzone(true);
        setEditForm(false);
        setAttachment([]); 
    }

    const dropZoneInitialize = (name = 'dachansicht') => {

        return(
            <SoftDropzone
                key={'dfdf'}
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers:{
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if(response.message == 'success'){
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function(file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function(file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }


    return(
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 mt-lg-0">
                            <div className="">
                                {editForm == false ? (
                                    <>
                                <form id="createOrderProcesing" className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <input type="hidden" defaultValue="POST" {...register("_method")} />
                                    <input 
                                        type="hidden"
                                            {...register("id", {
                                                required: true,
                                            })}
                                        />
                                    <SoftBox>
                                        
                                            <SoftBox p={2} lineHeight={1}> 
                                                <SoftBox>
                                                    <SoftBox
                                                        display="flex"
                                                        flexDirection="column"
                                                        justifyContent="flex-end"
                                                        height="100%"
                                                        >
                                                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                {t('Files')}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        
                                                        {dropZoneInitialize()}

                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                            <SoftBox pt={2} className="text-end">
                                                <a href="#" className="btn btn-danger mx-2 mb-0" onClick={()=>{ clearForm() }}>
                                                    {t('Cancel')}
                                                </a>

                                                {!saving && (
                                                    <button type="submit" className="btn btn-primary mb-0">
                                                        {t('Save')}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button type="submit" className="btn btn-disabled mb-0" disabled>
                                                        {t('Saving ...')}
                                                    </button>
                                                )}
                                            </SoftBox>
                                        
                                    </SoftBox>
                                </form>
                                </>
                                ) : <></>}
                            </div>
                            <br/> 
                        </div> 
                    </div>
                    <ToastContainer />

                    <hr style={{margin:'0px', marginBottom: '15px'}}/>
                    
                    <div className=" mb-4"> 
                        <div className="px-0 pt-0 pb-2">                                    
                            <FilesIndexTable refresh={refresh} for={'project_file'}/>                                                        
                        </div>
                    </div>

                    <hr style={{margin:'0px', marginBottom: '15px'}}/>

                    <div className=" mb-4"> 
                        <h6 className="" style={{marginTop: '10px', marginLeft: '10px'}}>{t('Files')} </h6>
                        <div className="px-0 pt-0 pb-2">   
                            <CustomFiles refresh={refresh} project_id={params.id}/>                                                        
                        </div>
                    </div>

                    <hr style={{margin:'0px', marginBottom: '15px'}}/>
                   
                    <div className=" mb-4"> 
                        <h6 className="" style={{marginTop: '10px', marginLeft: '10px'}}>{t('Angebot/Auftrag')} </h6>
                        <div className="px-0 pt-0 pb-2">   
                            <OrderFiles refresh={refresh} project_id={params.id}/>                                                        
                        </div>
                    </div>

                    <hr style={{margin:'0px', marginBottom: '15px'}}/>
                   
                    <div className=" mb-4"> 
                        <h6 className="" style={{marginTop: '10px', marginLeft: '10px'}}>{t('AC Abnahme')} </h6>
                        <div className="px-0 pt-0 pb-2">   
                            <ACFiles refresh={refresh} project_id={params.id}/>                                                        
                        </div>
                    </div>

                    <hr style={{margin:'0px', marginBottom: '15px'}}/>
                    
                    <div className=" mb-4"> 
                        <h6 className="" style={{marginTop: '10px', marginLeft: '10px'}}>{t('DC Abnahme')} </h6>
                        <div className=" px-0 pt-0 pb-2">   
                            <DCFiles refresh={refresh} project_id={params.id}/>                                                             
                        </div>
                    </div>


                </div> 
            </div>           
        </>
    );
}

export default FilesForOverView;
